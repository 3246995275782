import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  icon: {
    width: '16px',
    height: '16px',
  },
  wrapperRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px',
    background: '#eee',
    margin: '10px 0',
  },
  navList: {
    border: '1px #eeeeee solid',
    padding: '10px',
    borderRadius: '5px',
    position: 'relative',

    display: 'flex',
    flexDirection: 'column',
  },
}));

const NavList = ({
  navs,
  onScroll,
  dragStartHandler,

  dropHandler,
  next,
}) => {
  const classes = useStyles();

  const history = useHistory();

  return (
    <div className={classes.navList}>
      <InfiniteScroll
        dataLength={navs?.length}
        next={onScroll}
        hasMore={Boolean(next)}
        loader={<h4>Загрузка...</h4>}
        height="500px"
      >
        {navs?.map((item) => {
          return (
            <div
              draggable="true"
              key={item.id}
              onDragStart={() => dragStartHandler(item)}
              onDrop={(e) => dropHandler(e, item)}
              className={classes.wrapperRow}
            >
              <div>{item.title}</div>
              <Tooltip title="Редактировать" aria-label="add">
                <IconButton
                  size="small"
                  aria-label="remove papper"
                  onClick={() => {
                    history.push(
                      `/admin/sp-header-categories/edit/${item.slug}`
                    );
                  }}
                >
                  <EditIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
            </div>
          );
        })}
      </InfiniteScroll>
    </div>
  );
};

export default NavList;
