import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { loadScript } from 'assets/js/helper.js';
import 'assets/css/dashboard_grid.css';
import { v2BaseURL, mainGridSocket } from 'request/axios';

const MainGrid = ({ user }) => {
  const baseUrl = v2BaseURL.replace('/admin', '');
  useEffect(() => {
    if (!document.getElementById('dashboard_grid_script')) {
      loadScript(`${window.location.origin}/dashboard_grid.js`, null, {
        id: 'dashboard_grid_script',
      });
    }
  }, []);

  return (
    <Paper style={{ padding: 15 }} elevation={0}>
      <div
        id="dashboard-grid"
        data-username={user}
        data-socket_url={mainGridSocket}
        data-api-host={baseUrl}
      />
    </Paper>
  );
};

const mapState = (state) => ({
  user: `${state.auth.first_name} ${state.auth.last_name}`,
});

export default connect(mapState)(MainGrid);
