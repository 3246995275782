import React, { useEffect, useState } from 'react';
import { Paper, Button, Divider } from '@material-ui/core';
import ChoiceArticles from 'components/ChoiceArticles';
import { getUniversalData, saveUniversalData } from '../../request';
import { connect } from 'react-redux';
import Preloader from 'components/Preloader';

let timeoutID = null;

const ChoiceArticlesFeature = ({
  showDialog,
  target_id,
  type,
  showDialogMsg,
  slotsCount,
  articlePath,
  slotsPath,
  queryParams = '',
  onSave,
  slot,
  onDelete,
  onChange,
}) => {
  const [articles, setArticles] = useState();
  const [slots, setSlots] = useState();

  const featchArticles = async (query, clear) => {
    const urlParams = new URLSearchParams(query);
    const params = Object.fromEntries(urlParams);
    const res = await getUniversalData({
      path: articlePath,
      query: { ...params, target_id },
    });
    setArticles({
      ...res,
      results: clear ? res.results : [...articles.results, ...res.results],
    });
  };

  const featchGrid = async () => {
    const res = await getUniversalData({
      path: slotsPath,
      query: { target_id },
    });
    setSlots(res);
    onChange?.(res);
  };

  useEffect(() => {
    featchArticles(`${queryParams}`, true);
    featchGrid();
  }, []);

  const handleScrollLoad = (next) => {
    const parse = next.split('?');
    featchArticles(`${parse[1]}`);
  };

  const handleSearch = (e) => {
    clearTimeout(timeoutID);
    timeoutID = setTimeout(() => {
      featchArticles(`${queryParams}&search=${e?.target?.value}`, true);
    }, 500);
  };

  const handleChange = (e) => {
    setSlots(e);
    onChange?.(e);
  };

  const handleSave = () => {
    showDialog(showDialogMsg, async () => {
      const ids = slots.map(({ id }) => Number(id));
      if (onSave) {
        onSave(slots);
      } else {
        await saveUniversalData(
          {
            target_id,
            type,
          },
          ids
        );
      }
    });
  };

  return (
    <>
      <Paper style={{ padding: 15, marginBottom: 15 }} elevation={0}>
        {slots && articles ? (
          <>
            <ChoiceArticles
              articles={articles}
              slots={slots}
              slotsCount={slotsCount}
              onScroll={handleScrollLoad}
              onSearch={handleSearch}
              onChange={handleChange}
              onDelete={onDelete}
              slot={slot}
            />
            <Divider style={{ margin: '15px 0' }} />
            <Button variant="contained" onClick={handleSave}>
              Сохранить
            </Button>
          </>
        ) : (
          <Preloader />
        )}
      </Paper>
    </>
  );
};

const mapDispatch = (dispatch) => ({
  showDialog: (message, cb) =>
    dispatch.dialog.toggle({
      message,
      onAgree: cb,
    }),
});

export default connect(null, mapDispatch)(ChoiceArticlesFeature);
