import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import { TextField } from 'mui-rff';
import Button from '@material-ui/core/Button';
import { useParams, useHistory } from 'react-router-dom';
import { createProgram, saveProgram, getProgram } from '../../../request';
import Dropzone from 'react-dropzone';
import { getBase64Image } from 'assets/js/helper';
import SimpleTable from 'components/SimpleTable';
import Divider from '@material-ui/core/Divider';
import { baseSiteURL } from 'request/axios';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTextField-root': {
      margin: '8px 0',
      width: '100%',
    },
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
    },
  },
  submitBtn: {
    marginRight: 5,
    marginTop: 15,
  },
  dropZoneContainer: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
  image: {
    position: 'relative',
    width: '100%',
    maxWidth: '480px',
    minWidth: '270px',
  },
  releaseBlock: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
}));

const validate = ({ title, description }) => {
  const errors = {};
  if (!title) {
    errors.title = 'Обязательно для заполнения';
  }
  if (!description) {
    errors.description = 'Обязательно для заполнения';
  }
  return errors;
};

const CrudPrograms = () => {
  const history = useHistory();
  const { id } = useParams();
  const classes = useStyles();
  const [values, setValues] = useState({});
  const [state, setState] = useState({});
  useEffect(() => {
    async function fetchData() {
      const res = await getProgram(id);
      if (res.id) setValues(res);
      if (res.cover) setState({ src: res.cover });
      else history.push(`/admin/classifiers/programs`);
    }
    if (id) fetchData();
  }, []);

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('description', data.description);

    try {
      if (id) {
        if (state.file) formData.append('cover', state.file);

        await saveProgram(id, formData);
      } else {
        formData.append('cover', state.file);
        await createProgram(formData);
      }
      history.push(`/admin/classifiers/programs`);
    } catch (error) {
      console.error('ошибка при сохранении программы', error);
    }
  };

  const handleOnLoad = async (file) => {
    const baseFile = await getBase64Image(file[0]);
    setState({
      file: file[0],
      src: baseFile,
    });
  };

  return (
    <>
      <Form
        initialValues={values}
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, valid }) => (
          <form
            onSubmit={handleSubmit}
            className={classes.root}
            noValidate
            autoComplete="off"
          >
            <Paper style={{ padding: 15, marginBottom: 15 }} elevation={0}>
              {id && (
                <>
                  <Button
                    href={`${baseSiteURL}tv/${id}`}
                    target="_blank"
                    rel="noreferrer"
                    color="primary"
                  >
                    Перейти на страницу программу
                  </Button>
                  <Divider />
                  <br />
                </>
              )}
              <TextField
                required
                label="Название программы"
                variant="outlined"
                name="title"
                margin="none"
                size="small"
              />
              <TextField
                required
                multiline
                rows={4}
                label="Описание"
                variant="outlined"
                name="description"
                margin="none"
                size="small"
              />
              <Dropzone
                accept=".img,.jpg,.jpeg,.png,.gif"
                onDrop={handleOnLoad}
                // disabled={!state.description}
              >
                {({ getRootProps, getInputProps }) => (
                  <section className={classes.dropZoneContainer}>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      {state.src ? (
                        <img
                          className={classes.image}
                          alt="pic"
                          src={state.src}
                        />
                      ) : (
                        <p>Загрузить обложку, обязательно.</p>
                      )}
                    </div>
                  </section>
                )}
              </Dropzone>
              <Button
                disabled={!valid || !state.src}
                type="submit"
                className={classes.submitBtn}
                variant="contained"
                color="primary"
              >
                Сохранить
              </Button>
            </Paper>
          </form>
        )}
      />
      {id && (
        <Paper style={{ padding: 15, marginBottom: 15 }} elevation={0}>
          <div className={classes.releaseBlock}>
            <div>Выпуски</div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="small"
              onClick={() =>
                history.push(`/admin/classifiers/programs/release/new/${id}`)
              }
            >
              Добавить
            </Button>
          </div>
          <Divider />
          <SimpleTable
            rowClick={(releaseId) =>
              history.push(`/admin/classifiers/programs/release/${releaseId}`)
            }
            list={values.releases}
            header={[
              {
                title: 'Название',
                value: 'title',
                align: 'left',
              },
              {
                title: 'Опубликованно',
                value: 'is_active',
                align: 'center',
              },
            ]}
          />
        </Paper>
      )}
    </>
  );
};

export default CrudPrograms;
