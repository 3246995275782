import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { TextField } from 'mui-rff';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { patchArticle } from '../../../request/index.js';
import useGetVideo, {
  YOUTUBE_REGEXP,
  RUTUBE_REGEXP,
  VK_REGEXP,
  VK_REGEXP_OLD,
} from 'hook/useGetVideo';

const validate = ({ videoLink }) => {
  const errors = {};
  if (!videoLink) {
    errors.videoLink = 'Обязательно для заполнения';
  }
  if (
    videoLink &&
    !videoLink.match(YOUTUBE_REGEXP) &&
    !videoLink.match(RUTUBE_REGEXP) &&
    !videoLink.match(VK_REGEXP) &&
    !videoLink.match(VK_REGEXP_OLD)
  ) {
    errors.videoLink = 'Вставьте ссылку c доступного видео хостинга';
  }
  return errors;
};

const ArticleVideoCover = ({ article, fetchArticles }) => {
  const { adapter, prepareLink, platformMap } = useGetVideo();
  const { opening_video, video_cover } = article;
  const init = adapter(video_cover, opening_video);
  const [state, setState] = useState({ videoLink: init });
  const [link, setlink] = useState(init);

  const onSubmit = async ({ videoLink }) => {
    if (link) {
      setlink(null);
      setState({ videoLink: '' });
      await patchArticle(article.slug, { opening_video: {} }).then(() => {
        fetchArticles();
      });
    } else {
      const video = prepareLink(videoLink);
      const { id, platform } = video;
      const embedLink = platformMap[platform](id);
      setState({ videoLink: embedLink });
      setlink(embedLink);
      await patchArticle(article.slug, { opening_video: video }).then(() => {
        fetchArticles();
      });
    }
  };

  return (
    <>
      <Paper style={{ padding: 15, marginBottom: 15 }} elevation={0}>
        <b>Редактирование видео обложки</b>
      </Paper>
      <Paper style={{ padding: 15, marginBottom: 15 }} elevation={0}>
        <Form
          initialValues={state}
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit, valid }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={9}>
                  <TextField
                    required
                    name="videoLink"
                    size="small"
                    label="Ссылка для видео"
                    variant="outlined"
                    style={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} style={{ textAlign: 'right' }}>
                  {link ? (
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      startIcon={<DeleteIcon />}
                      size="medium"
                    >
                      Удалить
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      disabled={!valid}
                      variant="contained"
                      color="default"
                      startIcon={<CloudUploadIcon />}
                      size="medium"
                    >
                      Загрузить
                    </Button>
                  )}
                </Grid>
              </Grid>
              {link && (
                <iframe
                  style={{ marginTop: 20 }}
                  width="560"
                  height="315"
                  src={link}
                  frameBorder="0"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  referrerPolicy="no-referrer"
                  title="frame"
                />
              )}
            </form>
          )}
        />
      </Paper>
    </>
  );
};

export default ArticleVideoCover;
