import React from 'react';
import Button from '@material-ui/core/Button';
import { Form } from 'react-final-form';
import { TextField, Select } from 'mui-rff';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { useSelector } from 'react-redux';
import { createArticle } from '../../request';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTextField-root': {
      width: '100%',
    },
  },
  minWidth: {
    minWidth: 400,
  },
}));

const validate = (values) => {
  const errors = {};
  if (!values.title) {
    errors.title = 'Обязательно для заполнения';
  }
  if (!values.category_id) {
    errors.category_id = 'Обязательно для заполнения';
  }
  return errors;
};

export default function FormDialog({
  onClose,
  open,
  site = {},
  group,
  categories,
}) {
  const classes = useStyles();
  const userID = useSelector((state) => state.auth.id);

  const onSubmit = async (values) => {
    const data = {
      ...values,
      target_id: site.id,
      group_id: group.id,
      authors_ids: [],
      tags_ids: [userID],
      type: 'news',
    };
    const { slug } = await createArticle(data);
    document.location.replace(`/admin/articles/edit/${slug}`);
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit, valid }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <DialogTitle id="form-dialog-title">Новая Статья</DialogTitle>
              <DialogContent className={classes.minWidth}>
                <DialogContentText>
                  {`Создать версию статьи для сайта - ${site ? site.name : ''}`}
                </DialogContentText>
                <Select
                  required
                  name="category_id"
                  label="Выберите рубрику"
                  formControlProps={{
                    margin: 'normal',
                    variant: 'outlined',
                  }}
                >
                  {categories.map(({ title, id, is_active }) => {
                    if (!is_active) return null;
                    return (
                      <MenuItem key={title} value={id}>
                        {title}
                      </MenuItem>
                    );
                  })}
                </Select>
                <TextField
                  required
                  label="Название"
                  variant="outlined"
                  name="title"
                  margin="none"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose} color="primary">
                  Отмена
                </Button>
                <Button
                  disabled={!valid}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Создать
                </Button>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </div>
  );
}
