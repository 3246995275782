import React from 'react';
import MuiTextField from '@material-ui/core/TextField';
import { TextField, Autocomplete } from 'mui-rff';

const ExtraForm = ({ data, tags }) => {
  return (
    <>
      <Autocomplete
        required
        name="tags"
        multiple
        options={tags}
        getOptionLabel={({ title }) => title}
        getOptionValue={({ slug }) => slug}
        filterSelectedOptions
        renderInput={(params) => (
          <MuiTextField required {...params} variant="outlined" label="Теги" />
        )}
      />
      {data.values.tags.length > 1 && (
        <>
          <TextField
            label="Slug"
            disabled
            variant="outlined"
            name="slug"
            margin="none"
            size="small"
          />
          <TextField
            label="СЕО заголовок"
            variant="outlined"
            name="seo_title"
            margin="none"
            size="small"
          />
          <TextField
            label="СЕО описание"
            variant="outlined"
            name="seo_description"
            margin="none"
            size="small"
          />
        </>
      )}
    </>
  );
};

export default ExtraForm;
