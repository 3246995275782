import React from 'react';
import ChoiceArticles from 'feature/ChoiceArticles';

const EditorChoice = () => (
  <ChoiceArticles
    slotsCount={5}
    target_id={2}
    type="main_page/editors_choice"
    showDialogMsg='Сохранить статьи для блока "выбор редакции"?'
    articlePath="articles"
    slotsPath="articles/main_page/editors_choice"
    queryParams={`is_published=true&is_available_by_link=False&type=news,narrative`}
  />
);

export default EditorChoice;
