import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import Button from '@material-ui/core/Button';
import { updateSeverPressGrid } from '../../request';
import { RenderGrid } from './components/RenderGrid';

const useStyles = makeStyles(() => ({
  SeverPressMainGrid: {
    display: 'grid',
    gap: 25,
  },
  alignEnd: {
    alignSelf: 'flex-end',
  },

  gridColumn: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: `10px 20px`,
  },
  section: {},
  interesting: {
    alignSelf: 'flex-end',
  },
}));

const target_id = 3;
const subscription = { submitting: true, pristine: true };

const SeverPressMainGrid = (props) => {
  const { data } = props;
  const classes = useStyles();

  const onSubmit = async (values) => {
    await updateSeverPressGrid(values, target_id);
  };

  return (
    <Form
      subscription={subscription}
      initialValues={data}
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting }) => {
        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className={classes.SeverPressMainGrid}>
              <RenderGrid />
            </div>
            <Button
              type="submit"
              className={classes.submitBtn}
              variant="contained"
              color="primary"
              disabled={submitting}
            >
              Сохранить
            </Button>
          </form>
        );
      }}
    />
  );
};

export default SeverPressMainGrid;
