import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { connect } from 'react-redux';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'fixed',
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(189, 195, 199, 0.9)',
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imgContainer: {
    maxWidth: '80%',
  },
  tool: {
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    width: '100%',
  },
}));

const CropImgPreview = ({ img, handleSaveCrop, close, showDialog }) => {
  const classes = useStyles();
  const handleClick = () => {
    showDialog('Сохранить обложку?', async () => {
      handleSaveCrop();
    });
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.imgContainer}>
        <img src={img} alt="pic" className={classes.image} />
        <div className={classes.tool}>
          <ButtonGroup
            variant="contained"
            color="primary"
            aria-label="contained primary button group"
          >
            <Button onClick={close}>отмена</Button>
            <Button onClick={handleClick}>сохранить</Button>
          </ButtonGroup>
        </div>
      </div>
    </div>
  );
};

const mapDispatch = (dispatch) => ({
  showDialog: (message, cb) =>
    dispatch.dialog.toggle({
      message,
      onAgree: cb,
    }),
});

export default connect(null, mapDispatch)(CropImgPreview);
