import React, { useState, useEffect } from 'react';
import { baseSiteRedNorthURL } from 'request/axios';
import { createNewspaper, saveNewspaper, getNewspaper } from '../../../request';
import { useParams, useHistory } from 'react-router-dom';
import { Form } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Switches } from 'mui-rff';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import SimpleTable from 'components/SimpleTable';

const BASE_URL = '/admin/newspapers';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTextField-root': {
      margin: '8px 0',
      width: '100%',
    },
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
    },
  },
  submitBtn: {
    marginRight: 5,
    marginTop: 15,
  },
  dropZoneContainer: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
  image: {
    position: 'relative',
    width: '100%',
    maxWidth: '480px',
    minWidth: '270px',
  },
  issueBlock: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
}));

const validate = ({ title }) => {
  const errors = {};
  if (!title) {
    errors.title = 'Обязательно для заполнения';
  }
  return errors;
};

const CrudNewspaper = () => {
  const history = useHistory();
  const { id } = useParams();
  const classes = useStyles();
  const [values, setValues] = useState({});

  useEffect(() => {
    async function fetchData() {
      const res = await getNewspaper(id);
      if (res.id) setValues(res);
      else history.push(`${BASE_URL}`);
    }
    if (id) fetchData();
  }, []);

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('is_active', Boolean(data.is_active));
    formData.append('title', data.title);
    formData.append('description', data.description);

    try {
      if (id) {
        await saveNewspaper(id, formData);
      } else {
        await createNewspaper(formData);
      }
      history.push(`${BASE_URL}`);
    } catch (error) {
      console.error('Ошибка при сохранении статьи', error);
    }
  };

  return (
    <>
      <Form
        initialValues={values}
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, valid }) => (
          <form
            onSubmit={handleSubmit}
            className={classes.root}
            noValidate
            autoComplete="off"
          >
            <Paper style={{ padding: 15, marginBottom: 15 }} elevation={0}>
              {id && (
                <>
                  <Button
                    href={`${baseSiteRedNorthURL}press/${values.id}`}
                    target="_blank"
                    rel="noreferrer"
                    color="primary"
                  >
                    Перейти на страницу печатного издания
                  </Button>
                  <Divider />
                  <br />
                </>
              )}
              <Switches
                label="Издание опубликовано"
                name="is_active"
                data={{ label: null }}
              />
              <TextField
                required
                label="Название печатного издания"
                variant="outlined"
                name="title"
                margin="none"
                size="small"
              />
              <TextField
                label="Описание печатного издания"
                variant="outlined"
                name="description"
                margin="none"
                size="small"
              />
              <Button
                disabled={!valid}
                type="submit"
                className={classes.submitBtn}
                variant="contained"
                color="primary"
              >
                Сохранить
              </Button>
            </Paper>
          </form>
        )}
      />
      {id && (
        <Paper style={{ padding: 15, marginBottom: 15 }} elevation={0}>
          <div className={classes.issueBlock}>
            <div>Номера</div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="small"
              onClick={() => history.push(`${BASE_URL}/issue/new/${id}`)}
            >
              Добавить
            </Button>
          </div>
          <Divider />
          <SimpleTable
            rowClick={(issueId) => history.push(`${BASE_URL}/issue/${issueId}`)}
            list={values.issues}
            header={[
              {
                title: 'Год',
                value: 'year',
                align: 'left',
              },
              {
                title: 'Номер',
                value: 'number',
                align: 'center',
              },
              {
                title: 'Активен',
                value: 'is_active',
                align: 'center',
              },
            ]}
          />
        </Paper>
      )}
    </>
  );
};

export default CrudNewspaper;
