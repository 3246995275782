import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';

const useStyles = makeStyles(() => ({
  ArticleList: {
    position: 'relative',
    border: '1px #eeeeee solid',
    padding: '10px',
    borderRadius: '5px',
  },
  articlesitem: {
    position: 'relative',
    backgroundColor: '#eeeeee',
    padding: '10px',
    borderRadius: '3px',
    cursor: 'grabbing',
  },
}));

const ArticleList = ({
  articles,
  onScroll = () => null,
  handleDragStart,
  onSearch,
}) => {
  const { results, next } = articles;
  const classes = useStyles();

  return (
    <div className={classes.ArticleList}>
      <TextField
        label="Поиск"
        variant="outlined"
        size="small"
        fullWidth
        onChange={onSearch}
      />
      <InfiniteScroll
        dataLength={results?.length}
        next={() => onScroll(next)}
        hasMore={Boolean(next)}
        loader={<h4>Загрузка...</h4>}
        height="500px"
      >
        {results?.map(({ title, id, slug }) => (
          <p
            data-nodeid={id}
            data-nodetitle={title}
            data-slug={slug}
            draggable="true"
            onDragStart={handleDragStart}
            className={classes.articlesitem}
            key={id}
          >
            {title}
          </p>
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default ArticleList;
