import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import {
  TextField,
  FormControlLabel,
  Radio,
  Tooltip,
  TextareaAutosize,
} from '@material-ui/core/index';
import CloseIcon from '@material-ui/icons/Close';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
const useStyles = makeStyles(() => ({
  MainList: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '1em',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  slotWrapper: {
    position: 'relative',
    borderRadius: '3px',
    display: 'flex',
    flexDirection: 'column',
    justifyCcontent: 'space-between',
    maxWidth: '560px',
    gridTemplateColumns: '1fr 50px',
    alignItems: 'center',
    gap: '20px',
  },
  slotItem: {
    borderRadius: '3px',
    position: 'relative',
    padding: '10px',
    display: 'flex',
    maxHeight: '170px',
  },
  addBlock: {
    borderRadius: '3px',
    position: 'relative',
    padding: '10px',
    backgroundColor: '#eeeeee',
  },
  textBox: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0px 5px 0px 10px',
    maxWidth: '200px',
  },
  dragBox: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F0E9E9',
    marginRight: '10px',
    maxWidth: '260px',
    cursor: 'grabbing',
  },
  dragIcon: {
    padding: 4,
    cursor: 'grabbing',
    transform: 'scale(2)',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    color: '#B7B3B3',
  },
  deleteIcon: {
    background: 'red',
    color: 'white',
    transform: 'scale(1.1)',
    borderRadius: '50%',
    cursor: 'pointer',
    position: 'absolute',
    right: '-5px',
    top: '-5px',
  },
  itemRadio: {
    display: 'flex',
    alignItems: 'center',
  },
  imageBox: {
    position: 'relative',
  },
}));

const BankImageList = ({
  handleDragOver,
  handleDragLeave,
  handleDrop,
  slots,
  handleDragStart,
  handleDelete,
  handleUpdate,
}) => {
  const classes = useStyles();
  let timer;
  const [isCover, setIsCover] = useState(
    slots.filter(({ is_cover }) => is_cover)?.[0]?.id
  );
  const updatePhotoData = (id, data) => {
    clearTimeout(timer);
    timer = setTimeout(async () => {
      await handleUpdate(id, data);
    }, 400);
  };
  const coverHandler = (id) => {
    setIsCover(id);
    updatePhotoData(id, { is_cover: true });
  };

  return (
    <div
      className={classes.MainList}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onDragStart={handleDragStart}
    >
      {slots.map(({ id, title, image, description, order_index }, index) => (
        <div key={id} className={classes.slotWrapper}>
          <div className={classes.slotItem}>
            <div
              draggable="true"
              data-index={index}
              data-nodeid={id}
              data-order_index={order_index}
              className={cn(classes.dragBox, 'slot')}
            >
              <DragIndicatorIcon className={classes.dragIcon} />
            </div>
            <div className={classes.imageBox}>
              <img src={image.src} width={'100%'} height={'100%'} alt={title} />
              <CloseIcon
                className={classes.deleteIcon}
                onClick={() => handleDelete(id)}
              />
            </div>
            <div className={classes.textBox}>
              <div className={classes.itemRadio}>
                <TextField
                  id="outlined-error"
                  label="Название фото"
                  variant="outlined"
                  size={'small'}
                  name="title"
                  defaultValue={title}
                  onChange={({ target }) =>
                    updatePhotoData(id, { [target.name]: target.value })
                  }
                />
                <Tooltip placement="top" title="Установить как обложку">
                  <FormControlLabel
                    style={{ margin: '0px 0px 0px 5px' }}
                    control={
                      <Radio
                        onChange={() => coverHandler(id)}
                        checked={isCover === id}
                        color="primary"
                      />
                    }
                  />
                </Tooltip>
              </div>
              <TextareaAutosize
                id="outlined-error"
                placeholder="Описание фото"
                variant="outlined"
                size={'small'}
                name="description"
                defaultValue={description}
                onChange={({ target }) =>
                  updatePhotoData(id, { [target.name]: target.value })
                }
                style={{ height: '100%' }}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BankImageList;
