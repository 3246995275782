import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import { DatePicker, TextField } from 'mui-rff';

import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import Button from '@material-ui/core/Button';
import dayjs from 'dayjs';
import { getArticleReportURL } from '../../request';
import { download } from 'assets/js/helper';

const validate = (values) => {
  const { pub_date_at_local__lt, pub_date_at_local__gt, partner } = values;
  const errors = {};
  if (!pub_date_at_local__lt) {
    errors.pub_date_at_local__lt = 'Обязательно для заполнения';
  }
  if (!pub_date_at_local__gt) {
    errors.pub_date_at_local__gt = 'Обязательно для заполнения';
  }
  if (!partner) {
    errors.partner = 'Обязательно для заполнения';
  }
  return errors;
};

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTextField-root': {
      margin: '8px 0',
      width: '100%',
    },
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
    },
  },
  submitBtn: {
    marginRight: 5,
    marginTop: 10,
  },
}));

const PartnerReports = () => {
  const classes = useStyles();
  const values = {
    pub_date_at_local__gt: null,
    pub_date_at_local__lt: null,
    partner: null,
  };

  const onSubmit = (data) => {
    try {
      let query = Object.entries(data).reduce((acc, [key, value]) => {
        if (!value) return acc;
        if (key === 'pub_date_at_local__gt') {
          return `${acc}${key}=${dayjs(value).startOf('day').utc().valueOf()}&`;
        }
        if (key === 'pub_date_at_local__lt') {
          return `${acc}${key}=${dayjs(value).endOf('day').utc().valueOf()}&`;
        }
        if (key === 'partner') return `${acc}${key}=${value}&`;
        return acc;
      }, '?');
      const url = getArticleReportURL(query);
      download(`article-report-${dayjs().format('DD-MM-YYYY-HH:MM')}.xls`, url);
    } catch (error) {
      console.error('Ошибка при формировании отчета', error);
    }
  };

  return (
    <Paper style={{ padding: 15, marginBottom: 15 }} elevation={0}>
      <Form
        initialValues={values}
        onSubmit={onSubmit}
        validate={(values) => validate(values)}
        render={({ handleSubmit, valid }) => (
          <form
            onSubmit={handleSubmit}
            className={classes.root}
            noValidate
            autoComplete="off"
          >
            <DatePicker
              required
              name="pub_date_at_local__gt"
              ampm={false}
              label="Дата публикации (с)"
              showTodayButton
              inputVariant="outlined"
              cancelLabel="Отмена"
              todayLabel="Сегодня"
              clearLabel="Очистить"
              dateFunsUtils={DateFnsUtils}
              locale={ruLocale}
              clearable
              labelFunc={(date) =>
                date ? dayjs(date).format('DD.MM.YYYY') : ''
              }
            />
            <DatePicker
              required
              name="pub_date_at_local__lt"
              ampm={false}
              label="Дата публикации (по)"
              showTodayButton
              inputVariant="outlined"
              cancelLabel="Отмена"
              todayLabel="Сегодня"
              clearLabel="Очистить"
              dateFunsUtils={DateFnsUtils}
              locale={ruLocale}
              clearable
              labelFunc={(date) =>
                date ? dayjs(date).format('DD.MM.YYYY') : ''
              }
            />
            <TextField
              required
              label="Партнёрский URL"
              variant="outlined"
              name="partner"
              margin="none"
              size="small"
            />
            <Button
              disabled={!valid}
              type="submit"
              className={classes.submitBtn}
              variant="contained"
              color="primary"
            >
              Скачать
            </Button>
          </form>
        )}
      />
    </Paper>
  );
};

export default PartnerReports;
