import {
  IconButton,
  Tooltip,
  TableRow,
  TableCell,
} from '@material-ui/core/index';
import {
  Edit,
  OpenInNew,
  Visibility,
  Delete,
  VisibilityOff,
  PlayCircleOutline,
  PauseCircleOutline,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { useState } from 'react';
import { savePhotoBank, deletePhotoBank } from '../../request';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
dayjs.locale('ru');

const Actions = ({ item, handlePushBank, showDialog }) => {
  const { slug, is_active, title, created_at, shows_count, photos, authors } =
    item;
  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    tableContainer: {
      marginTop: 30,
    },
    tableRow: {
      cursor: 'pointer',
    },
    tableCellAction: {
      cursor: 'pointer',
      transition: 'all 0.3s',
      border: '1px solid rgba(224, 224, 224, 1) !important',
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: '#3f51b5',
        color: 'white',
      },
    },
  });
  const classes = useStyles();
  const formatDate = (date) => dayjs(date).format('DD MMMM YYYYг HH:mm:ss');
  const [published, setPublished] = useState(is_active);

  return (
    <>
      <TableRow>
        <TableCell
          component="th"
          scope="row"
          onClick={() => handlePushBank(slug)}
          className={classes.tableCellAction}
        >
          {title}
        </TableCell>
        <TableCell align="center">
          {published ? (
            <PlayCircleOutline color="primary" />
          ) : (
            <PauseCircleOutline color="error" />
          )}
        </TableCell>
        <TableCell align="center">{formatDate(created_at)}</TableCell>
        <TableCell align="center">
          <Tooltip title="Редактировать" aria-label="add">
            <IconButton
              color="primary"
              aria-label="remove papper"
              onClick={() => handlePushBank(slug)}
            >
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Перейти на сайт" aria-label="add">
            <IconButton
              color="primary"
              aria-label="remove paper"
              onClick={() => window.open(`https://ks-yanao.ru/photo/${slug}`)}
            >
              <OpenInNew />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={published ? 'Снять с публикации' : 'Опубликовать'}
            aria-label="add"
          >
            <span>
              <IconButton
                color="primary"
                onClick={async () => {
                  await savePhotoBank(slug, { is_active: !published });
                  setPublished((prev) => !prev);
                }}
              >
                {published ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </span>
          </Tooltip>

          <Tooltip title="Удалить" aria-label="add">
            <IconButton
              onClick={() => {
                showDialog('Удалить фотобанк?', async () => {
                  await deletePhotoBank(slug);
                });
              }}
              color="secondary"
              aria-label="remove papper"
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell align="center">
          <span>{`${authors[0].first_name} ${authors[0].last_name}`}</span>
        </TableCell>
        <TableCell align="center">{photos.length}</TableCell>
        <TableCell align="center">{shows_count || 0}</TableCell>
      </TableRow>
    </>
  );
};

const mapDispatch = (dispatch) => ({
  showDialog: (message, cb) =>
    dispatch.dialog.toggle({
      message,
      onAgree: cb,
    }),
  showSnakebar: dispatch.snakebar.showSnakebar,
});

export default connect(null, mapDispatch)(Actions);
