import React from 'react';
import ChoiceArticles from 'feature/ChoiceArticles';

const SpEditorChoice = () => (
  <ChoiceArticles
    slotsCount={6}
    target_id={3}
    type="main_page/editors_choice"
    showDialogMsg='Сохранить статьи для блока "Популярное"?'
    articlePath="articles"
    slotsPath="articles/main_page/editors_choice"
    queryParams={`is_published=true&is_available_by_link=False&type=news,narrative`}
  />
);

export default SpEditorChoice;
