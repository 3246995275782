import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import { TextField, Switches } from 'mui-rff';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { useParams, useHistory } from 'react-router-dom';
import { getRelease, saveRelease, createRelease } from '../../../../request';
import { baseSiteURL } from 'request/axios';
import useGetVideo, {
  YOUTUBE_REGEXP,
  RUTUBE_REGEXP,
  VK_REGEXP,
  VK_REGEXP_OLD,
} from 'hook/useGetVideo';
import Dropzone from 'react-dropzone';
import { getBase64Image } from 'assets/js/helper';
import {
  getFormData,
  modularEditorAdapter,
  descriptionAddLink,
} from './helpers/index';
import ModularEditor from 'feature/ModularEditor';
import Preloader from 'components/Preloader';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTextField-root': {
      margin: '8px 0',
      width: '100%',
    },
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
    },
  },
  submitBtn: {
    marginRight: 5,
    marginTop: 15,
  },
  linkBlock: {
    display: 'flex',
    gap: 14,
  },
  dropZoneContainer: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
  image: {
    position: 'relative',
    width: '100%',
    maxWidth: '480px',
    minWidth: '270px',
  },
  modularEditor: {
    position: 'relative',
    borderRadius: 4,
    border: '1px #c4c4c4 solid',
    padding: '18.5px 14px',
    '&:hover': {
      border: '1px rgba(0, 0, 0, 0.87) solid',
    },
    '&::first-child': {
      display: 'none !important',
    },
    '@global': {
      '.sc-fjdhpX:nth-of-type(1)': {
        display: 'none !important',
      },
    },
  },
}));

const validate = ({ title, description, url }) => {
  const errors = {};
  if (!title) {
    errors.title = 'Обязательно для заполнения';
  }
  if (!url) {
    errors.url = 'Обязательно для заполнения';
  }
  if (
    url &&
    !url.match(YOUTUBE_REGEXP) &&
    !url.match(RUTUBE_REGEXP) &&
    !url.match(VK_REGEXP) &&
    !url.match(VK_REGEXP_OLD)
  ) {
    errors.url = 'Вставьте ссылку c доступного видео хостинга';
  }
  return errors;
};

const Release = () => {
  const [mdInstance, setMdInstance] = useState(null);
  const { adapter, prepareLink } = useGetVideo();
  const history = useHistory();
  const { id, programId } = useParams();
  const classes = useStyles();
  const [values, setValues] = useState();
  const [state, setState] = useState({});

  useEffect(() => {
    async function fetchData() {
      const res = await getRelease(id);
      if (res.id)
        setValues({
          ...res,
          url: adapter(res.url, res.opening_video),
        });
      else history.push(`/admin/classifiers/programs`);

      if (res.cover) setState({ src: res.cover });
    }
    if (id) fetchData();
    else setValues({});
  }, []);

  useEffect(() => {
    if (mdInstance && typeof mdInstance.init === 'function') {
      mdInstance.init();
    }
    return () => {
      if (mdInstance) mdInstance.destroy();
    };
  }, [mdInstance]);

  const onSubmit = async (data) => {
    const { value } = mdInstance.editor;
    const valueJSON = value.toJSON();
    const formData = getFormData(data);
    formData.set('opening_video', JSON.stringify(prepareLink(data.url)));
    formData.delete('url');
    formData.delete('cover');
    formData.set('description', JSON.stringify(valueJSON));
    formData.set('description_link_title', '');
    formData.set('description_link', '');

    try {
      if (id) {
        if (state.file) formData.append('cover', state.file);
        await saveRelease(id, formData);
      } else {
        formData.append('program', programId);
        if (state.file) formData.append('cover', state.file);
        await createRelease(formData);
      }
      history.push(
        `/admin/classifiers/programs/edit/${programId || values.program_slug}`
      );
    } catch (error) {
      console.error('ошибка при сохранении статьи', error);
    }
  };

  const handleOnLoad = async (file) => {
    const baseFile = await getBase64Image(file[0]);
    setState({
      file: file[0],
      src: baseFile,
    });
  };
  const onInitHandler = (value) => setMdInstance(value);

  if (!values) {
    return <Preloader />;
  }

  return (
    <Form
      initialValues={values}
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, valid }) => (
        <form
          onSubmit={handleSubmit}
          className={classes.root}
          noValidate
          autoComplete="off"
        >
          <Paper style={{ padding: 15, marginBottom: 15 }} elevation={0}>
            {id && (
              <>
                <Button
                  href={`${baseSiteURL}tv/${values.program}/${id}`}
                  target="_blank"
                  rel="noreferrer"
                  color="primary"
                >
                  Перейти на страницу выпуска
                </Button>
                <Divider />
                <br />
              </>
            )}
            <Switches
              label="Выпуск опубликован"
              name="is_active"
              data={{ label: null }}
            />
            <TextField
              required
              label="Название выпуска"
              variant="outlined"
              name="title"
              margin="none"
              size="small"
            />
            <ModularEditor
              className={classes.modularEditor}
              initValue={
                typeof values.description === 'string'
                  ? modularEditorAdapter(
                      values.description,
                      values.description_link_title,
                      values.description_link
                    )
                  : descriptionAddLink(
                      values.description,
                      values.description_link_title,
                      values.description_link
                    )
              }
              configType={{ id: 4 }}
              onInit={onInitHandler}
              articleID={id}
            />
            <TextField
              required
              label="Ссылка на видео"
              placeholder="youtube, vkontakte, rutube"
              variant="outlined"
              name="url"
              margin="none"
              size="small"
            />
            <Dropzone
              accept=".img,.jpg,.jpeg,.png,.gif"
              onDrop={handleOnLoad}
              // disabled={!state.description}
            >
              {({ getRootProps, getInputProps }) => (
                <section className={classes.dropZoneContainer}>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {state?.src ? (
                      <img
                        className={classes.image}
                        alt="pic"
                        src={state?.src}
                      />
                    ) : (
                      <p>Загрузить обложку.</p>
                    )}
                  </div>
                </section>
              )}
            </Dropzone>
            <Button
              disabled={!valid}
              type="submit"
              className={classes.submitBtn}
              variant="contained"
              color="primary"
            >
              Сохранить
            </Button>
          </Paper>
        </form>
      )}
    />
  );
};

export default Release;
