import React from 'react';
import MuiTextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(() => ({
  block: {
    marginTop: 8,
    marginBottom: 4,
  },
  textFieldBlock: {
    display: 'flex',
  },
}));

const event = (value) => ({
  target: {
    name: 'theses',
    value,
  },
});

export const Theses = ({ value = [], onChange = () => undefined }) => {
  const classes = useStyles();
  const handleAdd = () => {
    onChange(event([...value, '']));
  };
  const handleDelete = (index) => {
    const newArray = [...value];
    newArray.splice(index, 1);
    onChange(event(newArray));
  };
  const handleInput = (e, index) => {
    const newArray = [...value];
    newArray[index] = e.target.value;
    onChange(event(newArray));
  };
  return (
    <div className={classes.block}>
      <Button
        onClick={handleAdd}
        variant="contained"
        size="small"
        startIcon={<AddIcon />}
      >
        Добавить тезис
      </Button>
      {value.map((val, index) => (
        <div key={index} className={classes.textFieldBlock}>
          <MuiTextField
            variant="outlined"
            margin="none"
            size="small"
            value={val}
            onChange={(e) => handleInput(e, index)}
          />
          <IconButton
            color="secondary"
            aria-label="remove papper"
            onClick={() => handleDelete(index)}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ))}
    </div>
  );
};
