import {
  baseSiteURL,
  baseSiteRedNorthURL,
  baseSiteSeverPressURL,
} from 'request/axios';

export const tagsSiteLinks = [
  {
    label: 'Тег на «Ямал-Медиа»',
    href: `${baseSiteURL}tag/`,
  },
  {
    label: 'Тег на Красный север',
    href: `${baseSiteRedNorthURL}tag/`,
  },
  {
    label: 'Тег на Север-пресс',
    href: `${baseSiteSeverPressURL}tag/`,
  },
];
