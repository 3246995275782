import React from 'react';
import Grid from '@material-ui/core/Grid';
import CardArticleVersion from 'components/CardArticleVersion';

const CompareArticleVersion = ({ firstVersion, secondVersion }) => {
  if (!firstVersion || !secondVersion) return null;
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={6}>
            <CardArticleVersion {...firstVersion} type="first" />
          </Grid>
          <Grid item xs={6}>
            <CardArticleVersion {...secondVersion} type="second" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CompareArticleVersion;
