import React, { useState, useEffect, useRef } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import { TextField, DateTimePicker } from 'mui-rff';
import Button from '@material-ui/core/Button';
import { useParams, useHistory } from 'react-router-dom';
import { setUniversalData, getUniversalData } from '../../../request';
import Dropzone from 'react-dropzone';
import { getBase64Image } from 'assets/js/helper';
import RootRef from '@material-ui/core/RootRef';
import dayjs from 'dayjs';
import IconButton from '@material-ui/core/IconButton';
import UpdateIcon from '@material-ui/icons/Update';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import cn from 'classnames';

const MAX_TEXT = 45;

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTextField-root': {
      margin: '8px 0',
      width: '100%',
    },
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
    },
  },
  submitBtn: {
    marginRight: 5,
  },
  dropZoneContainer: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '19px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    margin: '8px 0',
  },
  dropZone: {
    position: 'relative',
    width: '100%',
    textAlign: 'center',
  },
  avatar: {
    cursor: 'pointer',
    width: '100%',
  },
  calendarWrapper: {
    display: 'flex',
  },
  todayBtn: {
    width: 40,
  },
  error: {
    color: 'red',
  },
}));

const validate = ({ url, starts_at, title }) => {
  const errors = {};
  if (!url) {
    errors.url = 'Обязательно для заполнения';
  }
  if (!starts_at) {
    errors.starts_at = 'Обязательно для заполнения';
  }
  if (!title) {
    errors.title = 'Обязательно для заполнения';
  }
  if (title?.length && title.length > MAX_TEXT) {
    errors.title = 'Превышен лимит символов';
  }
  return errors;
};

const CrudBroadcast = () => {
  const calendarRef = useRef();
  const history = useHistory();
  const { id } = useParams();
  const classes = useStyles();
  const [values, setValues] = useState({
    starts_at: new Date(),
  });
  const [image, setImage] = useState();
  const [file, setFile] = useState();

  useEffect(() => {
    async function fetchData() {
      const res = await getUniversalData({
        path: 'np/triple-block/live-broadcast/' + id,
      });
      if (res.id) setValues(res);
      else history.push(`/admin/sp-main-broadcast`);
    }
    if (id) fetchData();
  }, []);

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('target', 3);
    if (typeof data.starts_at === 'object')
      formData.append('starts_at', data.starts_at.toJSON());
    formData.append('title', data.title);
    formData.append('url', data.url);
    if (file) formData.append('image', file);

    try {
      if (id) {
        await setUniversalData({
          path: 'np/triple-block/live-broadcast/' + id,
          data: formData,
          method: 'put',
        });
      } else {
        await setUniversalData({
          path: 'np/triple-block/live-broadcast',
          data: formData,
        });
      }
      history.push(`/admin/sp-main-broadcast`);
    } catch (error) {
      console.error('ошибка при сохранении', error);
    }
  };

  const handleOnLoad = async (imgFile) => {
    const baseFile = await getBase64Image(imgFile[0]);
    setImage(baseFile);
    setFile(imgFile[0]);
  };

  return (
    <Form
      initialValues={values}
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, valid, form, ...rest }) => (
        <form
          onSubmit={handleSubmit}
          className={classes.root}
          noValidate
          autoComplete="off"
        >
          <Paper style={{ padding: 15 }} elevation={0}>
            <TextField
              required
              label="Заголовок"
              variant="outlined"
              name="title"
              margin="none"
              size="small"
            />
            {rest?.values?.title && (
              <span
                className={cn({
                  [classes.error]: rest?.values?.title.length > MAX_TEXT,
                })}
              >
                {rest.values.title.length} из {MAX_TEXT}
              </span>
            )}
            <br />
            <br />
            <TextField
              required
              label="Ссылка на трансляцию"
              variant="outlined"
              name="url"
              margin="none"
              size="small"
            />
            <div className={classes.calendarWrapper}>
              <RootRef rootRef={calendarRef}>
                <DateTimePicker
                  name="starts_at"
                  ampm={false}
                  label="Дата публикации"
                  showTodayButton
                  inputVariant="outlined"
                  cancelLabel="Отмена"
                  todayLabel="Сегодня"
                  dateFunsUtils={DateFnsUtils}
                  locale={ruLocale}
                  labelFunc={(date) => dayjs(date).format('DD.MM.YYYY - HH:mm')}
                />
              </RootRef>
              <IconButton
                className={classes.todayBtn}
                color="primary"
                aria-label="calendar today"
                component="span"
                onClick={() => form.change('starts_at', new Date())}
              >
                <UpdateIcon />
              </IconButton>
            </div>
            <Dropzone accept=".img,.jpg,.jpeg,.png,.gif" onDrop={handleOnLoad}>
              {({ getRootProps, getInputProps }) => (
                <section className={classes.dropZoneContainer}>
                  <div className={classes.dropZone} {...getRootProps()}>
                    <input {...getInputProps()} />
                    {image || rest?.values?.image?.src ? (
                      <img
                        alt="pic"
                        src={image || rest?.values?.image?.src}
                        className={classes.avatar}
                      />
                    ) : (
                      <p>Перенесите или выберите фото</p>
                    )}
                  </div>
                </section>
              )}
            </Dropzone>
            <Button
              disabled={!valid}
              type="submit"
              className={classes.submitBtn}
              variant="contained"
              color="primary"
            >
              Сохранить
            </Button>
          </Paper>
        </form>
      )}
    />
  );
};

export default CrudBroadcast;
