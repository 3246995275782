import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import { TextField, Switches } from 'mui-rff';
import Button from '@material-ui/core/Button';
import { useParams, useHistory } from 'react-router-dom';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ExtraForm from './components/ExtraForm';
import DefaultForm from './components/DefaultForm';
import Grid from '@material-ui/core/Grid';
import {
  createCustomNavs,
  saveCustomNavs,
  getCustomNav,
  getCachedTagsList,
} from '../../request';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTextField-root': {
      margin: '8px 0',
      width: '100%',
    },
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
    },
  },
  submitBtn: {
    marginRight: 5,
  },
}));

const validate = ({ title, tags, is_extra, url }) => {
  const errors = {};
  if (!title) {
    errors.title = 'Обязательно для заполнения';
  }
  if (is_extra && tags && tags.length === 0) {
    errors.tags = 'Обязательно для заполнения';
  }
  if (!is_extra && !url) {
    errors.url = 'Обязательно для заполнения';
  }
  return errors;
};

const CrudCategories = ({
  baseUrl = '/admin/classifiers/extranav',
  target = 1,
  showExtraNav = false,
}) => {
  baseUrl = `${baseUrl}/?target_id=${target}`;
  const history = useHistory();
  const { id } = useParams();
  const classes = useStyles();
  const [values, setValues] = useState({
    tags: [],
  });
  const [tags, setTags] = useState();
  const featchTags = async () => {
    // todo: переделать на асинхронную загрузку по search
    const results = await getCachedTagsList('?is_active=true');
    setTags(results);
  };
  useEffect(() => {
    featchTags();
    async function fetchData() {
      const res = await getCustomNav(id);
      if (res.id) {
        setValues({
          ...res,
          tags: res.url.split(','),
        });
      } else history.push(baseUrl);
    }
    if (id) fetchData();
  }, []);

  const onSubmit = async (data) => {
    try {
      const prepareData = {
        ...data,
        url: data.is_extra ? data.tags.join(',') : data.url,
        seo_title: data.seo_title || data.title,
        is_tag: Boolean(data.tags.length === 1),
        target,
      };

      if (!data.is_extra) {
        delete prepareData.is_tag;
        delete prepareData.tags;
        delete prepareData.seo_title;
        delete prepareData.seo_title;
      }

      if (id) {
        await saveCustomNavs(id, prepareData);
      } else {
        await createCustomNavs(prepareData);
      }
      history.push(baseUrl);
    } catch (error) {
      console.error('ошибка при сохранении статьи', error);
    }
  };

  return (
    <>
      {tags && (
        <Form
          initialValues={values}
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit, valid, ...rest }) => (
            <form
              onSubmit={handleSubmit}
              className={classes.root}
              noValidate
              autoComplete="off"
            >
              <Paper style={{ padding: 15, marginBottom: 15 }} elevation={0}>
                {showExtraNav && (
                  <Tooltip title="При включении 'Кнопка в шапке' ссылка попадает на верхний уровень шапки, по умолчанию добавляется в блок шапки под основным меню ">
                    <IconButton color="primary" aria-label="help">
                      <HelpIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <br />
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>Не активна</Grid>
                  <Grid item>
                    <Switches name="is_active" data={{ label: null }} />
                  </Grid>
                  <Grid item>Активна</Grid>
                </Grid>
                {showExtraNav && (
                  <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>Второй ряд</Grid>
                    <Grid item>
                      <Switches name="is_extra" data={{ label: null }} />
                    </Grid>
                    <Grid item>Первый ряд</Grid>
                  </Grid>
                )}
                <TextField
                  required
                  label="Название ссылки"
                  variant="outlined"
                  name="title"
                  margin="none"
                  size="small"
                />
                {rest.values.is_extra ? (
                  <ExtraForm data={rest} tags={tags} />
                ) : (
                  <DefaultForm />
                )}
                <Button
                  disabled={!valid}
                  type="submit"
                  className={classes.submitBtn}
                  variant="contained"
                  color="primary"
                >
                  Сохранить
                </Button>
              </Paper>
            </form>
          )}
        />
      )}
    </>
  );
};

export default CrudCategories;
