import { Paper } from '@material-ui/core/index';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import {
  Divider,
  Button,
  TextField,
  IconButton,
} from '@material-ui/core/index';
import { Close } from '@material-ui/icons/';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { getPhotoBankList, getUsersAuthorsList } from '../../../request';
import PhotoBankList from 'feature/PhotoBankList/index';
import { useHistory } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';

let timer;
const Photobank = () => {
  const useStyles = makeStyles(() => ({
    formControl: {
      display: 'flex',
      alignItems: 'flex-end',
      flexWrap: 'wrap',
      gap: 20,
      marginBottom: '15px',
    },
    author: {
      minWidth: '250px',
      maxWidth: '400px',
    },
    datePicker: {
      maxWidth: '200px',
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [users, setUsers] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [filter, setFilter] = useState({
    dateFrom: null,
    dateTo: null,
    authors: [],
  });

  async function parsePhotoBanks(query = '') {
    if (!query) {
      const authors = await getUsersAuthorsList(`?target_id=2`);
      setUsers(authors);
    }
    const { results = [] } = await getPhotoBankList(
      `?target_id=2${query ? `&${query}` : ''}`
    );
    setBankList(results);
  }

  useEffect(() => {
    parsePhotoBanks();
  }, []);

  const handleChangeSearch = ({ target }) => {
    clearTimeout(timer);
    timer = setTimeout(async () => {
      await parsePhotoBanks(`search=${target.value}`);
    }, 600);
    setSearch(target.value);
  };
  const handlerChangeFilterDate = ({ key, value }) =>
    setFilter({ ...filter, [key]: value });

  return (
    <>
      <Paper style={{ padding: 15, marginBottom: 15 }} elevation={0}>
        <b>Список фотобанков</b>
      </Paper>
      <Paper style={{ padding: 15 }}>
        <div className={classes.formControl}>
          <Button
            onClick={() => history.push('/admin/photobanks/new')}
            variant="contained"
            color="primary"
          >
            Добавить фотобанк
          </Button>
          <TextField
            label="Поиск"
            value={search}
            onChange={handleChangeSearch}
          />
          <div>
            <KeyboardDatePicker
              className={classes.datePicker}
              format="dd.MM.yyyy"
              id="date-picker-inline"
              label="Дата создания (c)"
              value={filter.dateFrom}
              onChange={(dateFrom) =>
                handlerChangeFilterDate({ key: 'dateFrom', value: dateFrom })
              }
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <IconButton
              onClick={() =>
                handlerChangeFilterDate({ key: 'dateFrom', value: null })
              }
            >
              <Close />
            </IconButton>
          </div>
          <div>
            <KeyboardDatePicker
              className={classes.datePicker}
              format="dd.MM.yyyy"
              id="date-picker-inline"
              label="Дата создания (по)"
              value={filter.dateTo}
              onChange={(dateTo) =>
                handlerChangeFilterDate({ key: 'dateTo', value: dateTo })
              }
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <IconButton
              onClick={() =>
                handlerChangeFilterDate({ key: 'dateTo', value: null })
              }
            >
              <Close />
            </IconButton>
          </div>
          <Autocomplete
            multiple
            options={users}
            disableCloseOnSelect
            getOptionLabel={({ first_name, last_name }) =>
              `${last_name} ${first_name}`
            }
            onChange={(_, newValue) => {
              setFilter({ ...filter, authors: newValue.map(({ id }) => id) });
            }}
            renderOption={(props) => (
              <span>{`${props.last_name} ${props.first_name}`}</span>
            )}
            className={classes.author}
            renderInput={(params) => <TextField {...params} label="Автор" />}
          />
        </div>
        <Divider />
        <PhotoBankList filter={filter} list={bankList} />
      </Paper>
    </>
  );
};

export default Photobank;
