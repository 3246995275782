import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { deleteDocument, getDocuments } from '../../../request';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { tabList } from './constants';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  dropZoneContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginTop: 30,
  },
  tableRow: {
    cursor: 'pointer',
  },
  input: {
    display: 'none',
  },
}));

const Documents = () => {
  const [list, setList] = useState();
  const history = useHistory();
  const asyncFetch = async () => {
    const documents = await getDocuments('?per_page=999999');
    setList(documents);
  };

  useEffect(() => {
    asyncFetch();
  }, []);

  const classes = useStyles();

  const deleteHandler = async (id) => {
    await deleteDocument(id);
    asyncFetch();
  };

  return (
    <Paper style={{ padding: 15, marginBottom: 15 }} elevation={0}>
      <Button
        onClick={() => history.push(`documents/new`)}
        variant="contained"
        color="primary"
      >
        Добавить документ
      </Button>
      {list && (
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="center">Файл</TableCell>
                <TableCell align="center">Вкладка</TableCell>
                <TableCell align="center">Загрузка</TableCell>
                <TableCell align="center">Удалить</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.results.map((row) => (
                <TableRow className={classes.tableRow} key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() =>
                      history.push(
                        `/admin/classifiers/documents/edit/${row.id}`
                      )
                    }
                  >
                    <a
                      href={`/admin/classifiers/documents/edit/${row.id}`}
                      onClick={(e) => e.preventDefault()}
                    >
                      {row.name}
                    </a>
                  </TableCell>
                  <TableCell align="center">
                    <span>
                      {tabList.find((item) => item.value === row.tab).name}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <a href={row.file} target="_blank" rel="noreferrer">
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <CloudDownloadIcon />
                      </IconButton>
                    </a>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      color="secondary"
                      aria-label="delete picture"
                      component="span"
                      onClick={() => deleteHandler(row.id)}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
};

export default Documents;
