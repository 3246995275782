import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Form } from 'react-final-form';
import { TextField, Switches, Select } from 'mui-rff';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { getArticleTargets } from '../../request';
import { useSelector } from 'react-redux';
import {
  positionOptionsJM,
  positionOptionsRN,
  positionOptionsNP,
  deviceTypeOptions,
} from './constants';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTextField-root': {
      margin: '8px 0',
      width: '100%',
    },
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
    },
  },
  submitBtn: {
    marginTop: 16,
  },
}));

const BannersCrud = ({ values, onSubmit, validate }) => {
  const classes = useStyles();

  const [targets, setTargets] = useState([]);
  const permissions = useSelector((state) =>
    state?.auth?.sitePermissions.map(({ name }) => name)
  );
  useEffect(() => {
    async function fetchData() {
      const articleTargets = await getArticleTargets();
      const userSitePermissions = articleTargets?.filter(({ name }) =>
        permissions.includes(name)
      );
      setTargets(userSitePermissions);
    }
    fetchData();
  }, []);

  const positionMap = {
    1: positionOptionsJM,
    2: positionOptionsRN,
    3: positionOptionsNP,
  };

  return (
    <Form
      initialValues={values}
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, valid, values: formValues }) => {
        const positionOptions = positionMap[formValues.target_id];

        return (
          <form
            onSubmit={handleSubmit}
            className={classes.root}
            noValidate
            autoComplete="off"
          >
            <Paper style={{ padding: 15, marginBottom: 15 }} elevation={0}>
              <Switches
                label="Баннер активен"
                name="is_active"
                data={{ label: null }}
              />
              <TextField
                required
                label="Название"
                variant="outlined"
                name="code_name"
                margin="none"
                size="small"
              />
              <TextField
                required
                multiline
                rows={10}
                label="Код баннера"
                variant="outlined"
                name="value"
                margin="none"
                size="small"
              />
              <TextField
                label="Высота баннера"
                variant="outlined"
                name="height"
                margin="none"
                size="small"
                type="number"
                defaultValue="0"
                helperText="Для автовысоты введите 0"
              />
              <Select
                required
                name="type"
                label="Показывать на устройстве"
                formControlProps={{
                  margin: 'dense',
                  variant: 'outlined',
                }}
              >
                {deviceTypeOptions.map(({ id, label, value }) => (
                  <MenuItem key={id} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
              <Select
                required
                name="position"
                label="Выберите расположение"
                formControlProps={{
                  margin: 'dense',
                  variant: 'outlined',
                }}
              >
                {positionOptions.map(({ id, label, value }) => (
                  <MenuItem key={id} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
              <Select
                required
                name="priority"
                label="Приоритет"
                formControlProps={{
                  margin: 'dense',
                  variant: 'outlined',
                }}
              >
                {[...Array(5)].map((el, index) => (
                  <MenuItem key={index} value={index + 1}>
                    {index + 1}
                  </MenuItem>
                ))}
              </Select>
              <Select
                required
                name="target_id"
                label="Выберите сайт"
                formControlProps={{
                  margin: 'dense',
                  variant: 'outlined',
                }}
              >
                {targets.map(({ name, id }) => (
                  <MenuItem key={name} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <Button
                disabled={!valid}
                type="submit"
                className={classes.submitBtn}
                variant="contained"
                color="primary"
              >
                Сохранить
              </Button>
            </Paper>
          </form>
        );
      }}
    />
  );
};

export default BannersCrud;
