import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import { TextField, Switches } from 'mui-rff';
import { TextareaAutosize } from '@material-ui/core/index';
import Button from '@material-ui/core/Button';
import { useParams, useHistory } from 'react-router-dom';
import { createTag, saveTag, getTag } from '../../../request';
import { tagsSiteLinks } from './tags.constants';

const BASE_URL = '/admin/classifiers/tags';
const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTextField-root': {
      margin: '8px 0',
      width: '100%',
    },
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
    },
  },
  submitBtn: {
    marginRight: 5,
  },
}));

const validate = ({ title }) => {
  const errors = {};
  if (!title) {
    errors.login = 'Обязательно для заполнения';
  }
  return errors;
};

const CrudCategories = () => {
  const history = useHistory();
  const { id } = useParams();
  const classes = useStyles();
  const [values, setValues] = useState({});
  useEffect(() => {
    async function fetchData() {
      const res = await getTag(id);
      if (res.id) setValues(res);
      else history.push(BASE_URL);
    }
    if (id) fetchData();
  }, []);

  const onSubmit = async (values) => {
    try {
      if (id) {
        await saveTag(id, values);
      } else {
        await createTag(values);
      }
      history.push(BASE_URL);
    } catch (error) {
      console.error('ошибка при сохранении тега', error);
    }
  };

  return (
    <Form
      initialValues={values}
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, valid }) => (
        <form
          onSubmit={handleSubmit}
          className={classes.root}
          noValidate
          autoComplete="off"
        >
          <Paper
            className={classes.paperHead}
            style={{ padding: 15, marginBottom: 15 }}
            elevation={0}
          >
            <div className={classes.buttonGroupWrapper}>
              {tagsSiteLinks.map(({ label, href }) => (
                <Button
                  key={label}
                  type="submit"
                  className={classes.submitBtn}
                  variant="outlined"
                  color="primary"
                  href={`${href}${values.slug}`}
                  target="_blank"
                  size="small"
                >
                  {label}
                </Button>
              ))}
            </div>
          </Paper>
          <Paper style={{ padding: 15 }} elevation={0}>
            <Switches
              label="Тег активен"
              name="is_active"
              data={{ label: null }}
            />
            <br />
            <Switches
              label="Является ли тег региональным (будет доступен на сайте в меню ЯНАО)"
              name="is_regional"
              data={{ label: null }}
            />
            <br />
            <Switches
              label="Важная тема"
              name="is_thematic"
              data={{ label: null }}
            />
            <TextField
              required
              label="Название тега"
              variant="outlined"
              name="title"
              margin="none"
              size="small"
            />
            <TextField
              label="Описание тега"
              variant="outlined"
              name="description"
              margin="none"
              size="small"
              InputProps={{
                inputComponent: TextareaAutosize,
              }}
            />
            <TextField
              label="Слаг"
              variant="outlined"
              name="slug"
              margin="none"
              size="small"
              disabled
            />
            <Button
              disabled={!valid}
              type="submit"
              className={classes.submitBtn}
              variant="contained"
              color="primary"
            >
              Сохранить
            </Button>
          </Paper>
        </form>
      )}
    />
  );
};

export default CrudCategories;
