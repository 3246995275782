import {
  baseSiteURL,
  baseSiteRedNorthURL,
  baseSiteSeverPressURL,
} from 'request/axios';

export const rubricsSiteLinks = [
  {
    label: 'Рубрика на «Ямал-Медиа»',
    href: `${baseSiteURL}category/`,
  },
  {
    label: 'Рубрика на Красный север',
    href: `${baseSiteRedNorthURL}category/`,
  },
  {
    label: 'Рубрика на Север-пресс',
    href: `${baseSiteSeverPressURL}category/`,
  },
];
