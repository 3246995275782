import { Paper, Button, TextField } from '@material-ui/core/index';
import { createPhotoBank } from '../../../../request';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const NewPhotobank = () => {
  const [initialData, setInitialData] = useState({
    title: '',
    description: '',
  });
  const history = useHistory();
  const userID = useSelector((state) => state.auth.id);

  const onSubmit = async () => {
    let form = new FormData();
    form.append('title', initialData.title);
    form.append('description', initialData.description);
    form.append('authors', [userID]);
    form.append('target', 2);
    const { slug } = await createPhotoBank(form);
    if (slug) history.push(`/admin/photobanks/edit/${slug}`);
  };

  return (
    <>
      <Paper style={{ padding: 15, marginBottom: 25 }} elevation={0}>
        <b>Создать фотобанк</b>
      </Paper>
      <Paper style={{ padding: 15, display: 'flex', flexDirection: 'column' }}>
        <TextField
          required
          id="outlined-error"
          label="Название"
          variant="outlined"
          name="title"
          margin="dense"
          onChange={({ target }) =>
            setInitialData({ ...initialData, [target.name]: target.value })
          }
        />
        <TextField
          id="outlined-error"
          label="Описание"
          variant="outlined"
          name="description"
          margin="dense"
          onChange={({ target }) =>
            setInitialData({ ...initialData, [target.name]: target.value })
          }
        />
        <Button
          disabled={!initialData.title}
          variant="contained"
          color="primary"
          onClick={onSubmit}
        >
          Создать
        </Button>
      </Paper>
    </>
  );
};
export default NewPhotobank;
