import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
// core components
import Admin from 'layouts/Admin';
import Snackbar from 'components/Snackbar/Snackbar';
import Dialog from 'components/Dialog/Dialog';
import AddAlert from '@material-ui/icons/AddAlert';
import Auth from 'layouts/Auth';
import PrivateRoute from 'components/PrivateRoute';

import 'assets/css/material-dashboard-react.css?v=1.9.0';

const App = ({ snakebarData, closeSnakeBar, isAuth, autoLogin }) => {
  useEffect(autoLogin, []);
  return (
    <>
      <Dialog />
      <Snackbar
        place="tr"
        color={snakebarData.type}
        icon={AddAlert}
        message={snakebarData.message}
        open={snakebarData.isShow}
        closeNotification={closeSnakeBar}
        close
      />
      <Switch>
        {!isAuth && <Route path="/auth" component={Auth} />}
        <PrivateRoute
          path="/admin"
          component={Admin}
          isAuthenticated={isAuth}
        />
        <Redirect from="/" to="/admin" />
      </Switch>
    </>
  );
};

const mapState = (state) => ({
  isAuth: state.auth.isAuth,
  snakebarData: state.snakebar,
});

const mapDispatch = (dispatch) => ({
  closeSnakeBar: dispatch.snakebar.close,
  autoLogin: dispatch.auth.autoLogin,
});

export default connect(mapState, mapDispatch)(App);
