import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Form } from 'react-final-form';
import { Select } from 'mui-rff';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import {
  createDocument,
  getArticleTargets,
  getDocuments,
  saveDocument,
} from '../../../request';
import { useSelector } from 'react-redux';
import Dropzone from 'react-dropzone';
import cn from 'classnames';
import { useHistory, useParams } from 'react-router-dom';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { tabList } from './constants';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  dropZoneContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    cursor: 'pointer',
    transition: 'border .24s ease-in-out, color .24s ease-in-out',
    '&:hover, &:focus': {
      backgroundColor: '#e6e6e6',
    },
  },
  dropZoneContainerActive: {
    borderColor: '#3f51b5',
    borderStyle: 'solid',
    backgroundColor: '#fafafa',
    color: '#3f51b5',
    fontWeight: '400',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
  fileName: {
    fontSize: '1.2em',
    margin: '10px 0',
    display: 'flex',
    alignItems: 'center',
    color: '#3f51b5',
    fontWeight: '400',
  },
}));

const validate = ({ target, fileData }) => {
  const errors = {};
  if (!target) {
    errors.target_id = 'Обязательно для заполнения';
  }
  if (!fileData) {
    errors.fileData = 'Обязательно для заполнения';
  }
  return errors;
};

const CrudDocuments = () => {
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = useState();
  const { id } = useParams();

  const [targets, setTargets] = useState([]);

  const permissions = useSelector((state) =>
    state?.auth?.sitePermissions.map(({ name }) => name)
  );

  useEffect(() => {
    async function fetchData() {
      const articleTargets = await getArticleTargets();
      const userSitePermissions = articleTargets?.filter(({ name }) =>
        permissions.includes(name)
      );
      if (id) {
        setData(await getDocuments(id));
      }
      setTargets(userSitePermissions);
    }
    fetchData();
  }, []);

  const prepareFormData = (fileData, target, tab) => {
    const formData = new FormData();
    if (fileData) {
      const name = fileData[0]?.name?.split('.');
      formData.append('file', fileData[0]);
      formData.append('name', name[0]);
      formData.append('title', name[1]);
    }
    formData.append('target', target);
    formData.append('tab', tab);
    return formData;
  };

  const onSubmit = useCallback(async (values) => {
    const { fileData, target, tab } = values;
    const createData = prepareFormData(fileData, target, tab);
    try {
      if (id) {
        await saveDocument(id, createData);
      } else {
        await createDocument(createData);
      }
      history.push(`/admin/classifiers/documents`);
    } catch (error) {
      console.error('ошибка при сохранении документа', error);
    }
  }, []);

  return (
    <Form
      mutators={{
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      onSubmit={onSubmit}
      validate={!id && validate}
      initialValues={data}
      render={({ handleSubmit, valid, form, values }) => {
        const handleOnLoad = (files) => {
          form.mutators.setValue('fileData', files);
        };

        const localData = values.fileData?.[0] || data;
        return (
          <form
            onSubmit={handleSubmit}
            className={classes.root}
            noValidate
            autoComplete="off"
          >
            <Paper style={{ padding: 15, marginBottom: 15 }} elevation={0}>
              <Dropzone
                onDrop={handleOnLoad}
                accept="application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              >
                {({ getRootProps, getInputProps }) => (
                  <section
                    className={cn(classes.dropZoneContainer, {
                      [classes.dropZoneContainerActive]:
                        values?.fileData?.length || data,
                    })}
                    {...getRootProps()}
                  >
                    <div>
                      <input {...getInputProps()} />

                      {localData ? (
                        <p>Файл выбран</p>
                      ) : (
                        <p>
                          Перетащите файл сюда, или кликните для выбора файла
                        </p>
                      )}
                    </div>
                  </section>
                )}
              </Dropzone>
              {localData && (
                <a
                  className={classes.fileName}
                  href={localData.file}
                  target="_blank"
                  rel="noreferrer"
                >
                  <InsertDriveFileIcon /> {localData.name}
                </a>
              )}
              <Select
                required
                name="target"
                label="Выберите сайт"
                formControlProps={{
                  margin: 'dense',
                  variant: 'outlined',
                }}
              >
                {targets.map(({ name, id }) => (
                  <MenuItem key={name} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <Select
                required
                name="tab"
                label="Вкладка"
                formControlProps={{
                  margin: 'dense',
                  variant: 'outlined',
                }}
              >
                {tabList.map(({ value, name }) => (
                  <MenuItem key={name} value={value}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <Button
                disabled={!valid}
                type="submit"
                className={classes.submitBtn}
                variant="contained"
                color="primary"
              >
                Сохранить
              </Button>
            </Paper>
          </form>
        );
      }}
    />
  );
};

export default CrudDocuments;
