/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import { TextField, DatePicker, Switches, Autocomplete } from 'mui-rff';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import Button from '@material-ui/core/Button';
import { useParams, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  createContract,
  saveContract,
  getContract,
  getAuthorsList,
  getArticleReportURL,
} from '../../../request';

import MuiTextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import { download } from 'assets/js/helper';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: '8px 0',
      width: '100%',
    },
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
      justifyContent: 'stretch',
    },
  },
}));

const validate = (values) => {
  const { number, author, start_date, end_date } = values;
  const errors = {};
  if (!author) {
    errors.author = 'Обязательно для заполнения';
  }
  if (!number) {
    errors.number = 'Обязательно для заполнения';
  }
  if (!start_date) {
    errors.start_date = 'Обязательно для заполнения';
  }
  if (!end_date) {
    errors.end_date = 'Обязательно для заполнения';
  }
  return errors;
};

const CrudContract = () => {
  const history = useHistory();
  const { id } = useParams();
  const classes = useStyles();
  const [initValues, setValues] = useState({
    author: {},
    number: '',
    start_date: new Date(),
    end_date: new Date(),
  });
  const [authors, setAuthors] = useState([]);

  useEffect(() => {
    const requests = [getAuthorsList('?is_external=true')];
    if (id) requests.push(getContract(id));
    Promise.all(requests).then(([authorsList, data = {}]) => {
      setAuthors(authorsList);
      if (data.id) {
        setValues(data);
      } else if (id) history.push(`/admin/administration/contracts`);
    });
  }, []);

  const onSubmit = async (values) => {
    const prepareValues = {
      ...values,
      start_date: values.start_date ? Number(values.start_date) : new Date(),
      end_date: values.end_date ? Number(values.end_date) : new Date(),
    };

    try {
      if (id) {
        const contract = await saveContract(id, prepareValues);
        if (contract.id) setValues(contract);
      } else {
        await createContract(prepareValues);
        history.push(`/admin/administration/contracts`);
      }
    } catch (error) {
      console.error('Ошибка при сохранении договора', error);
    }
  };

  const onSubmitReportButton = (contractID) => {
    try {
      const url = getArticleReportURL(`?contract_id=${contractID}`);
      download(
        `contract-report-${dayjs().format('DD-MM-YYYY-HH:MM')}.xls`,
        url
      );
    } catch (error) {
      console.error('Ошибка при формировании отчета', error);
    }
  };

  return (
    <Form
      initialValues={initValues}
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, valid }) => (
        <form
          onSubmit={handleSubmit}
          className={classes.root}
          noValidate
          autoComplete="off"
        >
          <Paper style={{ padding: 15, marginBottom: 15 }} elevation={0}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Button
                  onClick={() => onSubmitReportButton(id)}
                  disabled={!valid}
                  className={classes.submitBtn}
                  variant="outlined"
                  color="primary"
                  size="small"
                  style={{ marginBottom: 15 }}
                >
                  Скачать отчёт по договору в формате .xls
                </Button>
                <Divider style={{ marginBottom: 15 }}></Divider>
                <Switches
                  label="Договор активен"
                  name="is_active"
                  data={{ label: null }}
                />

                {authors && (
                  <Autocomplete
                    name="author"
                    options={authors}
                    getOptionLabel={(option) =>
                      option.first_name && option.last_name
                        ? option.first_name + ' ' + option.last_name
                        : ''
                    }
                    filterSelectedOptions
                    disableClearable
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        variant="outlined"
                        label="Автор"
                        margin="dense"
                        placeholder="Выберите автора"
                      />
                    )}
                  />
                )}

                <TextField
                  required
                  label="Номер договора"
                  variant="outlined"
                  name="number"
                  margin="none"
                  size="small"
                />

                <DatePicker
                  required
                  name="start_date"
                  ampm={false}
                  label="Дата начала оказания услуг"
                  showTodayButton
                  inputVariant="outlined"
                  cancelLabel="Отмена"
                  todayLabel="Сегодня"
                  clearLabel="Очистить"
                  dateFunsUtils={DateFnsUtils}
                  locale={ruLocale}
                  clearable
                  labelFunc={(date) =>
                    date ? dayjs(date).format('DD.MM.YYYY') : ''
                  }
                />

                <DatePicker
                  required
                  name="end_date"
                  ampm={false}
                  label="Дата окончания оказания услуг"
                  showTodayButton
                  inputVariant="outlined"
                  cancelLabel="Отмена"
                  todayLabel="Сегодня"
                  clearLabel="Очистить"
                  dateFunsUtils={DateFnsUtils}
                  locale={ruLocale}
                  clearable
                  labelFunc={(date) =>
                    date ? dayjs(date).format('DD.MM.YYYY') : ''
                  }
                />
              </Grid>
            </Grid>

            <Button
              disabled={!valid}
              type="submit"
              className={classes.submitBtn}
              variant="contained"
              color="primary"
            >
              Сохранить
            </Button>
          </Paper>
        </form>
      )}
    />
  );
};

export default CrudContract;
