import releaseConfig from './configs/releaseConfig';
import yamalConfig from './configs/yamalConfig';
import rednorthConfig from './configs/rednorthConfig';
import severPressConfig from './configs/severPressConfig';

// configType: 1 = yamal, 2 = red_noth, 3 = sever_press 4 = ReleaseEditor
const initEditor = ({ targetNode, initValue, cb, configType, articleID }) => {
  if (!targetNode) {
    console.error('Ошибка аргументов в модульном редакторе');
    return;
  }

  const md = (window.md = new window.ModularEditor(targetNode, {
    value: initValue,
    className: 'md_panel',
  }));

  switch (configType?.id) {
    case 1:
      yamalConfig(md, articleID);
      break;
    case 2:
      rednorthConfig(md, articleID);
      break;
    case 3:
      severPressConfig(md, articleID);
      break;
    case 4:
      releaseConfig(md);
      break;
    default:
      releaseConfig(md);
      break;
  }

  if (cb && typeof cb === 'function') {
    cb(md);
  }
};

export default initEditor;
