import React, { useEffect, useState } from 'react';
import MuiTextField from '@material-ui/core/TextField';
import { Autocomplete } from 'mui-rff';
import { getUniversalData } from '../../../request';
import Typography from '@material-ui/core/Typography';

let timeoutID = null;

export const AsyncAutocomplete = (props) => {
  const {
    input,
    config,
    label,
    target_id,
    type,
    viewType,
    defaultValue,
  } = props;

  const { onChange, value } = input;

  const error = value?.error?.message ? 'Статья отсутствует' : null;
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { query, path } = config;

  const fetchData = async (query, clear) => {
    setIsLoading(true);
    const urlParams = new URLSearchParams(query);
    const params = Object.fromEntries(urlParams);
    const res = await getUniversalData({
      path,
      query: { ...params, target_id },
    });
    if (res) {
      setIsLoading(false);
      setOptions({
        ...res,
        results: clear ? res.results : [...options.results, ...res.results],
      });
    }
  };

  const handleSearch = (query, value) => {
    clearTimeout(timeoutID);
    timeoutID = setTimeout(() => {
      fetchData(`${query}&search=${value}`, true);
    }, 500);
  };

  useEffect(() => {
    handleSearch(query, inputValue);
  }, [inputValue]);


  return (
    <div>
      {label && (
        <Typography variant="subtitle1" component="p">
          {label}
        </Typography>
      )}

      <Autocomplete
        ListboxProps={{
          onScroll: (event) => {
            const listboxNode = event.currentTarget;
            if (
              Math.floor(listboxNode.scrollTop + listboxNode.clientHeight) ===
                listboxNode.scrollHeight &&
              options.next
            ) {
              fetchData(options.next);
            }
          },
        }}
        {...input}
        value={value.instance || defaultValue}
        onOpen={() => fetchData(query, true)}
        onChange={(e, option) => {
          const testValue = option?.id
            ? {
                id: option.id,
                instance: option,
                type,
                viewType,
              }
            : {};
          onChange(testValue);
          return option;
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        getOptionLabel={({ title, main_text, digit }) =>
          title || main_text || digit || ''
        }
        options={options?.results || []}
        loading={isLoading}
        defaultValue={defaultValue}
        filterSelectedOptions
        noOptionsText={'Результатов не найдено'}
        loadingText={'Загрузка...'}
        renderInput={(params) => {
          return (
            <MuiTextField
              {...params}
              variant="outlined"
              margin="dense"
              error={error}
              helperText={error}
            />
          );
        }}
      />
    </div>
  );
};
