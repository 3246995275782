/* eslint-disable no-case-declarations */
import React from 'react';
import Html from 'slate-html-serializer';
import cn from 'classnames';
import IFrame from './IFrame';
import Sharing from './sharing';
import Gallery from './gallery';
import { EDITOR_TYPES } from './config';

let IS_AMP = false;
let blockQuoteCounter = 1;

const serializeStyles = {
  image: { width: '100%' },
  block_link: {
    background: 'rgb(238, 238, 238)',
    border: '1px solid rgb(204, 204, 204)',
    padding: '5px 10px',
    textAlign: 'center',
    marginBottom: '10px',
  },
  header_three: {
    fontSize: '19px',
    fontWeight: '500',
    marginBottom: '12px',
  },
};

const MARK_SERIALIZE_RULE = {
  serialize(obj, children) {
    if (obj.object !== 'mark') return;
    switch (obj.type) {
      case EDITOR_TYPES.BOLD:
        return <strong>{children}</strong>;
      case EDITOR_TYPES.ITALIC:
        return <em>{children}</em>;
      case EDITOR_TYPES.UNDERLINE:
        return <u>{children}</u>;
      case EDITOR_TYPES.STRIKESTHROUGH:
        return <strike>{children}</strike>;
      default:
        return undefined;
    }
  },
};

const LINK_SERIALIZE_RULE = {
  serialize(obj, children) {
    if (obj.object !== 'inline') return;
    switch (obj.type) {
      case EDITOR_TYPES.LINK:
        const href = obj.data.get('href');
        return (
          <a href={href} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      default:
        return undefined;
    }
  },
};

const ANCHOR_SERIALIZE_RULE = {
  serialize(obj, children) {
    if (obj.object !== 'inline') return;
    switch (obj.type) {
      case EDITOR_TYPES.ANCHOR:
        const id = obj.data.get('anchor');
        return <a id={id}>{children}</a>;
      default:
        return undefined;
    }
  },
};

const SERIALIZE_TO_OLD_HTML_RULES = [
  MARK_SERIALIZE_RULE,
  LINK_SERIALIZE_RULE,
  ANCHOR_SERIALIZE_RULE,
  {
    serialize(obj, children) {
      if (obj.object !== 'block') return;
      const textAlign =
        obj.data.get(EDITOR_TYPES.TEXT_ALIGMENT) ||
        obj.data.get(EDITOR_TYPES.DATA_ALIGMENT);

      const src = obj.data.get('src');
      const source = obj.data.get('source');
      const shareProvider = obj.data.get('shareProvider');
      const showComments = obj.data.get('showComments');
      const height = obj.data.get('height');
      const width = obj.data.get('width');
      const imageDescription = obj.data.get('imageDescription');

      switch (obj.type) {
        case EDITOR_TYPES.DEFAULT_NODE:
          return <p style={{ textAlign }}>{children}</p>;
        case EDITOR_TYPES.TEXT_NODE:
          return <p style={{ textAlign }}>{children}</p>;
        case EDITOR_TYPES.BLOCKQUOTE: {
          const textChild = children.filter(
            (n) => n.type !== 'span' && n.type !== 'strong'
          );
          const ownerChild = children.filter(
            (n) => n.type === 'span' || n.type === 'strong'
          );

          const isOfficialComment = obj.data.get('isOfficialComment');
          const refutationLink = obj.data.get('refutationLink');
          const valueLinkOne = obj.data.get('valueLinkOne');
          const valueLinkTwo = obj.data.get('valueLinkTwo');
          const valueLinkThree = obj.data.get('valueLinkThree');

          let quoteAnchor = null;
          if (isOfficialComment) {
            quoteAnchor = `quote${blockQuoteCounter}`;
            blockQuoteCounter += 1;
          }

          return (
            <div
              className={cn('content-block-quote', {
                'content-aligment-block': textAlign,
                'content-full-width': !textAlign,
                'content-text-align-left': textAlign === 'left',
                'content-text-align-right': textAlign === 'right',
              })}
              data-official-comment={isOfficialComment}
              data-refutation-link={refutationLink}
              data-link-one={valueLinkOne}
              data-link-two={valueLinkTwo}
              data-link-three={valueLinkThree}
              id={quoteAnchor}
            >
              <div className="content-block-quote_text">{textChild}</div>
              <div className="content-block-quote_owner">
                {src ? (
                  <img
                    src={src}
                    alt="автор"
                    height="70"
                    width="70"
                    className="content-block-quote_owner_image"
                  />
                ) : null}
                <div className="content-block-quote_owner_text">
                  {ownerChild}
                </div>
              </div>
            </div>
          );
        }
        case EDITOR_TYPES.BLOCKQUOTE_OWNER:
          return <span className="content-strong-text">{children}</span>;
        case EDITOR_TYPES.BLOCKQUOTE_OWNER_INFO:
          return <span>{children}</span>;
        case EDITOR_TYPES.IMAGE: {
          return (
            <div className="content-new-image">
              {IS_AMP ? (
                <amp-img
                  alt={imageDescription || 'pic'}
                  src={src}
                  style={serializeStyles.image}
                  height={height}
                  width={width}
                  layout="responsive"
                />
              ) : (
                <img
                  alt={imageDescription || 'pic'}
                  src={src}
                  style={serializeStyles.image}
                  height={height}
                  width={width}
                />
              )}
              <div className="content-image_child">{children}</div>
            </div>
          );
        }
        case EDITOR_TYPES.GALLERY:
          return <Gallery content={obj.data.toJSON()} isAmp={IS_AMP} />;
        case EDITOR_TYPES.BLOCK_LINK:
          // const BlockLinkImg = IS_AMP ? (
          //   <amp-img
          //     height="50"
          //     width="50"
          //     alt="pic"
          //     src={obj.data.get('image')}
          //     layout="responsive"
          //   />
          // ) : (
          //   <img src={obj.data.get('image')} alt="pic" />
          // );

          return (
            <div
              className={cn('content-block-link', 'content-aligment-block', {
                'content-text-align-left': textAlign === 'left',
                'content-text-align-right': textAlign === 'right',
                'content-aligment-block-with-image':
                  obj.data.get('showImage') && obj.data.get('image'),
              })}
            >
              {obj.data.get('showImage') && (
                <img alt="новости" src={obj.data.get('image')} />
              )}
              <div className="content-block-link_wrapper">
                <p className="content-block-link_text">{children}</p>
                <a
                  className="content-block-link_button"
                  href={obj.data.get('href')}
                  target="_blank"
                  rel="noreferrer"
                >
                  Подробнее
                </a>
              </div>
            </div>
          );
        case EDITOR_TYPES.BLOCK_LINK_LIST:
          return (
            <div
              className={cn(
                'content-block-list-link',
                'content-aligment-block',
                {
                  'content-text-align-left': textAlign === 'left',
                  'content-text-align-right': textAlign === 'right',
                }
              )}
            >
              {children}
            </div>
          );
        case EDITOR_TYPES.VIDEO: {
          const objData = obj.data;
          const rssType = obj.data.videoTypeRss;
          const videoData = objData && objData.videoData;
          if (videoData) {
            const videoSrc = videoData.src;
            return (
              <IFrame
                src={videoSrc}
                frameborder={0}
                data-rss-type={rssType}
                isAmp={IS_AMP}
              />
            );
          }
          return undefined;
        }
        case EDITOR_TYPES.IFRAME:
          return <IFrame html={obj.data.get('html')} isAmp={IS_AMP} />;
        case EDITOR_TYPES.SHARING:
          if (shareProvider === 'IFRAME' || shareProvider === 'YOUTUBE') {
            return <IFrame {...obj.data.toJSON()} isAmp={IS_AMP} />;
          }
          return (
            <Sharing
              {...{
                shareProvider,
                source,
                showComments,
              }}
            />
          );
        case EDITOR_TYPES.HTML_TEXT:
          return <div dangerouslySetInnerHTML={{ __html: obj.text }} />;
        case EDITOR_TYPES.HORIZONTAL_LINE:
          return <hr />;
        case EDITOR_TYPES.TABLE:
          return <table>{children}</table>;
        case EDITOR_TYPES.TABLE_BODY:
          return <tbody>{children}</tbody>;
        case EDITOR_TYPES.TABLE_HEAD:
          return <thead>{children}</thead>;
        case EDITOR_TYPES.TABLE_ROW:
          return <tr>{children}</tr>;
        case EDITOR_TYPES.TABLE_CELL:
          return <td>{children}</td>;
        case EDITOR_TYPES.HEADING_TWO:
          return <h2 style={{ textAlign }}>{children}</h2>;
        case EDITOR_TYPES.HEADING_THREE:
          return (
            <h3 style={{ ...textAlign, ...serializeStyles.header_three }}>
              {children}
            </h3>
          );
        case EDITOR_TYPES.BULLETED_LIST:
          return (
            <div className="content-ul-with-title">
              <ul>{children}</ul>
            </div>
          );
        case EDITOR_TYPES.NUMBERED_LIST:
          return <ol>{children}</ol>;
        case EDITOR_TYPES.LIST_ITEM:
          return <li>{children}</li>;
        case EDITOR_TYPES.TYPE_IN_PROGRESS:
          return <div className="content-type-in-progress" />;
        case EDITOR_TYPES.PARTNERSHIP:
          return <div data-partnership="smi2" />;
        case EDITOR_TYPES.LEAD:
          return <div className="content-lead">{children}</div>;
        case EDITOR_TYPES.SURVEY_BLOCK:
          return <div className="content-lead">{children}</div>;
        default:
          return <span />;
      }
    },
  },
];

/**
 * Создание сериалайзера и применение правил
 * @type {Html}
 */

const moduleSerialize = (isAmp = false) => {
  IS_AMP = isAmp;
  return new Html({
    rules: SERIALIZE_TO_OLD_HTML_RULES,
    defaultBlock: EDITOR_TYPES.DEFAULT_NODE,
  });
};

export default moduleSerialize;
