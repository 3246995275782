import React from 'react';

const Gallery = ({ content, isAmp }) => {
  if (isAmp) {
    return (
      <amp-carousel
        width="450"
        height="300"
        layout="responsive"
        type="slides"
        role="region"
        aria-label="Basic carousel"
        lightbox
      >
        {content.items.map(({ id, src, description }) => (
          <amp-img
            key={id}
            src={src}
            alt={description}
            width="450"
            height="300"
          />
        ))}
      </amp-carousel>
    );
  }
  return (
    <div className="splide slider-module-serializer">
      <div className="splide__track">
        <ul className="splide__list" style={{ width: '100%' }}>
          {content.items.map(({ id, src, description }) => (
            <li
              key={id}
              className="splide__slide"
              style={{
                width: '100%',
                height: 'auto',
              }}
            >
              <img src={src} alt={description} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Gallery;
