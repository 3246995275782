import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Dropzone from 'react-dropzone';
import Tooltip from '@material-ui/core/Tooltip';
import { getBase64Image } from 'assets/js/helper';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import { TextField, Switches } from 'mui-rff';
import { TextareaAutosize } from '@material-ui/core/index';
import Button from '@material-ui/core/Button';
import { useParams, useHistory } from 'react-router-dom';
import { createCategory, saveCategory, getCategory } from '../../../request';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import { rubricsSiteLinks } from './categories.constants';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTextField-root': {
      margin: '8px 0',
      width: '100%',
    },
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
    },
  },
  submitBtn: {
    marginRight: 5,
    marginTop: '10px',
  },
  dropZoneContainer: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    height: '250px',
    justifyContent: 'center',
  },
  image: {
    position: 'relative',
    width: '100%',
    height: '100%',
    maxHeight: '250px',
  },
  releaseBlock: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  headerDropzone: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'center',
  },
  wrapperCover: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '10px',
    '@media (max-width: 1000px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr 1fr',
    },
  },
  removeIcon: {
    position: 'absolute',
    top: '5px',
    right: '5px',
  },
  downloadCover: {
    cursor: 'pointer',
  },
}));

const validate = ({ title }) => {
  const errors = {};
  if (!title) {
    errors.title = 'Обязательно для заполнения';
  }
  return errors;
};

const CrudCategories = () => {
  const history = useHistory();
  const { id } = useParams();
  const classes = useStyles();
  const [values, setValues] = useState({});
  const [state, setState] = useState({
    cover_mobile: { file: null, src: null },
    cover_desktop: { file: null, src: null },
  });
  useEffect(() => {
    async function fetchData() {
      const res = await getCategory(id);
      if (res.id) {
        setValues(res);
        if (res.cover_mobile) {
          setState((prevState) => {
            return {
              ...prevState,
              cover_mobile: {
                src: res.cover_mobile,
              },
            };
          });
        }
        if (res.cover_desktop) {
          setState((prevState) => {
            return {
              ...prevState,
              cover_desktop: {
                src: res.cover_desktop,
              },
            };
          });
        }
      } else {
        history.push(`/admin/classifiers/categories`);
      }
    }
    if (id) fetchData();
  }, []);

  const onSubmit = async (data) => {
    try {
      if (id) {
        const formData = new FormData();

        formData.append('title', data.title);
        formData.append('description', data.description ?? '');
        formData.append('id', data.id);
        formData.append('is_active', data.is_active);
        formData.append('is_main', data.is_main);
        formData.append('created_at', data.created_at);

        if (state.cover_mobile.file) {
          formData.append('cover_mobile', state.cover_mobile.file);
        } else {
          if (!state.cover_mobile.src) {
            formData.append('cover_mobile', '');
          }
        }

        if (state.cover_desktop.file) {
          formData.append('cover_desktop', state.cover_desktop.file);
        } else {
          if (!state.cover_desktop.src) {
            formData.append('cover_desktop', '');
          }
        }

        await saveCategory(id, formData);
      } else {
        await createCategory(data);
      }
      history.push(`/admin/classifiers/categories`);
    } catch (error) {
      console.error('ошибка при сохранении статьи', error);
    }
  };

  const handleOnLoad = async (file, type) => {
    const baseFile = await getBase64Image(file[0]);
    if (type === 'cover_desktop') {
      setState((prevState) => {
        return {
          ...prevState,
          cover_desktop: {
            file: file[0],
            src: baseFile,
          },
        };
      });
    }
    if (type === 'cover_mobile') {
      setState((prevState) => {
        return {
          ...prevState,
          cover_mobile: {
            file: file[0],
            src: baseFile,
          },
        };
      });
    }
  };

  const removeFile = (type) => {
    if (type === 'cover_desktop') {
      setState((prevState) => {
        return {
          ...prevState,
          cover_desktop: {
            file: undefined,
            src: null,
          },
        };
      });
    }
    if (type === 'cover_mobile') {
      setState((prevState) => {
        return {
          ...prevState,
          cover_mobile: {
            file: undefined,
            src: null,
          },
        };
      });
    }
  };

  return (
    <Form
      initialValues={values}
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, valid, values }) => (
        <form
          onSubmit={handleSubmit}
          className={classes.root}
          noValidate
          autoComplete="off"
        >
          <Paper
            className={classes.paperHead}
            style={{ padding: 15, marginBottom: 15 }}
            elevation={0}
          >
            <div className={classes.buttonGroupWrapper}>
              {rubricsSiteLinks.map(({ label, href }) => (
                <Button
                  key={label}
                  type="submit"
                  className={classes.submitBtn}
                  variant="outlined"
                  color="primary"
                  href={`${href}${values.slug}`}
                  target="_blank"
                  size="small"
                >
                  {label}
                </Button>
              ))}
            </div>
          </Paper>
          <Paper style={{ padding: 15 }} elevation={0}>
            <Switches
              label="Рубрика активна"
              name="is_active"
              data={{ label: null }}
            />
            <br />
            <Switches
              label="Показывать новости рубрики на главной"
              name="is_main"
              data={{ label: null }}
            />
            <TextField
              required
              label="Название рубрики"
              variant="outlined"
              name="title"
              margin="none"
              size="small"
            />
            <TextField
              label="Описание рубрики"
              variant="outlined"
              name="description"
              margin="none"
              size="small"
              InputProps={{
                inputComponent: TextareaAutosize,
              }}
            />
            <div className={classes.wrapperCover}>
              <div>
                <div className={classes.headerDropzone}>
                  <span className="MuiFormLabel-root">Картинка (десктоп)</span>
                  <Tooltip title="Обложка рубрики доступна только для Красного севера">
                    <IconButton color="primary" aria-label="help">
                      <HelpIcon />
                    </IconButton>
                  </Tooltip>
                </div>
                <Dropzone
                  accept=".img,.jpg,.jpeg,.png,.gif,.webp"
                  onDrop={(file) => handleOnLoad(file, 'cover_desktop')}
                  // disabled={!state.description}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className={classes.dropZoneContainer}>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {state.cover_desktop?.src ? (
                          <div style={{ position: 'relative' }}>
                            <img
                              className={classes.image}
                              alt="pic"
                              src={state.cover_desktop?.src}
                            />
                            <IconButton
                              className={classes.removeIcon}
                              key="close"
                              aria-label="Close"
                              color="inherit"
                              onClick={(e) => {
                                e.stopPropagation();
                                removeFile('cover_desktop');
                              }}
                            >
                              <Close className={classes.close} />
                            </IconButton>
                          </div>
                        ) : (
                          <p className={classes.downloadCover}>
                            Загрузить обложку
                          </p>
                        )}
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
              <div>
                <div className={classes.headerDropzone}>
                  <span className="MuiFormLabel-root">Картинка (мобайл)</span>
                  <Tooltip title="Обложка рубрики доступна только для Красного севера">
                    <IconButton color="primary" aria-label="help">
                      <HelpIcon />
                    </IconButton>
                  </Tooltip>
                </div>
                <Dropzone
                  accept=".img,.jpg,.jpeg,.png,.gif,.webp"
                  onDrop={(file) => handleOnLoad(file, 'cover_mobile')}
                  // disabled={!state.description}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className={classes.dropZoneContainer}>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {state?.cover_mobile?.src ? (
                          <div style={{ position: 'relative' }}>
                            <img
                              className={classes.image}
                              alt="pic"
                              src={state?.cover_mobile?.src}
                            />
                            <IconButton
                              className={classes.removeIcon}
                              key="close"
                              aria-label="Close"
                              color="inherit"
                              onClick={(e) => {
                                e.stopPropagation();
                                removeFile('cover_mobile');
                              }}
                            >
                              <Close className={classes.close} />
                            </IconButton>
                          </div>
                        ) : (
                          <p className={classes.downloadCover}>
                            Загрузить обложку
                          </p>
                        )}
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            </div>

            <Button
              disabled={!valid}
              type="submit"
              className={classes.submitBtn}
              variant="contained"
              color="primary"
            >
              Сохранить
            </Button>
          </Paper>
        </form>
      )}
    />
  );
};

export default CrudCategories;
