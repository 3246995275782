import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { getNewspapersList } from '../../../request';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'hook/useQuery';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import Preloader from 'components/Preloader';
import SortListController from './components/sortListController';
import { saveNewspaper, getNewspaper } from '../../../request';

const BASE_URL = '/admin/newspapers';
const PER_PAGE_MAX = 30;

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginTop: 30,
  },
  pointer: {
    cursor: 'pointer',
  },
});

const Newspapers = () => {
  const classes = useStyles();
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const [list, setList] = useState();
  const initPage =
    query.get('page') && typeof Number(query.get('page')) === 'number'
      ? Number(query.get('page'))
      : 1;
  const initPerPage =
    query.get('per_page') && typeof Number(query.get('per_page')) === 'number'
      ? Number(query.get('per_page'))
      : PER_PAGE_MAX;
  const [page, setPage] = useState(initPage);
  const [rowsPerPage, setRowsPerPage] = useState(initPerPage);

  useEffect(() => {
    if (!location.search) {
      history.push(`${BASE_URL}?page=${page}&per_page=${rowsPerPage}`);
      return;
    }
    async function fetchData() {
      const res = await getNewspapersList(location.search);
      if (res.results) setList(res);
    }
    fetchData();
  }, [location.search]);

  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value, 10);
    history.push(`${BASE_URL}?page=1&per_page=${value}`);
    setRowsPerPage(value);
  };

  const handleChangePage = (event, nextPage) => {
    history.push(`${BASE_URL}?page=${nextPage + 1}&per_page=${rowsPerPage}`);
    setPage(page + 1);
  };

  const handleChangeOrder = async (index, path) => {
    const current = list?.results?.[index];
    if (!current) return;

    const num = path === 'up' ? -1 : 1;
    const nextIndex = index + num;
    const newList = [...list?.results];
    const next = newList[nextIndex];
    newList[index] = next;
    newList[nextIndex] = current;
    setList({ ...list, results: newList });
    const formData = new FormData();
    formData.append('order_index', nextIndex);
    await saveNewspaper(current.slug, formData);
  };

  return (
    <>
      <Paper style={{ padding: 15, marginBottom: 15 }} elevation={0}>
        <Button
          onClick={() => history.push(`${BASE_URL}/new`)}
          variant="contained"
          color="primary"
        >
          Добавить печатное издание
        </Button>
        {list ? (
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Название</TableCell>
                  <TableCell>Слаг</TableCell>
                  <TableCell align="center">Активен</TableCell>
                  <TableCell align="center">Дата создания</TableCell>
                  <TableCell align="center">Кол-во выпусков</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.results.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell
                      className={classes.pointer}
                      onClick={() =>
                        history.push(`${BASE_URL}/edit/${row.slug}`)
                      }
                    >
                      {row.title}
                    </TableCell>
                    <TableCell>{row.slug}</TableCell>
                    <TableCell align="center">
                      {row.is_active ? (
                        <PlayCircleOutlineIcon color="primary" />
                      ) : (
                        <PauseCircleOutlineIcon color="error" />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {dayjs(row.created_at).format('DD-MM-YYYY HH:MM')}
                    </TableCell>
                    <TableCell align="center">
                      {row.issues ? row.issues.length : 0}
                    </TableCell>
                    <TableCell align="center">
                      <SortListController
                        key={row.id}
                        index={index}
                        onClick={handleChangeOrder}
                        maxListcount={list?.results?.length}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              {/* <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10]}
                    labelRowsPerPage="Статьи"
                    labelDisplayedRows={({ from, to, count }) =>
                      `${from}-${to} из ${count !== -1 ? count : to}`
                    }
                    count={list.count}
                    rowsPerPage={rowsPerPage}
                    page={page - 1}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter> */}
            </Table>
          </TableContainer>
        ) : (
          <Preloader />
        )}
      </Paper>
    </>
  );
};

export default Newspapers;
