import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { useParams } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { getTicker, saveTicker } from '../../../request';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTextField-root': {
      margin: '8px 0',
      width: '100%',
    },
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
  },
}));

const Ticker = () => {
  const { id } = useParams();
  const classes = useStyles();
  const [state, setState] = useState();

  useEffect(() => {
    async function fetchData() {
      const res = await getTicker(id);
      setState(res);
    }
    fetchData();
  }, []);

  const handleChange = async () => {
    const result = await saveTicker(state.id, { processed: !state.processed });
    setState(result);
  };

  return (
    <Paper style={{ padding: 15, marginBottom: 15 }} elevation={0}>
      {state && (
        <form className={classes.form}>
          <FormControlLabel
            control={
              <Switch
                checked={state.processed}
                onChange={handleChange}
                name="checkedA"
              />
            }
            label="Обработанно"
          />
          <TextField
            label="ФИО"
            variant="outlined"
            disabled
            defaultValue={state.fio}
          />
          <TextField
            label="Телефон"
            variant="outlined"
            disabled
            defaultValue={state.phone}
          />
          <TextField
            label="Почта"
            variant="outlined"
            disabled
            defaultValue={state.email}
          />
          <TextField
            label="Текст"
            variant="outlined"
            disabled
            defaultValue={state.text}
            multiline
            rows={4}
          />
          <TextField
            label="С"
            variant="outlined"
            disabled
            defaultValue={dayjs(Number(state.start_at)).format('DD.MM.YYYYг')}
          />
          <TextField
            label="По"
            variant="outlined"
            disabled
            defaultValue={dayjs(Number(state.end_at)).format('DD.MM.YYYYг')}
          />
          <TextField
            label="Количество дней"
            variant="outlined"
            disabled
            defaultValue={state.word_count}
          />
          <TextField
            label="Стоимость"
            variant="outlined"
            disabled
            defaultValue={state.cost}
          />
        </form>
      )}
    </Paper>
  );
};

export default Ticker;
