import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ArticleList from './components/ArticleList';
import MainList from './components/MainList';

// eslint-disable-next-line no-unused-vars
let dragNodeId = null;

const useStyles = makeStyles(() => ({
  RedNorthMainGrid: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '1fr 0.5fr',
    gap: '50px',
    '@media (max-width: 1000px)': {
      gridTemplateColumns: '1fr 1fr',
      '@media (max-width: 600px)': {
        gridTemplateColumns: '1fr',
      },
    },
  },
  slotActive: {
    backgroundColor: '#3c4858',
  },
}));

const RedNorthMainGrid = ({ onChange, onDelete, ...props }) => {
  const classes = useStyles();
  const handleDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.target.classList.contains('slot')) {
      e.target.classList.add(classes.slotActive);
    }
  };

  const handleDragLeave = (e) => {
    e.stopPropagation();
    e.target.classList.remove(classes.slotActive);
  };

  const handleDrop = (e) => {
    e.stopPropagation();
    const { nodeid, index } = e?.target?.dataset;
    const cloneSlots = [...props.slots];
    e.target.classList.remove(classes.slotActive);
    const isAlreadyExist = cloneSlots.find(
      (slot) => slot?.id == dragNodeId?.id
    );
    //checks to prevent duplicates and unexpected behavior when dragging slots
    if (dragNodeId?.id == nodeid) return;
    if (!dragNodeId.index && isAlreadyExist) return;
    if (!nodeid && !index) return;
    console.log('DropEvent ', nodeid, index);
    // если в перетаскиваемом элементе есть индекс, значит работаем внутри сетки главной, меняем слоты местами
    if (dragNodeId.index) {
      cloneSlots[dragNodeId.index] = cloneSlots[index];
      cloneSlots[index] = dragNodeId;
    } else {
      cloneSlots[index] = dragNodeId;
    }
    onChange && onChange(cloneSlots.filter(Boolean));
  };

  const handleDragStart = (e) => {
    e.stopPropagation();
    const { nodeid, nodetitle, index, slug, expires } = e?.target?.dataset;
    dragNodeId = {
      id: nodeid,
      title: nodetitle,
      index,
      slug,
      expires,
    };
  };

  const handleDelete = (i) => {
    const cloneSlots = [...props.slots];
    const deleted = cloneSlots.splice(i, 1);
    onDelete?.(deleted);
    onChange && onChange(cloneSlots);
  };

  return (
    <div className={classes.RedNorthMainGrid}>
      <MainList
        {...props}
        handleDrop={handleDrop}
        handleDragLeave={handleDragLeave}
        handleDragOver={handleDragOver}
        handleDragStart={handleDragStart}
        handleDelete={handleDelete}
      />
      <ArticleList {...props} handleDragStart={handleDragStart} />
    </div>
  );
};

export default RedNorthMainGrid;
