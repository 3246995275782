import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(() => ({
  MainList: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '1em',
  },
  slotWrapper: {
    position: 'relative',
    backgroundColor: '#eeeeee',
    borderRadius: '3px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '15px',
  },
  slotItem: {
    borderRadius: '3px',
    position: 'relative',
    cursor: 'grabbing',
    padding: '10px',
  },
  addBlock: {
    borderRadius: '3px',
    position: 'relative',
    padding: '10px',
    backgroundColor: '#eeeeee',
  },
}));

const MainList = ({
  handleDragOver,
  handleDragLeave,
  handleDrop,
  slots,
  slotsCount = 7,
  handleDragStart,
  handleDelete,
  slot,
}) => {
  const classes = useStyles();

  return (
    <div
      className={classes.MainList}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onDragStart={handleDragStart}
    >
      {slots.map((el, index) => (
        <div key={el?.id || index} className={classes.slotWrapper}>
          <div
            draggable="true"
            className={cn(classes.slotItem, 'slot')}
            data-index={index}
            data-nodeid={el?.id}
            data-nodetitle={el?.title}
            data-slug={el?.slug}
            data-expire={el?.promo_expires}
          >
            {el?.title || 'пустой слот'}
          </div>
          {slot && slot}
          <IconButton aria-label="delete" onClick={() => handleDelete(index)}>
            <DeleteIcon />
          </IconButton>
        </div>
      ))}
      {slots.length < slotsCount && (
        <div className={cn(classes.addBlock, 'slot')} data-index={slots.length}>
          {'Добавить новость'}
        </div>
      )}
    </div>
  );
};

export default MainList;
