/* eslint-disable no-multi-assign */
import { baseURL } from 'request/axios';

const severPressConfig = (md, articleID) => {
  md.CorePlugin({
    mode: false,
    default_node: 'paragraph',
    title_map: {
      blockquote: 'Цитата',
      lead: 'Лид-абзац',
      heading_two: 'Подзаголовок H2',
      heading_three: 'Подзаголовок H3',
      image: 'Изображение',
      gallery: 'Галерея',
      ul_list: 'Список',
      ol_list: 'Номерованный список',
      sharing: 'Репост',
      block_link_list: 'Статьи на тему',
      partnership: 'Партнерский блок',
      type_in_progress: 'Статья дополняется',
      html_text: 'HTML',
      horizontal_line: 'Разделитель',
      read_also: 'Читать также',
      important_thought: 'Важная мысль',
    },
    hot_keys: {
      soft_space: 'ctrl+space',
      soft_break: 'shift+enter',
    },
    exclude_typograf_types: ['sharing'],
    aligment_types: ['blockquote', 'block_link_list', 'image'],
    should_aligment_types: ['blockquote', 'image'],
    exclude_tool_types: [
      'paragraph',
      'heading_two',
      'heading_three',
      'lead',
      'ul_list',
      'ol_list',
    ],
    exclude_drag_types: ['lead'],
  })
    .MarkPlugin({
      icon: 'FaBold',
      node_type: 'bold',
      element: 'strong',
      title: 'Жирный',
    })
    .MarkPlugin({
      icon: 'FaItalic',
      node_type: 'italic',
      element: 'em',
      title: 'Курсив',
    })
    .MarkPlugin({
      icon: 'FaUnderline',
      node_type: 'underline',
      element: 'u',
      title: 'Подчеркнутый',
    })
    .MarkPlugin({
      icon: 'FaStrikethrough',
      node_type: 'strikethrough',
      element: 'strike',
      title: 'Зачеркнутый',
    })

    .AlignPlugin({ align_type: 'left', icon: 'FaAlignLeft', title: 'Слева' })
    .AlignPlugin({ align_type: 'right', icon: 'FaAlignRight', title: 'Cправа' })
    .AlignPlugin({
      align_type: 'center',
      icon: 'FaAlignCenter',
      title: 'По центру',
    })
    .AlignPlugin({
      align_type: 'justify',
      icon: 'FaAlignJustify',
      title: 'По ширине',
    })

    .InlinePlugin()
    .FormFieldValuePlugin()
    .HeaderPlugin({
      node_type: 'heading_two',
      size: 2,
      title: 'Подзаголовок H2',
      placeholder: 'Введите подзаголовок H2',
    })
    .HeaderPlugin({
      node_type: 'heading_three',
      size: 3,
      title: 'Подзаголовок H3',
      placeholder: 'Введите подзаголовок H3',
      icon: 'FaHSquare',
    })
    .BlockquotePlugin({
      with_avatar: false,
      with_extra: true,
      with_extra_additional: true,
      url: `${baseURL}/article_images/?article=${articleID}`,
      extra_node_placeholder: 'Укажите источник',
      isCloud: true,
    })
    .ImagePlugin({
      url: `${baseURL}/article_images/?article=${articleID}`,
      isCloud: true,
      removeOnUnMount: true,
      CROP_CONFIG: {
        x: 0,
        y: 0,
        width: 100,
        aspect: 16 / 9,
      },
    })
    .GalleryPlugin({
      url: `${baseURL}/article_images/?article=${articleID}`,
      isCloud: true,
    })
    .UlListPlugin()
    .OlListPlugin()
    .SharingPlugin()
    .CustomBlockPlugin({
      is_void: true,
      node_type: 'horizontal_line',
      style: {
        borderBottom: '4px solid gray',
        marginBottom: '4px',
      },
    })
    .CustomBlockPlugin({
      is_void: false,
      node_type: 'html_text',
      title: 'HTML',
      icon: 'FaHtml5',
      style: {
        border: '1px solid gray',
        padding: '10px',
      },
    })
    .EmojiPlugin();
};

export default severPressConfig;
