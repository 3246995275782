import React, { useState } from 'react';
import ChoiceArticles from 'feature/ChoiceArticles';
import { savePromo } from '../../request';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import dayjs from 'dayjs';
const time = [
  {
    name: 'Установленное время',
    value: null,
  },
  {
    name: 'Час',
    value: 1,
  },
  {
    name: '3 часа',
    value: 3,
  },
  {
    name: '24 час',
    value: 24,
  },
  {
    name: '48 часов',
    value: 48,
  },
];

const SelectTimeExpires = ({ currentValue, onChange, articles }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
      {' '}
      <Select
        style={{ width: 90 }}
        label="Время жизни промо"
        formControlProps={{
          margin: 'dense',
          variant: 'outlined',
        }}
        value={currentValue}
        onChange={onChange}
      >
        {time.map(({ name, value }) => (
          <MenuItem key={name} value={value}>
            {name}
          </MenuItem>
        ))}
      </Select>
      {currentValue && (
        <div>
          Промо истекает:{' '}
          {dayjs(getTime(currentValue)).format('DD.MM.YY HH:mm')}
        </div>
      )}
      {articles[0]?.promo_expires && !currentValue && (
        <div>
          Промо истекает:{' '}
          {dayjs(articles[0]?.promo_expires).format('DD.MM.YY HH:mm')}
        </div>
      )}
    </div>
  );
};

const getTime = (val) => {
  const createTimeByhours = (hours) => {
    const currentDate = new Date();
    return new Date(currentDate.getTime() + hours * 60 * 60 * 1000);
  };
  switch (val) {
    case 1:
      return createTimeByhours(val);
    case 3:
      return createTimeByhours(val);
    case 24:
      return createTimeByhours(val);
    case 48:
      return createTimeByhours(val);
    default:
      return new Date();
  }
};

const PromoinInfinity = ({ slotsCount = 1, targetId = 1 }) => {
  const [expires, setExpires] = useState();
  const [articles, setArticles] = useState([]);
  const saveHandler = async (ids) => {
    if (!ids.length || !expires) {
      return;
    }
    const [{ id }] = ids;

    await savePromo([
      {
        id,
        promo_position: 1,
        promo_expires: getTime(expires),
      },
    ],
    targetId,
    );
  };

  const changeExpireHandler = (val) => {
    setExpires(val.target.value);
  };

  const deleteHandler = () => {
    savePromo([], targetId);
  };

  return (
    <ChoiceArticles
      slotsCount={slotsCount}
      target_id={targetId}
      type="infinity/promo_articles"
      showDialogMsg="Сохранить статью для пром блока?"
      articlePath="articles"
      slotsPath="articles/infinity/promo_articles"
      queryParams={`is_published=true&is_available_by_link=False&type=news,narrative`}
      onSave={saveHandler}
      onDelete={deleteHandler}
      onChange={setArticles}
      slot={
        <SelectTimeExpires
          currentValue={expires}
          onChange={changeExpireHandler}
          articles={articles}
        />
      }
    />
  );
};

export default PromoinInfinity;
