import React from 'react';
import { FieldPrefix } from './CustomField';
import { valuesScheme } from '../constants';
import cn from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { renderGridByScheme } from '../helpers/renderGridByScheme';

const useStyles = makeStyles(() => ({
  gridColumn: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: `10px 20px`,
  },
  section: {},
  interesting: {
    alignSelf: 'flex-end',
  },
}));

export const RenderGrid = () => {
  const classes = useStyles();

  return (
    <>
      {valuesScheme.map((schemeItem, schemeId) => {
        const { slug, title, items } = schemeItem;
        return (
          <div key={`${slug}-${schemeId}`}>
            <Typography variant="h6" component="p">
              {title}
            </Typography>
            <FieldPrefix prefix={slug}>
              <div className={classes.gridColumn}>
                {items.map((item, idx) => {
                  return (
                    <div
                      key={`${slug}-${item.viewType}-${idx}`}
                      className={cn(classes.section, classes[slug])}
                    >
                      {renderGridByScheme({
                        items: item,
                        parentPath: `[${idx}]`,
                        slug,
                      })}
                    </div>
                  );
                })}
              </div>
            </FieldPrefix>
          </div>
        );
      })}
    </>
  );
};
