import React from 'react';
import ChoiceArticles from 'feature/ChoiceArticles';

const NavCategories = () => (
  <ChoiceArticles
    slotsCount={10}
    target_id={2}
    type="header/categories"
    showDialogMsg="Сохранить категории навигации?"
    articlePath="categories"
    slotsPath="categories/header"
  />
);

export default NavCategories;
