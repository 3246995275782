import React from 'react';
import ChoiceArticles from 'feature/ChoiceArticles';

const RedNorthMainGrid = () => (
  <ChoiceArticles
    slotsCount={7}
    target_id={2}
    type="main_page/articles"
    showDialogMsg="Сохранить статьи для главной?"
    articlePath="articles"
    slotsPath="articles/main_page/articles"
    queryParams={`is_published=true&is_available_by_link=False&type=news,narrative`}
  />
);

export default RedNorthMainGrid;
