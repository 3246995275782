/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from 'components/TabPanel';
import { useParams, useHistory } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import NewArticleDialog from 'feature/NewArticleDialog';
import {
  getArticle,
  getCategoriesList,
  getArticleTargets,
  getUsersAuthorsList,
  getCachedTagsList,
  getFeedsList,
  getRelatedArticles,
} from '../../../request';
import ArticleContent from './ArticleContent';
import ArticleCover from './ArticleCover';
import ArticleVideoCover from './ArticleVideoCover';
import ArticleVersioning from './ArticleVersioning';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

const EditArticle = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [currentSite, setCurrentSite] = useState(0);
  const [article, setArticle] = useState({});
  const [categories, setCategories] = useState();
  const [isLoad, setIsLoad] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [targets, setTargets] = useState([]);
  const [targetSite, setTargetSite] = useState(null);
  const [authors, setAuthors] = useState();
  const [tags, setTags] = useState();
  const [feeds, setFeeds] = useState();
  const [baseUrl, setBaseUrl] = useState(targets[0]?.url);
  const { slug } = useParams();
  const history = useHistory();
  const permissions = useSelector((state) =>
    state?.auth?.sitePermissions.map(({ name }) => name)
  );

  useEffect(() => {
    setBaseUrl(targets.find(({ id }) => id === currentSite)?.url);
  }, [targets, currentSite]);

  useEffect(() => {
    if (isLoad) return;
    if (article.id) {
      setCurrentSite(article.target.id);
    }
    if (article.id && categories && targets && authors && tags && feeds) {
      setIsLoad(true);
    }
  }, [article.id, categories, targets, authors, tags, feeds]);

  const fetchArticles = () => {
    Promise.all([getArticle(slug), getRelatedArticles(slug)]).then(
      ([res, related_articles]) => {
        if (res.id) {
          setArticle({ ...res, related_articles });
        }
      }
    );
  };
  const fetchData = async () => {
    setIsLoad(false);
    Promise.all([getArticle(slug), getRelatedArticles(slug)]).then(
      ([res, related_articles]) => {
        if (res.id) {
          setArticle({ ...res, related_articles });
          Promise.all([
            getCategoriesList('?per_page=100000&is_active=true'),
            getArticleTargets(),
            getUsersAuthorsList(),
            getCachedTagsList('?is_active=true'),
            getFeedsList(),
          ]).then(([categoriesList, articleTargets, authors, tags, feeds]) => {
            setCategories(categoriesList.results);
            setTargets(
              articleTargets
                .filter(({ name }) => permissions?.includes(name))
                ?.sort((a, b) => a.id - b.id)
            );
            setAuthors(authors);
            setTags(tags);
            setFeeds(
              feeds.results.map((el) => ({
                value: el.id,
                label: el.name,
              }))
            );
          });
        } else {
          history.push(`/admin/articles`);
        }
      }
    );
  };

  // загружаем статью
  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeCurrentSite = (event, newValue) => {
    if (article.target === newValue) return;
    const selectedArticle = article.related_articles.find(
      (el) => el.target === newValue
    );
    if (selectedArticle && selectedArticle.slug) {
      document.location.replace(`/admin/articles/edit/${selectedArticle.slug}`);
    } else {
      const newTarget = targets.find(({ id }) => id === newValue);
      setTargetSite(newTarget);
      setIsOpenDialog(true);
    }
  };

  return (
    <>
      <NewArticleDialog
        categories={categories}
        site={targetSite}
        group={article.group}
        open={isOpenDialog}
        onClose={() => {
          setIsOpenDialog(false);
          setTargetSite(null);
        }}
      />
      {targets.length > 1 && (
        <AppBar position="relative">
          <Tabs
            value={currentSite}
            onChange={handleChangeCurrentSite}
            variant="scrollable"
            scrollButtons="on"
            allowscrollbuttonsmobile="true"
            aria-label="scrollable force tabs example"
          >
            {targets.map(({ id, name }) => (
              <Tab label={name} {...a11yProps(id)} value={id} key={id} />
            ))}
          </Tabs>
        </AppBar>
      )}
      <Divider />
      <div className={classes.root}>
        <AppBar position="static" color="inherit">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="on"
            allowscrollbuttonsmobile="true"
            aria-label="scrollable force tabs example 2"
          >
            <Tab label="Статья" {...a11yProps(0)} />
            <Tab label="Обложка статьи" {...a11yProps(1)} />
            <Tab label="Видео" {...a11yProps(2)} />
            <Tab label="История изменений" {...a11yProps(3)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          {isLoad ? (
            <ArticleContent
              article={article}
              categories={categories}
              authors={authors}
              tags={tags}
              baseUrl={baseUrl}
              feeds={feeds}
            />
          ) : (
            <CircularProgress />
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {isLoad && (
            <ArticleCover
              article={article}
              fetchData={fetchData}
              isActive={value === 1}
            />
          )}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {isLoad && (
            <ArticleVideoCover
              article={article}
              fetchArticles={fetchArticles}
            />
          )}
        </TabPanel>
        <TabPanel value={value} index={3}>
          {isLoad && (
            <ArticleVersioning article={article} fetchData={fetchData} />
          )}
        </TabPanel>
      </div>
    </>
  );
};

export default EditArticle;
