import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import { Autocomplete, DatePicker, Select } from 'mui-rff';
import MuiTextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import Button from '@material-ui/core/Button';
import dayjs from 'dayjs';
import {
  getArticleTargets,
  getCategoriesList,
  getCachedTagsList,
  getUsersAuthorsList,
  getArticleReportURL,
} from '../../request';
import { download } from 'assets/js/helper';
import MenuItem from '@material-ui/core/MenuItem';

const validate = (values) => {
  const { pub_date_at_local__lt, pub_date_at_local__gt } = values;
  const errors = {};
  if (!pub_date_at_local__lt) {
    errors.pub_date_at_local__lt = 'Обязательно для заполнения';
  }
  if (!pub_date_at_local__gt) {
    errors.pub_date_at_local__gt = 'Обязательно для заполнения';
  }
  return errors;
};

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTextField-root': {
      margin: '8px 0',
      width: '100%',
    },
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
    },
  },
  submitBtn: {
    marginRight: 5,
    marginTop: 10,
  },
}));

const ArticleReports = () => {
  const classes = useStyles();
  const [targets, setTargets] = useState();
  const [categories, setCategories] = useState();
  const [tags, setTags] = useState();
  const [authors, setAuthors] = useState();
  const values = {
    pub_date_at_local__gt: null,
    pub_date_at_local__lt: null,
    target_id: null,
    category__slug: null,
    tag__slug: null,
    author__id: [],
    type: null,
  };
  useEffect(() => {
    Promise.all([
      getArticleTargets(),
      getCategoriesList('?per_page=100000'),
      getCachedTagsList(),
      getUsersAuthorsList(),
    ]).then(([targetsList, categoriesList, tagsList, authorsList]) => {
      setTargets(targetsList);
      setCategories(categoriesList.results);
      setTags(tagsList);
      setAuthors(authorsList);
    });
  }, []);

  const onSubmit = (data) => {
    try {
      let query = Object.entries(data).reduce((acc, [key, value]) => {
        if (!value) return acc;
        if (key === 'pub_date_at_local__gt') {
          return `${acc}${key}=${dayjs(value).startOf('day').utc().valueOf()}&`;
        }
        if (key === 'pub_date_at_local__lt') {
          return `${acc}${key}=${dayjs(value).endOf('day').utc().valueOf()}&`;
        }
        if (key === 'target_id') return `${acc}${key}=${value.id}&`;
        if (key === 'category__slug') return `${acc}${key}=${value.slug}&`;
        if (key === 'tag__slug') return `${acc}${key}=${value.slug}&`;
        if (key === 'author__id' && value.length > 0)
          return `${acc}${key}=${value.map(({ id }) => id).join(',')}&`;
        if (key === 'type') return `${acc}${key}=${value}&`;
        return acc;
      }, '?');
      const url = getArticleReportURL(query);
      download(`article-report-${dayjs().format('DD-MM-YYYY-HH:MM')}.xls`, url);
    } catch (error) {
      console.error('Ошибка при формировании отчета', error);
    }
  };

  return (
    <Paper style={{ padding: 15, marginBottom: 15 }} elevation={0}>
      <Form
        initialValues={values}
        onSubmit={onSubmit}
        validate={(values) => validate(values)}
        render={({ handleSubmit, valid }) => (
          <form
            onSubmit={handleSubmit}
            className={classes.root}
            noValidate
            autoComplete="off"
          >
            <DatePicker
              required
              name="pub_date_at_local__gt"
              ampm={false}
              label="Дата публикации (с)"
              showTodayButton
              inputVariant="outlined"
              cancelLabel="Отмена"
              todayLabel="Сегодня"
              clearLabel="Очистить"
              dateFunsUtils={DateFnsUtils}
              locale={ruLocale}
              clearable
              labelFunc={(date) =>
                date ? dayjs(date).format('DD.MM.YYYY') : ''
              }
            />
            <DatePicker
              required
              name="pub_date_at_local__lt"
              ampm={false}
              label="Дата публикации (по)"
              showTodayButton
              inputVariant="outlined"
              cancelLabel="Отмена"
              todayLabel="Сегодня"
              clearLabel="Очистить"
              dateFunsUtils={DateFnsUtils}
              locale={ruLocale}
              clearable
              labelFunc={(date) =>
                date ? dayjs(date).format('DD.MM.YYYY') : ''
              }
            />
            {targets && (
              <Autocomplete
                name="target_id"
                options={targets}
                getOptionLabel={({ name }) => name || ''}
                filterSelectedOptions
                renderInput={(params) => (
                  <MuiTextField {...params} variant="outlined" label="Сайт" />
                )}
              />
            )}
            {categories && (
              <Autocomplete
                name="category__slug"
                options={categories?.filter(({ is_active }) => is_active)}
                getOptionLabel={({ title }) => title || ''}
                filterSelectedOptions
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    variant="outlined"
                    label="Рубрика"
                  />
                )}
              />
            )}
            {tags && (
              <Autocomplete
                name="tag__slug"
                options={tags?.filter(({ is_active }) => is_active)}
                getOptionLabel={({ title }) => title || ''}
                filterSelectedOptions
                renderInput={(params) => (
                  <MuiTextField {...params} variant="outlined" label="Тег" />
                )}
              />
            )}
            {authors && (
              <Autocomplete
                name="author__id"
                options={authors}
                getOptionLabel={({ first_name, last_name }) =>
                  `${first_name || ''} ${last_name || ''}`
                }
                multiple
                filterSelectedOptions
                renderInput={(params) => (
                  <MuiTextField {...params} variant="outlined" label="Автор" />
                )}
              />
            )}
            <Select
              name="type"
              label="Выберите тип статьи"
              formControlProps={{
                variant: 'outlined',
              }}
            >
              <MenuItem value={null}>Все</MenuItem>
              <MenuItem value={'news'}>Новость</MenuItem>
              <MenuItem value={'narrative'}>Лонгрид</MenuItem>
            </Select>
            <Button
              disabled={!valid}
              type="submit"
              className={classes.submitBtn}
              variant="contained"
              color="primary"
            >
              Скачать
            </Button>
          </form>
        )}
      />
    </Paper>
  );
};

export default ArticleReports;
