import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'hook/useQuery';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import { getCustomNavs } from '../../request';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginTop: 30,
  },
  tableRow: {
    cursor: 'pointer',
  },
  tools: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: 15,
    flexWrap: 'wrap',
    gap: '20px',
  },
  formControl: {
    minWidth: 150,
  },
});

const typeNavs = [
  {
    name: 'Первый ряд',
    value: '&is_extra=true',
  },
  {
    name: 'Второй ряд',
    value: '&is_extra=false',
  },
  {
    name: 'Все',
    value: ' ',
  },
];

const Extranav = ({
  showFilters = false,
  baseUrl = '/admin/classifiers/extranav',
  target = 1,
}) => {
  baseUrl = `${baseUrl}`;
  const classes = useStyles();
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const [list, setList] = useState();
  const [type, setType] = useState(' ');
  const initPage =
    query.get('page') && typeof Number(query.get('page')) === 'number'
      ? Number(query.get('page'))
      : 1;
  const initPerPage =
    query.get('per_page') && typeof Number(query.get('per_page')) === 'number'
      ? Number(query.get('per_page'))
      : 10;
  const [page, setPage] = React.useState(initPage);
  const [rowsPerPage, setRowsPerPage] = React.useState(initPerPage);

  useEffect(() => {
    if (!location.search) {
      history.push(
        `${baseUrl}?target_id=${target}&page=${page}&per_page=${rowsPerPage}`
      );
      return;
    }
    async function fetchData() {
      const res = await getCustomNavs(location.search);
      setList(res);
    }
    fetchData();
  }, [location.search]);

  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value, 10);
    history.push(`${baseUrl}?target_id=${target}&page=1&per_page=${value}`);
    setRowsPerPage(value);
  };

  const handleChangePage = (event, nextPage) => {
    history.push(
      `${baseUrl}?target_id=${target}&page=${
        nextPage + 1
      }&per_page=${rowsPerPage}`
    );
    setPage(nextPage + 1);
  };

  const handleChangeTarget = ({ tar }) => {
    history.push(
      `${baseUrl}?target_id=${target}&page=1&per_page=${rowsPerPage}${tar.value}`
    );
    setPage(1);
    setType(tar.value);
  };

  return (
    <>
      <Paper style={{ padding: 15, marginBottom: 15 }} elevation={0}>
        <div className={classes.tools}>
          <Button
            onClick={() => history.push(`${baseUrl}/new`)}
            variant="contained"
            color="primary"
          >
            Добавить ссылку
          </Button>
          {showFilters && (
            <FormControl className={classes.formControl}>
              <Select
                labelId="select-site-label"
                value={type}
                onChange={handleChangeTarget}
              >
                {typeNavs.map(({ value, name }) => (
                  <MenuItem key={value} value={value}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
        <Divider />
        {list && (
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="center">Название</TableCell>
                  <TableCell align="center">Теги / Ссылки</TableCell>
                  <TableCell align="center">Расположение</TableCell>
                  <TableCell align="center">Активен</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list?.results?.map((row) => (
                  <TableRow
                    className={classes.tableRow}
                    key={row.id}
                    onClick={() => history.push(`${baseUrl}/edit/${row.slug}`)}
                  >
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell align="center">{row.title}</TableCell>
                    <TableCell align="center">{row.url}</TableCell>
                    <TableCell align="center">
                      {row.is_extra ? 'Первый ряд' : 'Второй ряд'}
                    </TableCell>
                    <TableCell align="center">
                      {row.is_active ? (
                        <PlayCircleOutlineIcon color="primary" />
                      ) : (
                        <PauseCircleOutlineIcon color="error" />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10]}
                    labelRowsPerPage="Статьи"
                    labelDisplayedRows={({ from, to, count }) =>
                      `${from}-${to} из ${count !== -1 ? count : to}`
                    }
                    count={list.count}
                    rowsPerPage={rowsPerPage}
                    page={page - 1}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
      </Paper>
    </>
  );
};

export default Extranav;
