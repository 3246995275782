import React from 'react';
import { TextField } from 'mui-rff';

const DefaultForm = () => (
  <TextField
    required
    label="Ссылка"
    variant="outlined"
    name="url"
    margin="none"
    size="small"
  />
);

export default DefaultForm;
