import { makeStyles } from '@material-ui/core/styles';
import BankImageList from 'components/BankImageList';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Paper,
  Button,
  TextField,
  TextareaAutosize,
  Grid,
  CircularProgress,
  RootRef,
  IconButton,
} from '@material-ui/core/index';
import { Delete, Update } from '@material-ui/icons';

import { Autocomplete, DateTimePicker } from 'mui-rff';
import { Form } from 'react-final-form';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import {
  getPhotoBank,
  updatePhotoInBank,
  savePhotoBank,
  getUsersAuthorsList,
  savePhotoToBank,
  deletePhotoFromBank,
  deletePhotoBank,
} from '../../../../request';

const EditPhotoBank = ({ showDialog }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        margin: '8px 0',
        width: '100%',
      },
    },
    modularEditor: {
      margin: '15px 0',
    },
    submitBtn: {
      marginRight: 5,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    calendarWrapper: {
      display: 'flex',
    },
    tagWrapper: {
      display: 'flex',
      '&>div': {
        flex: 1,
      },
    },
    tagLink: {
      color: 'inherit',
      '&:hover,:focus': {
        color: 'inherit',
      },
    },
    paperHead: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 15,
      marginBottom: 15,
    },
    buttonGroupWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    todayBtn: {
      width: 40,
    },
    rssFeed: {
      marginTop: 10,
    },
    slotActive: {
      backgroundColor: '#827c7c',
    },
  }));
  const classes = useStyles();
  let dragNodeId = null;
  const { slug } = useParams();
  const history = useHistory();
  const calendarRef = useRef();
  const [isLoad, setIsLoad] = useState({
    main: true,
    photo: false,
  });
  const [currentBank, setCurrentBank] = useState(null);
  const [currentPhotos, setCurrentPhotos] = useState(null);
  const [authors, setAuthors] = useState([]);

  const initPhotoBank = async () => {
    const results = await getPhotoBank(slug);
    const users = await getUsersAuthorsList(`?target=${results.target.id}`);
    setAuthors(users);
    setCurrentBank(results);
    setCurrentPhotos(results.photos);
  };

  useEffect(() => {
    setIsLoad((prev) => ({ ...prev, main: true }));
    async function parseData() {
      await initPhotoBank();
      setIsLoad((prev) => ({ ...prev, main: false }));
    }
    parseData();
  }, []);

  const handleDrop = async (e) => {
    e.stopPropagation();
    const { index } = e?.target?.dataset;
    e.target.classList.remove(classes.slotActive);
    if (!index || dragNodeId.index === index) return;
    await updatePhotoInBank(dragNodeId.id, { order_index: index });
    await initPhotoBank();
  };

  const handleDragLeave = (e) => {
    e.stopPropagation();
    e.target.classList.remove(classes.slotActive);
  };
  const handleDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.target.classList.contains('slot')) {
      e.target.classList.add(classes.slotActive);
    }
  };
  const handleDragStart = (e) => {
    e.stopPropagation();
    const { nodeid, index } = e?.target?.dataset;
    dragNodeId = {
      id: nodeid,
      index,
    };
  };
  const handleDelete = (id) => {
    showDialog('Удалить фото?', async () => {
      await deletePhotoFromBank(id);
      await initPhotoBank();
    });
  };
  const changeBankData = ({ target }) =>
    setCurrentBank((bank) => ({ ...bank, [target.name]: target.value }));

  const updatePhotoBank = async (data) => {
    const result = await savePhotoBank(slug, {
      slug: data.slug,
      title: data.title,
      description: data.description,
      authors: data.authors.map(({ id }) => id),
      is_active: data.is_active,
      pub_date_at: data.pub_date_at,
    });

    if (result.slug && result.slug !== slug) {
      history.push(`/admin/photobanks/edit/${result.slug}`);
      return;
    }
    await initPhotoBank();
  };

  const onSubmit = async (submittedData) => {
    await updatePhotoBank(submittedData);
  };

  const validate = (values) => {
    const { title, slug } = values;
    const errors = {};
    if (!title) {
      errors.title = 'Обязательно для заполнения';
    }
    if (!slug) {
      errors.slug = 'Обязательно для заполнения';
    }
    return errors;
  };

  function ControllButtons({ valid }) {
    return (
      <>
        <div className={classes.buttonGroupWrapper}>
          <Button
            disabled={!valid}
            type="submit"
            className={classes.submitBtn}
            variant="contained"
            color="primary"
          >
            Сохранить
          </Button>
          <Button
            onClick={() =>
              onSubmit({ ...currentBank, is_active: !currentBank.is_active })
            }
            disabled={!valid}
            className={classes.submitBtn}
            variant="outlined"
            color={currentBank.is_active ? 'secondary' : 'primary'}
            size="small"
          >
            {currentBank.is_active ? 'Снять с публикации' : 'Опубликовать'}
          </Button>
          <Button
            className={classes.submitBtn}
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => window.open(`https://ks-yanao.ru/photo/${slug}`)}
          >
            <b>Перейти на фотобанк</b>
          </Button>
          <Button
            onClick={() => delPhotoBank()}
            className={classes.submitBtn}
            variant="contained"
            color="secondary"
            size="small"
            startIcon={<Delete />}
          >
            Удалить
          </Button>
        </div>
      </>
    );
  }
  const delPhotoBank = () => {
    showDialog('Удалить фотобанк?', async () => {
      await deletePhotoBank(slug);
      history.push(`/admin/photobanks`);
    });
  };
  const onUploadPhoto = async ({ target }) => {
    let requests = [];
    setIsLoad({ ...isLoad, photo: true });
    for (let file of target.files) {
      let formData = new FormData();
      formData.append('photobank', currentBank.id);
      formData.append('image', file);
      requests.push(savePhotoToBank(formData));
    }
    await Promise.all([...requests]);
    await initPhotoBank();
    setIsLoad({ ...isLoad, photo: false });
  };

  if (isLoad.main) return <CircularProgress />;

  return (
    <>
      <Form
        initialValues={currentBank}
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, valid, errors, form }) => (
          <form
            onSubmit={handleSubmit}
            className={classes.root}
            noValidate
            autoComplete="off"
          >
            <Paper className={classes.paperHead} elevation={0}>
              <b
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                Редактировать фотобанк
              </b>
              <ControllButtons valid={valid} />
            </Paper>
            <Paper style={{ padding: 15 }}>
              <TextField
                required
                id="outlined-error"
                label="Название"
                variant="outlined"
                name="title"
                margin="none"
                size="small"
                error={Boolean(errors.title)}
                helperText={errors.title}
                defaultValue={currentBank.title}
                onChange={changeBankData}
              />
              <TextField
                required
                id="outlined-error"
                label="Слаг"
                variant="outlined"
                name="slug"
                margin="none"
                size="small"
                error={Boolean(errors.slug)}
                helperText={errors.slug}
                defaultValue={currentBank.slug}
                onChange={changeBankData}
              />

              <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                  <TextareaAutosize
                    id="outlined-error"
                    placeholder="Описание к фотоальбому"
                    variant="outlined"
                    name="description"
                    margin="none"
                    size="small"
                    defaultValue={currentBank.description}
                    onChange={changeBankData}
                    style={{
                      height: '100%',
                      width: '100%',
                      minHeight: '100px',
                    }}
                  />
                </Grid>
                <Grid item sm={4}>
                  <DateTimePicker
                    disabled
                    name="created_at"
                    ampm={false}
                    label="Дата создания"
                    showTodayButton
                    inputVariant="outlined"
                    cancelLabel="Отмена"
                    todayLabel="Сегодня"
                    dateFunsUtils={DateFnsUtils}
                    locale={ruLocale}
                    labelFunc={(date) =>
                      dayjs(date).format('DD.MM.YYYY - HH:mm')
                    }
                  />
                  <div className={classes.calendarWrapper}>
                    <RootRef rootRef={calendarRef}>
                      <DateTimePicker
                        name="pub_date_at"
                        ampm={false}
                        label="Дата публикации"
                        showTodayButton
                        inputVariant="outlined"
                        cancelLabel="Отмена"
                        todayLabel="Сегодня"
                        dateFunsUtils={DateFnsUtils}
                        locale={ruLocale}
                        labelFunc={(date) =>
                          dayjs(date).format('DD.MM.YYYY - HH:mm')
                        }
                      />
                    </RootRef>
                    <IconButton
                      className={classes.todayBtn}
                      color="primary"
                      aria-label="calendar today"
                      component="span"
                      onClick={() => form.change('pub_date_at', new Date())}
                    >
                      <Update />
                    </IconButton>
                  </div>

                  <Grid item xs={12}>
                    <Autocomplete
                      name="authors"
                      multiple
                      options={authors}
                      getOptionLabel={({ first_name, last_name }) =>
                        `${first_name} ${last_name}`
                      }
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          variant="outlined"
                          label="Автор"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {currentPhotos && (
                <Paper style={{ padding: 15, marginTop: 15 }} elevation={3}>
                  <BankImageList
                    slots={currentPhotos}
                    handleDrop={handleDrop}
                    handleDragLeave={handleDragLeave}
                    handleDragOver={handleDragOver}
                    handleDragStart={handleDragStart}
                    handleDelete={handleDelete}
                    handleUpdate={updatePhotoInBank}
                  />
                  {(!isLoad.photo && (
                    <Button
                      className={classes.submitBtn}
                      variant="contained"
                      color="primary"
                      component="label"
                    >
                      Добавить фото
                      <input
                        type="file"
                        hidden
                        multiple
                        onChange={onUploadPhoto}
                      />
                    </Button>
                  )) || <CircularProgress />}
                </Paper>
              )}

              <div className={classes.paperHead}>
                <ControllButtons valid={valid} />
              </div>
            </Paper>
          </form>
        )}
      />
    </>
  );
};

const mapDispatch = (dispatch) => ({
  showDialog: (message, cb) =>
    dispatch.dialog.toggle({
      message,
      onAgree: cb,
    }),
  showSnakebar: dispatch.snakebar.toggle,
});

export default connect(null, mapDispatch)(EditPhotoBank);
