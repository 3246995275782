import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'hook/useQuery';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import CheckIcon from '@material-ui/icons/Check';
import { getTickers } from '../../../request';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginTop: 30,
  },
  tableRow: {
    cursor: 'pointer',
  },
});

const TickerList = () => {
  const classes = useStyles();
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const [list, setList] = useState();
  const initPage =
    query.get('page') && typeof Number(query.get('page')) === 'number'
      ? Number(query.get('page'))
      : 1;
  const initPerPage =
    query.get('per_page') && typeof Number(query.get('per_page')) === 'number'
      ? Number(query.get('per_page'))
      : 30;
  const [page, setPage] = React.useState(initPage);
  const [rowsPerPage, setRowsPerPage] = React.useState(initPerPage);

  useEffect(() => {
    if (!location.search) {
      history.push(
        `/admin/promo/advertisment?page=${page}&per_page=${rowsPerPage}`
      );
      return;
    }
    async function fetchData() {
      const res = await getTickers(location.search);
      setList(res);
    }
    fetchData();
  }, [location.search]);

  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value, 10);
    history.push(`/admin/promo/advertisment?page=1&per_page=${value}`);
    setRowsPerPage(value);
  };

  const handleChangePage = (event, page) => {
    history.push(
      `/admin/promo/advertisment?page=${page + 1}&per_page=${rowsPerPage}`
    );
    setPage(page + 1);
  };

  return (
    <>
      <Paper style={{ padding: 15, marginBottom: 15 }} elevation={0}>
        {list && (
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="center">ФИО</TableCell>
                  <TableCell align="center">Телефон</TableCell>
                  <TableCell align="center">Обработанно</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.results.map((row) => (
                  <TableRow
                    className={classes.tableRow}
                    key={row.id}
                    onClick={() =>
                      history.push(`/admin/promo/advertisment/${row.id}`)
                    }
                  >
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell align="center">{row.fio}</TableCell>
                    <TableCell align="center">{row.phone}</TableCell>
                    <TableCell align="center">
                      {row.processed ? (
                        <CheckIcon color="primary" />
                      ) : (
                        <PauseCircleOutlineIcon color="error" />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10]}
                    labelRowsPerPage="Статьи"
                    labelDisplayedRows={({ from, to, count }) =>
                      `${from}-${to} из ${count !== -1 ? count : to}`
                    }
                    count={list.count}
                    rowsPerPage={rowsPerPage}
                    page={page - 1}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
      </Paper>
    </>
  );
};

export default TickerList;
