import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import { Select, Switches, TextField } from 'mui-rff';
import Button from '@material-ui/core/Button';
import { useHistory, useParams } from 'react-router-dom';
import { createScript, getArticleTargets, getScriptsList, saveScript } from '../../../request';
import { baseSiteURL } from 'request/axios';
import MenuItem from '@material-ui/core/MenuItem';

const BASE_URL = '/admin/classifiers/scripts';
const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTextField-root': {
      margin: '8px 0',
      width: '100%',
    },
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
    },
  },
  submitBtn: {
    marginRight: 5,
  },
}));


const CrudCategories = () => {
  const history = useHistory();
  const {id} = useParams();
  const classes = useStyles();
  const [values, setValues] = useState({});
  const [targets, setTargets] = useState();
  useEffect(() => {
    const getTargets = async () => {
      const articleTargets = await getArticleTargets();
      setTargets(articleTargets);
    };
    async function fetchData() {
      const res = await getScriptsList(id);

      if (res.id) setValues(res);
      else history.push(BASE_URL);
    }

    getTargets();
    if (id) fetchData();
  }, []);

  const onSubmit = async (values) => {
    try {
      if (id) {
        await saveScript(id, values);
      } else {
        await createScript(values);
      }
      history.push(BASE_URL);
    } catch (error) {
      console.error('ошибка при сохранении скрипта', error);
    }
  };
  let { target, ...curValues } = values;
  curValues = { ...curValues, target_id: target?.id };

  return (
    <Form
      initialValues={curValues}
      onSubmit={onSubmit}
      render={({handleSubmit}) => (
        <form
          onSubmit={handleSubmit}
          className={classes.root}
          noValidate
          autoComplete="off"
        >
          <Paper
            className={classes.paperHead}
            style={{padding: 15, marginBottom: 15}}
            elevation={0}
          >
            <div className={classes.buttonGroupWrapper}>
              <div>
                <Button
                  type="submit"
                  className={classes.submitBtn}
                  variant="outlined"
                  color="primary"
                  href={`${baseSiteURL}script/${values.slug}`}
                  size="small"
                >
                  Перейти на страницу скрипта
                </Button>
              </div>
            </div>
          </Paper>
          <Paper style={{padding: 15}} elevation={0}>
            <Switches
              label="Скрипт активен"
              name="is_active"
              data={{label: null}}
            />
            <br/>
            <TextField
              required
              label="Название"
              variant="outlined"
              name="code_name"
              margin="none"
              size="small"
            />
            {targets &&
              <Select
                required
                name="target_id"
                label="Выберите сайт"
                formControlProps={{
                  margin: 'dense',
                  variant: 'outlined',
                }}
              >
                {targets?.map(({name, id}) => (
                  <MenuItem key={name} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            }
            <TextField
              required
              label="Скрипт для head"
              variant="outlined"
              name="head_value"
              margin="none"
              size="small"
            />
            <Button
              type="submit"
              className={classes.submitBtn}
              variant="contained"
              color="primary"
            >
              Сохранить
            </Button>
          </Paper>
        </form>
      )}
    />
  );
};

export default CrudCategories;
