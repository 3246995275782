export const positionOptionsRN = [
  {
    id: 1,
    label: 'В шапке',
    value: 'header',
  },
  {
    id: 2,
    label: 'Под статьей',
    value: 'underArticle',
  },
  {
    id: 3,
    label: 'Правый блок 1',
    value: 'rightSide1',
  },
  {
    id: 4,
    label: 'Правый блок 2',
    value: 'rightSide2',
  },
  {
    id: 5,
    label: 'Левый блок',
    value: 'leftSide',
  },
  {
    id: 6,
    label: 'Блок на главной под сеткой',
    value: 'mainPage',
  },
  {
    id: 7,
    label: 'Сайдбар фотоальбомы',
    value: 'photoRightSide',
  },
  {
    id: 8,
    label: 'Сайдбар фотоальбомы (малый)',
    value: 'photoRightSideSmall',
  },
];

export const positionOptionsJM = [
  {
    id: 1,
    label: 'В шапке',
    value: 'header',
  },
  {
    id: 2,
    label: 'Под статьей',
    value: 'underArticle',
  },
  {
    id: 3,
    label: 'В программе',
    value: 'program',
  },
  {
    id: 4,
    label: 'На главной',
    value: 'main',
  },
];

export const positionOptionsNP = [
  {
    id: 1,
    label: 'В шапке',
    value: 'header',
  },
  {
    id: 2,
    label: 'Под статьей',
    value: 'underArticle',
  },
  {
    id: 3,
    label: 'В правом блоке',
    value: 'right',
  },
  {
    id: 4,
    label: 'Обменка под статьей',
    value: 'exchange',
  },
  {
    id: 5,
    label: 'Баннер в ленте новостей',
    value: 'left',
  },
];

export const deviceTypeOptions = [
  {
    id: 1,
    label: 'All',
    value: 'all',
  },
  {
    id: 2,
    label: 'Desktop',
    value: 'desktop',
  },
  {
    id: 3,
    label: 'Mobile',
    value: 'mobile',
  },
];
