import React from 'react';
import Paper from '@material-ui/core/Paper';

const MainPage = () => (
  <Paper style={{ padding: 15, marginBottom: 15 }} elevation={0}>
    <b>Welcome to Yamal dashboard!</b>
  </Paper>
);

export default MainPage;
