import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const useStyles = makeStyles({
  wrapper: {
    position: 'releative',
  },
  button: {
    position: 'releative',
    cursor: 'pointer',
    '&:hover': {
      color: '#3f51b5',
    },
  },
  disabled: {
    pointerEvents: 'none',
  },
});

const SortListController = ({ index, onClick, maxListcount }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div>
        <ArrowUpwardIcon
          className={index ? classes.button : classes.disabled}
          fontSize="small"
          color="disabled"
          onClick={() => onClick(index, 'up')}
        />
      </div>
      <div>
        <ArrowDownwardIcon
          className={
            index !== maxListcount - 1 ? classes.button : classes.disabled
          }
          fontSize="small"
          color="disabled"
          onClick={() => onClick(index, 'down')}
        />
      </div>
    </div>
  );
};

export default SortListController;
