/* eslint-disable no-debugger */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useRef, memo, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Form, Field, FormSpy } from 'react-final-form';
import {
  TextField,
  DateTimePicker,
  Select,
  Autocomplete,
  Checkboxes,
  Switches,
} from 'mui-rff';
import ModularEditor from 'feature/ModularEditor';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import { useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import MenuItem from '@material-ui/core/MenuItem';
import MuiTextField from '@material-ui/core/TextField';
import dayjs from 'dayjs';
import IconButton from '@material-ui/core/IconButton';
import RootRef from '@material-ui/core/RootRef';
import serializer from 'feature/ModularEditorSerializer';
import UpdateIcon from '@material-ui/icons/Update';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ClearIcon from '@material-ui/icons/Clear';
import { saveArticle, deleteArticle } from '../../../request/index';
import { Theses } from './components/theses.component';
import { EditorDraft } from './helper/articleEditorDraft';
import { prepareInitFormValues } from './helper';

const { DASHBOARD_BASE_URL } = process.env;
let debounceId = null;

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: '8px 0',
      width: '100%',
    },
  },
  modularEditor: {
    margin: '15px 0',
  },
  submitBtn: {
    // marginRight: 5,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  calendarWrapper: {
    display: 'flex',
  },
  tagWrapper: {
    display: 'flex',
    '&>div': {
      flex: 1,
    },
  },
  tagLink: {
    color: 'inherit',
    '&:hover,:focus': {
      color: 'inherit',
    },
  },
  paperHead: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 20,
    justifyContent: 'space-between',
    padding: 15,
    marginBottom: 15,
  },
  buttonGroupWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  todayBtn: {
    width: 40,
  },
  rssFeed: {
    marginTop: 10,
  },
  buttonSaveWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 10,
    marginRight: 10,
  },
}));

const types = [
  { name: 'Новость', value: 'news' },
  { name: 'Лонгрид', value: 'narrative' },
];

const validate = (values) => {
  const {
    title,
    authors,
    is_advertising_article,
    advertising_label,
    advertising_code,
    yandex_title,
  } = values;
  const errors = {};
  if (!title) {
    errors.title = 'Обязательно для заполнения';
  }
  if (authors.length === 0) {
    errors.authors = 'Обязательно для заполнения';
  }
  if (is_advertising_article) {
    if (!advertising_label) {
      errors.advertising_label = 'Обязательно для заполнения';
    }
    if (!advertising_code) {
      errors.advertising_code = 'Обязательно для заполнения';
    }
  }
  if (yandex_title?.length > 80) {
    errors.yandex_title = 'Превышен лимит символов (максимально 80)';
  }
  return errors;
};

const revalidation = async (data, site) => {
  if (!data || !site) return;
  try {
    await fetch(site, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {
    console.error('Ошибка:', error);
  }
};

const EditArticle = ({
  showDialog,
  article,
  categories,
  showSnakebar,
  authors,
  tags,
  feeds,
  baseUrl,
}) => {
  const { id, slug, target, category, type } = article;
  const classes = useStyles();
  const [mdInstance, setMdInstance] = useState(null);
  const history = useHistory();
  const calendarRef = useRef();
  const preparedArticle = prepareInitFormValues(article);
  const deleteArticlesAllowed = useSelector((state) =>
    state?.auth?.all_permissions.find(
      (el) => el.slug === 'deleteArticlesAllowed'
    )
  );

  const ED = useMemo(
    () =>
      new EditorDraft(id, article.editor_data, JSON.stringify(preparedArticle)),
    [id, article.editor_data]
  );
  const [initEditorData, initFormData, isDraftInit] = ED.getActualData();
  const [isDraft, setIsDraft] = useState(isDraftInit);
  const [isSubmitted, setSubmitted] = useState(false);
  const revalidateLink = `${baseUrl}/api/revalidate`;

  useEffect(() => {
    if (mdInstance && typeof mdInstance.init === 'function') {
      mdInstance._onChange = () => {
        clearTimeout(debounceId);
        debounceId = setTimeout(() => {
          const { value } = mdInstance.editor;
          const valueJSON = value.toJSON();
          const draftStatus = ED.saveEditorDraft(valueJSON, 'editor');
          setIsDraft(draftStatus);
        }, 500);
      };
      mdInstance.init();
    }
    return () => {
      if (mdInstance) mdInstance.destroy();
    };
  }, [mdInstance]);

  const onSubmit = async (values) => {
    try {
      setSubmitted(true);
      const submitData = {
        ...values,
        category_id: values.category.id,
        group_id: values.group.id,
        target_id: values.target.id,
        authors_ids: values.authors.map(({ id }) => id),
        tags_ids: values.tags.map(({ id }) => id),
        pub_date_at: dayjs(values.pub_date_at).valueOf(),
        feeds_ids: values.feeds,
        yandex_title: values.yandex_title || '',
      };
      delete submitData.group;
      delete submitData.target;
      delete submitData.category;
      delete submitData.authors;
      delete submitData.tags;
      delete submitData.video_cover;
      delete submitData.opening_video;
      delete submitData.feeds;
      delete submitData.image_cover;

      const { value } = mdInstance.editor;
      const valueJSON = value.toJSON();
      const leadNode = valueJSON.document.nodes.find(
        (node) => node.type === 'lead'
      );

      submitData.lead = leadNode
        ? leadNode.nodes.reduce((acc, { text, nodes }) => {
            if (text) {
              return `${acc} ${text}`;
            } else if (nodes && Array.isArray(nodes)) {
              return `${acc} ${nodes[0].text}`;
            }
            return acc;
          }, '')
        : null;
      if (!submitData.lead) {
        showSnakebar({
          message: 'Введите Лид-абзац!',
          isShow: true,
          type: 'danger',
        });
        return;
      }

      const simbolCount = value.document.text.trim().length;
      const wordsCount = value.document.nodes.reduce(
        (acc, node) => [
          ...acc,
          ...node.text
            .trim()
            .split(' ')
            .filter((i) => i.length > 0),
        ],
        []
      ).length;
      submitData.character_count = simbolCount;
      submitData.word_count = wordsCount;

      // Если нет описания, прокидывам туда lead
      submitData.seo_description = values.seo_description
        ? values.seo_description
        : '';
      submitData.editor_data = JSON.stringify(valueJSON);
      submitData.text = serializer().serialize(value);
      const data = await saveArticle(article.slug, submitData);
      // revalidation page on request
      if (data?.slug) {
        ED.removeEditorDraft();
        await revalidation(
          {
            page: '/' + data.url,
          },
          revalidateLink
        );

        if (document)
          document.location.replace(data.slug) && document.location.reload();
      }
    } catch (error) {
      console.error('ошибка при сохранении статьи', error);
    }
  };

  const delArticle = () => {
    showDialog('Удалить статью?', async () => {
      await deleteArticle(slug);
      history.push(`/admin/articles`);
    });
  };

  const clearDraft = () => {
    showDialog('Сбросить черновик?', async () => {
      ED.removeEditorDraft();
      document?.location?.reload();
    });
  };

  const publicArticle = (status, values) => {
    showDialog(
      status ? 'Опубликовать статью?' : 'Снять с публикации?',
      async () => {
        onSubmit({ ...values, is_active: status });
      }
    );
  };

  const articleExternalLinks = {
    published:
      target.id === 1
        ? `${baseUrl}/${article.url}`
        : type === 'photoalbum'
        ? `${baseUrl}/photo/${article.slug}`
        : `${baseUrl}/${type}/${category.slug}/${article.slug}`,
    preview: `${baseUrl}/preview/${article.slug}`,
  };

  const onInitHandler = (value) => setMdInstance(value);

  const handleFormChange = ({ dirty, values }) => {
    if (!dirty || isSubmitted) return;
    const draftStatus = ED.saveEditorDraft(values, 'form');
    setIsDraft(draftStatus);
  };

  return (
    <>
      <Form
        initialValues={initFormData}
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, valid, errors, values, form }) => (
          <form
            onSubmit={handleSubmit}
            className={classes.root}
            noValidate
            autoComplete="off"
          >
            <FormSpy onChange={handleFormChange} />
            <Paper className={classes.paperHead} elevation={0}>
              <b
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                Редактирование статьи
              </b>
              <div className={classes.buttonGroupWrapper}>
                <div className={classes.buttonSaveWrapper}>
                  <Button
                    disabled={!valid || !mdInstance}
                    type="submit"
                    className={classes.submitBtn}
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    Сохранить
                  </Button>
                  {article.is_published ? (
                    <Button
                      onClick={() => publicArticle(false, values)}
                      disabled={!valid}
                      className={classes.submitBtn}
                      variant="outlined"
                      color="secondary"
                      size="small"
                    >
                      Снять с публикации
                    </Button>
                  ) : (
                    <Button
                      onClick={() => publicArticle(true, values)}
                      disabled={!valid}
                      className={classes.submitBtn}
                      variant="outlined"
                      color="primary"
                      size="small"
                    >
                      Опубликовать
                    </Button>
                  )}
                  {article.is_published ? (
                    <Button
                      className={classes.submitBtn}
                      variant="outlined"
                      color="primary"
                      href={articleExternalLinks.published}
                      target="_blank"
                      rel="noreferrer"
                      size="small"
                    >
                      <b>Перейти на статью</b>
                    </Button>
                  ) : (
                    <Button
                      className={classes.submitBtn}
                      variant="outlined"
                      color="primary"
                      href={articleExternalLinks.preview}
                      target="_blank"
                      rel="noreferrer"
                      size="small"
                    >
                      <b>Перейти на превью</b>
                    </Button>
                  )}
                  {deleteArticlesAllowed && (
                    <Button
                      onClick={delArticle}
                      className={classes.submitBtn}
                      variant="contained"
                      color="secondary"
                      size="small"
                      startIcon={<DeleteIcon />}
                    >
                      Удалить
                    </Button>
                  )}
                  {isDraft && (
                    <Button
                      onClick={clearDraft}
                      disabled={!mdInstance}
                      className={classes.submitBtn}
                      variant="contained"
                      size="small"
                    >
                      Сбросить черновик
                    </Button>
                  )}
                </div>
              </div>
            </Paper>
            <Paper style={{ padding: 15 }}>
              <TextField
                required
                label="Название"
                variant="outlined"
                name="title"
                margin="none"
                size="small"
                helperText={`Кол-во символов: ${values?.title?.length || 0}`}
              />
              <TextField
                label="Заголовок для Дзен Новостей"
                variant="outlined"
                name="yandex_title"
                margin="none"
                size="small"
                helperText={`Кол-во символов: ${
                  values?.yandex_title?.length || 0
                }`}
              />
              {deleteArticlesAllowed && (
                <TextField
                  required
                  label="Слаг"
                  variant="outlined"
                  name="slug"
                  margin="none"
                  size="small"
                  helperText="Очистите поле для его обновления в соответствии с заголовком"
                  InputProps={{
                    endAdornment: values?.slug && (
                      <IconButton
                        color="secondary"
                        aria-label={'clear slug'}
                        onClick={() => form.change('slug', '')}
                      >
                        <ClearIcon />
                      </IconButton>
                    ),
                  }}
                />
              )}
              {categories && (
                <Autocomplete
                  name="category"
                  options={categories?.filter(({ is_active }) => is_active)}
                  getOptionLabel={({ title }) => title}
                  filterSelectedOptions
                  disableClearable
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      variant="outlined"
                      label="Выберите рубрику"
                      margin="dense"
                    />
                  )}
                />
              )}
              <Select
                name="type"
                label="Выберите Тип"
                formControlProps={{
                  margin: 'dense',
                  variant: 'outlined',
                }}
              >
                {types.map(({ name, value }) => (
                  <MenuItem key={name} value={value}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              {target.id === 3 && (
                <Field name="theses">
                  {(props) => (
                    <Theses
                      value={props.input.value}
                      onChange={props.input.onChange}
                    />
                  )}
                </Field>
              )}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={7}>
                  <ModularEditor
                    className={classes.modularEditor}
                    initValue={initEditorData}
                    configType={article.target}
                    onInit={onInitHandler}
                    articleID={id}
                  />
                </Grid>
                <Grid item xs={12} sm={5} style={{ marginTop: '35px' }}>
                  <div className={classes.calendarWrapper}>
                    <RootRef rootRef={calendarRef}>
                      <DateTimePicker
                        name="pub_date_at"
                        ampm={false}
                        label="Дата публикации"
                        showTodayButton
                        inputVariant="outlined"
                        cancelLabel="Отмена"
                        todayLabel="Сегодня"
                        dateFunsUtils={DateFnsUtils}
                        locale={ruLocale}
                        labelFunc={(date) =>
                          dayjs(date).format('DD.MM.YYYY - HH:mm')
                        }
                      />
                    </RootRef>
                    <IconButton
                      className={classes.todayBtn}
                      color="primary"
                      aria-label="calendar today"
                      component="span"
                      onClick={() => form.change('pub_date_at', new Date())}
                    >
                      <UpdateIcon />
                    </IconButton>
                  </div>
                  <div className={classes.tagWrapper}>
                    <Autocomplete
                      name="tags"
                      multiple
                      options={tags}
                      getOptionLabel={({ title }) => title}
                      filterSelectedOptions
                      renderInput={(params) => (
                        <MuiTextField
                          {...params}
                          variant="outlined"
                          label="Теги"
                        />
                      )}
                    />
                    <IconButton
                      className={classes.todayBtn}
                      color="primary"
                      aria-label="add tag"
                      component="span"
                    >
                      <a
                        className={classes.tagLink}
                        href={`${DASHBOARD_BASE_URL}/admin/classifiers/tags/new`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <AddCircleIcon />
                      </a>
                    </IconButton>
                  </div>
                  <Autocomplete
                    name="authors"
                    required
                    multiple
                    options={authors}
                    getOptionLabel={({ first_name, last_name }) =>
                      `${first_name} ${last_name}`
                    }
                    filterSelectedOptions
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        error={Boolean(errors.authors)}
                        helperText={errors.authors}
                        required
                        variant="outlined"
                        label="Автор"
                      />
                    )}
                  />
                  <TextField
                    label="CEO заголовок"
                    variant="outlined"
                    name="seo_title"
                    margin="none"
                    size="small"
                  />
                  <TextField
                    label="CEO описание"
                    variant="outlined"
                    name="seo_description"
                    margin="none"
                    size="small"
                    multiline
                    minRows={2}
                  />
                  <Switches
                    label="Новость эксклюзив"
                    name="is_exclusive"
                    data={{ label: null }}
                  />
                  <hr />
                  <Switches
                    label="Новость видео"
                    name="is_video"
                    data={{ label: null }}
                  />
                  {/* 
                  <hr />
                  <Switches
                    label="Партнёрская новость"
                    name="is_partner"
                    data={{ label: null }}
                  /> */}
                  <hr />
                  <div className={classes.rssFeed}>
                    <Checkboxes
                      label="Отображение в RSS-каналах"
                      name="feeds"
                      data={feeds}
                    />
                  </div>
                  <hr />
                  <Switches
                    label="Доступна только по ссылке"
                    name="is_available_by_link"
                    data={{ label: null }}
                  />
                  <hr />
                  <Switches
                    label="Рекламная статья"
                    name="is_advertising_article"
                    data={{ label: null }}
                  />
                  {values.is_advertising_article && (
                    <>
                      <TextField
                        label="Метка"
                        variant="outlined"
                        name="advertising_label"
                        required
                        margin="none"
                        size="small"
                      />
                      <TextField
                        label="Рекламный код"
                        variant="outlined"
                        name="advertising_code"
                        required
                        margin="none"
                        size="small"
                        multiline
                        minRows={5}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
              <Paper className={classes.paperHead} elevation={0}>
                <div className={classes.buttonGroupWrapper}>
                  <div className={classes.buttonSaveWrapper}>
                    <Button
                      disabled={!valid || !mdInstance}
                      type="submit"
                      className={classes.submitBtn}
                      variant="contained"
                      color="primary"
                    >
                      Сохранить
                    </Button>
                    {article.is_published ? (
                      <Button
                        onClick={() => publicArticle(false, values)}
                        disabled={!valid}
                        className={classes.submitBtn}
                        variant="outlined"
                        color="secondary"
                      >
                        Снять с публикации
                      </Button>
                    ) : (
                      <Button
                        onClick={() => publicArticle(true, values)}
                        disabled={!valid}
                        className={classes.submitBtn}
                        variant="outlined"
                        color="primary"
                      >
                        Опубликовать
                      </Button>
                    )}
                    {article.is_published ? (
                      <Button
                        className={classes.submitBtn}
                        variant="outlined"
                        color="primary"
                        href={articleExternalLinks.published}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <b>Перейти на статью</b>
                      </Button>
                    ) : (
                      <Button
                        className={classes.submitBtn}
                        variant="outlined"
                        color="primary"
                        href={`${baseUrl}/preview/${article.slug}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <b>Перейти на превью</b>
                      </Button>
                    )}
                    {deleteArticlesAllowed && (
                      <Button
                        onClick={() => delArticle()}
                        className={classes.submitBtn}
                        variant="contained"
                        color="secondary"
                        startIcon={<DeleteIcon />}
                      >
                        Удалить
                      </Button>
                    )}
                    {isDraft && (
                      <Button
                        onClick={clearDraft}
                        disabled={!mdInstance}
                        className={classes.submitBtn}
                        variant="contained"
                        size="small"
                      >
                        Сбросить черновик
                      </Button>
                    )}
                  </div>
                </div>
              </Paper>
            </Paper>
          </form>
        )}
      />
    </>
  );
};

const mapDispatch = (dispatch) => ({
  showDialog: (message, cb) =>
    dispatch.dialog.toggle({
      message,
      onAgree: cb,
    }),
  showSnakebar: dispatch.snakebar.showSnakebar,
});

export default connect(null, mapDispatch)(memo(EditArticle));
