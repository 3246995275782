import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'hook/useQuery';
import { getBanners } from '../../../request';
import BannerList from 'components/Promo/BannersList';

const Promo = () => {
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const [list, setList] = useState();
  const initPage =
    query.get('page') && typeof Number(query.get('page')) === 'number'
      ? Number(query.get('page'))
      : 1;
  const initPerPage =
    query.get('per_page') && typeof Number(query.get('per_page')) === 'number'
      ? Number(query.get('per_page'))
      : 10;
  const [page, setPage] = React.useState(initPage);
  const [rowsPerPage, setRowsPerPage] = React.useState(initPerPage);

  async function fetchData() {
    const res = await getBanners(location.search);
    setList(res);
  }
  useEffect(() => {
    if (!location.search) {
      history.push(
        `/admin/red-north/promo?target_id=2&page=${page}&per_page=${rowsPerPage}`
      );
      return;
    }

    fetchData();
  }, [location.search]);

  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value, 10);
    history.push(`/admin/red-north/promo?target_id=2&page=1&per_page=${value}`);
    setRowsPerPage(value);
  };

  const handleChangePage = (event, page) => {
    history.push(
      `/admin/red-north/promo?target_id=2&page=${
        page + 1
      }&per_page=${rowsPerPage}`
    );
    setPage(page + 1);
  };

  return (
    <BannerList
      list={list}
      rowsPerPage={rowsPerPage}
      page={page}
      fetchData={fetchData}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      route="/admin/red-north/promo"
    />
  );
};

export default Promo;
