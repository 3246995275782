import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import dayjs from 'dayjs';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const CardArticleVersion = ({
  number,
  type,
  is_actual,
  created_at,
  article_lead,
  article_text,
  title,
  yandex_title,
  slug,
  tags,
  category,
}) => {
  const classes = useStyles();
  const typePos = type === 'first' ? 'A' : 'B';
  const isActiveTitle = is_actual
    ? '*ЭТО ТЕКУЩАЯ ВЕРСИЯ'
    : '*ЭТО НЕАКТИВНАЯ ВЕРСИЯ';

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {`${typePos}: ВЫБРАННАЯ ВЕРСИЯ СТАТЬИ - ${number}`}
        </Typography>
        <Typography variant="h6" component="p">
          {isActiveTitle}
        </Typography>
        <Divider />
        <Typography className={classes.pos} color="textSecondary">
          {`Дата создания: ${dayjs(created_at).format('DD-MM-YYYY HH:MM')}`}
        </Typography>
        <Typography variant="body2" component="p">
          Slug:
          <br />
          {slug || 'Не заполнен'}
        </Typography>
        <br />
        <Typography variant="body2" component="p">
          Заголовок:
          <br />
          {title || 'Не заполнен'}
        </Typography>
        <br />
        <Typography variant="body2" component="p">
          Заголовок для Яндекс новостей:
          <br />
          {yandex_title || 'Не заполнен'}
        </Typography>
        <br />
        <Typography variant="body2" component="p">
          Лид-абзац:
          <br />
          {article_lead || 'Не заполнен'}
        </Typography>
        <br />
        <Typography variant="body2" component="p">
          Текст статьи:
          <br />
          {article_text ? (
            <div dangerouslySetInnerHTML={{ __html: article_text }} />
          ) : (
            'Не заполнен'
          )}
          <Typography variant="body2" component="p">
            Теги:
            <br />
            {tags.length
              ? tags.reduce(
                  (acc, { title }, index) =>
                    `${acc} ${title}${index !== tags.length - 1 ? ',' : ''}`,
                  ''
                )
              : 'Не заполнен'}
          </Typography>
          <br />
          <Typography variant="body2" component="p">
            Категории:
            <br />
            {category.length
              ? category.reduce(
                  (acc, { title }, index) =>
                    `${acc} ${title}${
                      index !== category.length - 1 ? ',' : ''
                    }`,
                  ''
                )
              : 'Не заполнен'}
          </Typography>
          <br />
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CardArticleVersion;
