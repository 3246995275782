import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getCustomNavs, saveCustomNavs } from '../../request';
import MainList from './components/MainList';
import NavList from './components/NavList';

const useStyles = makeStyles(() => ({
  severPressNavs: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '1fr 0.5fr',
    gap: '50px',
    '@media (max-width: 1000px)': {
      gridTemplateColumns: '1fr 1fr',
      '@media (max-width: 600px)': {
        gridTemplateColumns: '1fr',
      },
    },
  },
  slotActive: {
    backgroundColor: '#3c4858',
  },
}));

const NavCategories = (props) => {
  const classes = useStyles();

  const [navs, setNavs] = useState([]);
  const [navsActive, setNavsActive] = useState([]);
  const [current, setCurrent] = useState();
  const [updateMainList, setUpdateMainList] = useState(0);
  const [next, setNext] = useState();

  useEffect(() => {
    getNavsActive();
  }, [updateMainList]);

  useEffect(() => {
    getNavs();
  }, []);

  const getNavsActive = async () => {
    const dataIsActive = await getCustomNavs('?target_id=3&is_active=true');
    setNavsActive(dataIsActive.results);
  };

  const getNavs = async (query = 'target_id=3') => {
    const data = await getCustomNavs(`?${query}`);
    setNext(data.next);
    setNavs((prev) => [...prev, ...data.results]);
  };

  const dragStartHandler = (card) => {
    setCurrent(card);
  };

  const handleDragLeave = (e) => {
    e.target.classList.remove(classes.slotActive);
  };

  const dragOverHandler = (e) => {
    e.preventDefault();
    if (e.target.classList.contains('slot')) {
      e.target.classList.add(classes.slotActive);
    }
  };

  const getMaxOrder = navsActive.reduce((prev, cur) => {
    prev < cur.order_index ? (prev = cur.order_index) : prev;
    return prev;
  }, -1);

  const dropHandler = async (e, card) => {
    e.preventDefault();

    e.target.classList.remove(classes.slotActive);

    const hasId = navsActive.find((item) => item.id === current.id);

    if (card) {
      await saveCustomNavs(current.slug, {
        ...current,
        is_active: true,
        order_index: card.order_index,
      });
      await saveCustomNavs(card.slug, {
        ...card,
        is_active: Boolean(hasId),
        order_index: hasId ? hasId.order_index : card.order_index,
      });
    } else {
      await saveCustomNavs(current.slug, {
        ...current,
        is_active: true,
        order_index: getMaxOrder + 1,
      });
    }

    setUpdateMainList((prev) => prev + 1);
  };

  const deleteIsNav = async (item) => {
    await saveCustomNavs(item.slug, { ...item, is_active: false });
    setUpdateMainList((prev) => prev + 1);
  };

  const handleScrollLoad = async (next) => {
    const parse = next.split('?');
    await getNavs(parse[1]);
  };

  return (
    <div className={classes.severPressNavs}>
      <MainList
        navs={navsActive}
        dragStartHandler={dragStartHandler}
        handleDragLeave={handleDragLeave}
        dropHandler={dropHandler}
        dragOverHandler={dragOverHandler}
        deleteIsNav={deleteIsNav}
        {...props}
      />
      <NavList
        navs={navs}
        next={next}
        dragStartHandler={dragStartHandler}
        dropHandler={dropHandler}
        onScroll={() => handleScrollLoad(next)}
      />
    </div>
  );
};

export default NavCategories;
