/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Dropzone from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import dayjs from 'dayjs';
import { uploadAirBroadcast, broadcastLastUpdateInfo } from '../../../request';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  dropZoneContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginTop: 30,
  },
  tableRow: {
    cursor: 'pointer',
  },
  input: {
    display: 'none',
  },
}));

const Air = () => {
  const classes = useStyles();
  const [state, setState] = useState();
  const asyncfeatch = async () => {
    const response = await broadcastLastUpdateInfo();
    setState(response);
  };
  const prepareFormData = (files) => {
    const formData = new FormData();
    formData.append('file', files[0]);
    return formData;
  };

  useEffect(() => {
    asyncfeatch();
  }, []);

  const handleOnLoad = async (files) => {
    const formData = prepareFormData(files);
    await uploadAirBroadcast(formData);
    asyncfeatch();
  };

  return (
    <Paper style={{ padding: 15, marginBottom: 15 }} elevation={0}>
      <Dropzone onDrop={handleOnLoad} accept=".xls, .xlsx, .xlsm">
        {({ getRootProps, getInputProps }) => (
          <section className={classes.dropZoneContainer}>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <p>Перетащите файл сюда, или кликните для выбора файла</p>
            </div>
          </section>
        )}
      </Dropzone>

      {state && (
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Дата последней загрузки</TableCell>
                <TableCell>Автор</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow className={classes.tableRow}>
                <TableCell>
                  {dayjs(state.updated_at).format('DD-MM-YYYY HH:mm')}
                </TableCell>
                <TableCell>
                  {`${state.author.first_name} ${state.author.last_name}`}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
};

export default Air;
