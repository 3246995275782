import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import DigitsList from 'feature/DigitsList';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'hook/useQuery';
import { getUniversalData } from '../../../request';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
  tools: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '20px',
    marginBottom: 15,
  },
  wrapFormControl: {
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    gap: '20px',
  },
  formControl: {
    minWidth: 150,
  },
  formControlSelect: {
    minWidth: 150,
  },
});

let debounce;

const Digits = () => {
  const classes = useStyles();
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const [users, setUsers] = useState();
  const initPage =
    query.get('page') && typeof Number(query.get('page')) === 'number'
      ? Number(query.get('page'))
      : 1;
  const initPerPage =
    query.get('per_page') && typeof Number(query.get('per_page')) === 'number'
      ? Number(query.get('per_page'))
      : 20;
  const initSearch = query.get('search') || '';
  const [page, setPage] = useState(initPage);
  const [rowsPerPage, setRowsPerPage] = useState(initPerPage);
  const [search, setSearch] = useState(initSearch);

  useEffect(() => {
    if (!location.search) {
      history.push(
        `/admin/sp-main-digits?page=${page}&per_page=${rowsPerPage}&search=${search}`
      );
      return;
    }
    async function fetchData() {
      let prepareQuery = location.search;
      prepareQuery += '&target_id=3';
      const res = await getUniversalData({
        path: 'np/triple-block/digit',
        query: prepareQuery,
      });
      setUsers(res);
    }
    fetchData();
  }, [location.search]);

  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value, 10);
    history.push(
      `/admin/sp-main-digits?page=1&per_page=${value}&search=${search}`
    );
    setRowsPerPage(value);
  };

  const handleChangePage = (event, page) => {
    history.push(
      `/admin/sp-main-digits?page=${
        page + 1
      }&per_page=${rowsPerPage}&search=${search}`
    );
    setPage(page + 1);
  };

  const handleChangeSearch = ({ target }) => {
    setSearch(target.value);
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      history.push(
        `/admin/sp-main-digits?page=1&per_page=${rowsPerPage}&search=${target.value}`
      );
      setPage(1);
    }, 600);
  };

  return (
    <>
      <Paper style={{ padding: 15, marginBottom: 15 }} elevation={0}>
        <div className={classes.tools}>
          <Button
            onClick={() => history.push('/admin/sp-main-digits/new')}
            variant="contained"
            color="primary"
          >
            Добавить карточку
          </Button>
          <div className={classes.wrapFormControl}>
            <FormControl className={classes.formControl}>
              <TextField
                label="Поиск"
                variant="outlined"
                size="small"
                fullWidth
                value={search}
                onChange={handleChangeSearch}
              />
            </FormControl>
          </div>
        </div>
        {users && (
          <DigitsList
            {...users}
            onClick={(id) => history.push(`/admin/sp-main-digits/edit/${id}`)}
            page={page - 1}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onChangePage={handleChangePage}
          />
        )}
      </Paper>
    </>
  );
};

export default Digits;
