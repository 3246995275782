import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import dayjs from 'dayjs';
import CompareArticleVersion from 'feature/CompareArticleVersion';
import { getArticleVersions, revertArticleVersion } from '../../../request';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginTop: 30,
  },
}));

const ArticleVersioning = ({ article, showDialog }) => {
  const classes = useStyles();
  const [list, setList] = useState();
  const [state, setState] = useState({
    firstVersion: null,
    secondVersion: null,
    firstVersionContent: null,
    secondVersionContent: null,
  });

  useEffect(() => {
    async function fetchData() {
      const res = await getArticleVersions(article.slug);
      if (res) setList(res);
    }
    fetchData();
  }, []);

  const changeVersionHandler = async (version) => {
    showDialog('Переключить на эту версию?', async () => {
      await revertArticleVersion(article.slug, version);
      if (document) document.location.reload();
    });
  };

  const compare = () => {
    if (
      typeof state.firstVersion !== 'number' ||
      typeof state.secondVersion !== 'number' ||
      state.firstVersion === state.secondVersion
    ) {
      setState({
        ...state,
        firstVersionContent: null,
        secondVersionContent: null,
      });
    } else {
      setState({
        ...state,
        firstVersionContent: list[state.firstVersion],
        secondVersionContent: list[state.secondVersion],
      });
    }
  };

  return (
    <>
      <Paper style={{ padding: 15, marginBottom: 15 }} elevation={0}>
        <b>Версионирование статей</b>
      </Paper>

      <Paper style={{ padding: 15, marginBottom: 15 }} elevation={0}>
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">α</TableCell>
                <TableCell align="center">β</TableCell>
                <TableCell align="center">#Версия</TableCell>
                <TableCell align="center">Дата создания версии</TableCell>
                <TableCell align="center">Кто изменил</TableCell>
                <TableCell align="center">Действие</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list &&
                list.map((row, index) => (
                  <TableRow className={classes.tableRow} key={row.id}>
                    <TableCell align="center">
                      <Radio
                        checked={state.firstVersion === index}
                        onChange={(event) =>
                          setState({
                            ...state,
                            firstVersion: Number(event.target.value),
                          })
                        }
                        value={index}
                        name="radio-button-first-version"
                        inputProps={{ 'aria-label': 'First-version' }}
                        size="small"
                        color="primary"
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Radio
                        checked={state.secondVersion === index}
                        onChange={(event) =>
                          setState({
                            ...state,
                            secondVersion: Number(event.target.value),
                          })
                        }
                        value={index}
                        name="radio-button-second-version"
                        inputProps={{ 'aria-label': 'Second-version' }}
                        size="small"
                        color="primary"
                      />
                    </TableCell>
                    <TableCell align="center">{row.number}</TableCell>
                    <TableCell align="center">
                      {dayjs(row.created_at).format('DD-MM-YYYY HH:mm')}
                    </TableCell>
                    <TableCell align="center">{row.user_name}</TableCell>
                    <TableCell align="center">
                      {row.is_actual ? (
                        'Это актуальная версия'
                      ) : (
                        <Button
                          size="small"
                          variant="outlined"
                          onClick={() => changeVersionHandler(row.number)}
                        >
                          Откатить на эту версию
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={compare}
        >
          Сравнить версии
        </Button>
      </Paper>
      <CompareArticleVersion
        firstVersion={state.firstVersionContent}
        secondVersion={state.secondVersionContent}
      />
    </>
  );
};

const mapDispatch = (dispatch) => ({
  showDialog: (message, cb) =>
    dispatch.dialog.toggle({
      message,
      onAgree: cb,
    }),
});

export default connect(null, mapDispatch)(ArticleVersioning);
