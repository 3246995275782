import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import UsersList from 'feature/UsersList';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'hook/useQuery';
import { getUsersList } from '../../../request';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
  tools: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '20px',
    marginBottom: 15,
  },
  wrapFormControl: {
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    gap: '20px',
  },
  formControl: {
    minWidth: 150,
  },
  formControlSelect: {
    minWidth: 150,
  },
});

const targets = [
  {
    name: 'Ямал-медиа',
    value: 1,
  },
  {
    name: 'Красный север',
    value: 2,
  },
  {
    name: 'Все',
    value: '',
  },
];

let debounce;

const Users = () => {
  const classes = useStyles();
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const [users, setUsers] = useState();
  const initPage =
    query.get('page') && typeof Number(query.get('page')) === 'number'
      ? Number(query.get('page'))
      : 1;
  const initPerPage =
    query.get('per_page') && typeof Number(query.get('per_page')) === 'number'
      ? Number(query.get('per_page'))
      : 20;
  const initTarget =
    query.get('target_id') && typeof Number(query.get('target_id')) === 'number'
      ? Number(query.get('target_id'))
      : '';
  const initSearch = query.get('search') || '';
  const [page, setPage] = useState(initPage);
  const [rowsPerPage, setRowsPerPage] = useState(initPerPage);
  const [targetId, setTargetId] = useState(initTarget);
  const [search, setSearch] = useState(initSearch);

  useEffect(() => {
    if (!location.search) {
      history.push(
        `/admin/administration/users?page=${page}&per_page=${rowsPerPage}&target_id=${targetId}&search=${search}`
      );
      return;
    }
    async function fetchData() {
      const prepareQuery = location.search;
      if (!targetId) prepareQuery.replace('&target_id=', '');
      const res = await getUsersList(prepareQuery);
      setUsers(res);
    }
    fetchData();
  }, [location.search]);

  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value, 10);
    history.push(
      `/admin/administration/users?page=1&per_page=${value}&target_id=${targetId}&search=${search}`
    );
    setRowsPerPage(value);
  };

  const handleChangePage = (event, page) => {
    history.push(
      `/admin/administration/users?page=${
        page + 1
      }&per_page=${rowsPerPage}&target_id=${targetId}&search=${search}`
    );
    setPage(page + 1);
  };

  const handleChangeTarget = ({ target }) => {
    history.push(
      `/admin/administration/users?page=1&per_page=${rowsPerPage}&target_id=${target.value}&search=${search}`
    );
    setPage(1);
    setTargetId(target.value);
  };

  const handleChangeSearch = ({ target }) => {
    setSearch(target.value);
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      history.push(
        `/admin/administration/users?page=1&per_page=${rowsPerPage}&target_id=${targetId}&search=${target.value}`
      );
      setPage(1);
    }, 600);
  };

  return (
    <>
      <Paper style={{ padding: 15, marginBottom: 15 }} elevation={0}>
        <div className={classes.tools}>
          <Button
            onClick={() => history.push('/admin/administration/users/new')}
            variant="contained"
            color="primary"
          >
            Добавить пользователя
          </Button>
          <div className={classes.wrapFormControl}>
            <FormControl className={classes.formControl}>
              <TextField
                label="Поиск"
                variant="outlined"
                size="small"
                fullWidth
                value={search}
                onChange={handleChangeSearch}
              />
            </FormControl>
            <FormControl className={classes.formControlSelect}>
              <Select
                labelId="select-site-label"
                value={targetId}
                onChange={handleChangeTarget}
              >
                {targets.map(({ value, name }) => (
                  <MenuItem key={value} value={value}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        {users && (
          <UsersList
            {...users}
            onClick={(id) =>
              history.push(`/admin/administration/users/edit/${id}`)
            }
            page={page - 1}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onChangePage={handleChangePage}
          />
        )}
      </Paper>
    </>
  );
};

export default Users;
