export const YOUTUBE_REGEXP =
  /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
export const RUTUBE_REGEXP =
  /^.*((rutube.ru\/video\/)|(\/u\/\w\/))\??v?=?([^#&?]*)./;
export const VK_REGEXP_OLD =
  /^.*((vk.com\/))\??video_ext\.php\?=?oid=([^#&?]*)&id=([^#&?]*)?.*/;
export const VK_REGEXP =
  /^.*((vk.com\/))\??video_ext\.php\?=?oid=([^#&?]*)&id=([^#&?]*)(&hd=([^#&?]*))&hash=([^#&?]*)?.*/;

const prepareLink = (url) => {
  if (!url || typeof url !== 'string') return;
  if (url.match(YOUTUBE_REGEXP)) {
    return {
      platform: 'youtube',
      id: url.match(YOUTUBE_REGEXP)[7],
    };
  } else if (url.match(RUTUBE_REGEXP)) {
    return {
      platform: 'rutube',
      id: url.match(RUTUBE_REGEXP)[4],
    };
  } else if (url.match(VK_REGEXP) || url.match(VK_REGEXP_OLD)) {
    return {
      platform: 'vkontakte',
      id:
        new DOMParser().parseFromString(url, 'text/html')?.body?.children?.[0]
          ?.src || url,
    };
  }
};

const platformMap = {
  youtube: (id) => `https://youtube.com/embed/${id}`,
  rutube: (id) => `https://rutube.ru/play/embed/${id}`,
  vkontakte: (id) => id,
};

const adapter = (oldData, newData) => {
  let data = oldData;
  if (newData && newData.id) {
    data = newData;
  }
  if (!data) return '';
  if (typeof data === 'string') {
    return platformMap['youtube'](data);
  } else {
    return platformMap[data.platform](data.id);
  }
};

const useGetVideo = () => {
  return {
    prepareLink,
    platformMap,
    adapter,
  };
};

export default useGetVideo;
