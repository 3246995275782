export const getFormData = (data) => {
  const formData = new FormData();
  if (!data && typeof data !== 'object') return formData;
  Object.entries(data).forEach(([key, value]) => {
    formData.append(key, value);
  });
  return formData;
};

const setLink = (text, href) => {
  return {
    data: {},
    type: 'paragraph',
    nodes: [
      {
        text: '',
        marks: [],
        object: 'text',
      },
      {
        data: {
          href,
        },
        type: 'link',
        nodes: [
          {
            text,
            marks: [],
            object: 'text',
          },
        ],
        object: 'inline',
      },
      {
        text: '',
        marks: [],
        object: 'text',
      },
    ],
    object: 'block',
  };
};

export const modularEditorAdapter = (text, textLink, link) => {
  const result = {
    object: 'value',
    document: {
      data: {},
      nodes: [
        {
          data: {},
          type: 'lead',
          nodes: [
            {
              text: '',
              marks: [],
              object: 'text',
            },
          ],
          object: 'block',
        },
        {
          data: {},
          type: 'paragraph',
          nodes: [
            {
              text,
              marks: [],
              object: 'text',
            },
          ],
          object: 'block',
        },
      ],
      object: 'document',
    },
  };

  if (textLink && link) {
    result.document.nodes.push(setLink(textLink, link));
  }

  return result;
};

export const descriptionAddLink = (data, textLink, link) => {
  if (textLink && link) {
    const newData = JSON.parse(JSON.stringify(data));
    newData.document.nodes.push(setLink(textLink, link));
    return newData;
  }
  return data;
};
