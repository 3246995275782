/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { createBanner, saveBanner, getBanner } from '../../request';
import BannersCrud from 'components/Promo/BannersCrud';

const mapUrl = ['/admin/red-north/promo', '/admin/promo', '/admin/sp-banners'];

const validate = ({ code_name, value, position, priority, target_id }) => {
  const errors = {};
  if (!code_name) {
    errors.code_name = 'Обязательно для заполнения';
  }
  if (!value) {
    errors.value = 'Обязательно для заполнения';
  }
  if (!position) {
    errors.position = 'Обязательно для заполнения';
  }
  if (!priority) {
    errors.priority = 'Обязательно для заполнения';
  }
  if (!target_id) {
    errors.target_id = 'Обязательно для заполнения';
  }

  return errors;
};

const CrudPromo = ({ defaultTarget = 1 }) => {
  const history = useHistory();
  const { id } = useParams();
  const [values, setValues] = useState({});
  const defaultUrl = mapUrl.find((url) =>
    history?.location?.pathname?.includes(url)
  );

  useEffect(() => {
    async function fetchData() {
      const res = await getBanner(id);
      if (res.id) setValues(res);
      else history.push(defaultUrl);
    }
    if (id) fetchData();
  }, []);

  const onSubmit = async (values) => {
    try {
      if (id) {
        await saveBanner(id, { ...values });
      } else {
        await createBanner({
          ...values,
          is_active: values.is_active || false,
        });
      }
      history.push(defaultUrl);
    } catch (error) {
      console.error('ошибка при сохранении статьи', error);
    }
  };
  let { target, ...curValues } = values;
  curValues = { ...curValues, target_id: target?.id || defaultTarget };

  return (
    <BannersCrud
      values={curValues}
      onSubmit={onSubmit}
      validate={validate}
      defaultTarget={defaultTarget}
    />
  );
};

export default CrudPromo;
