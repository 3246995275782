import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core/index';
import { makeStyles } from '@material-ui/core/styles';
import Actions from 'feature/Actions/index';
import dayjs from 'dayjs';

const PhotoBankList = ({ list, filter }) => {
  const { dateFrom, dateTo, authors } = filter;
  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    tableContainer: {
      marginTop: 30,
    },
    tableRow: {
      cursor: 'pointer',
    },
    tableCellAction: {
      cursor: 'pointer',
      transition: 'all 0.3s',
      border: '1px solid rgba(224, 224, 224, 1) !important',
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: '#3f51b5',
        color: 'white',
      },
    },
  });
  const classes = useStyles();
  const toMs = (date) => dayjs(date).valueOf();
  const handlePushBank = (slug) =>
    window.open(`/admin/photobanks/edit/${slug}`);
  const filterDateRule = ({ created_at }) => {
    const created = toMs(created_at);
    const from = toMs(dateFrom);
    const to = toMs(dateTo);

    if (dateFrom && dateTo) {
      return created >= from && created <= to;
    }
    if (dateFrom) {
      return created >= from;
    }
    if (dateTo) {
      return created <= to;
    }
    return created_at;
  };
  const userRule = (item) => {
    if (
      !authors.length ||
      item.authors.filter(({ id }) => authors.includes(id)).length
    )
      return item;
  };

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Заголовок</TableCell>
            <TableCell align="center">Опубликован</TableCell>
            <TableCell align="center">Дата создания</TableCell>
            <TableCell align="center">Действия</TableCell>
            <TableCell align="center">Автор</TableCell>
            <TableCell align="center">Количество фото</TableCell>
            <TableCell align="center">Просмотры</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list
            .filter(filterDateRule)
            .filter(userRule)
            .map((item) => (
              <Actions
                key={item.id}
                item={item}
                handlePushBank={handlePushBank}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default PhotoBankList;
