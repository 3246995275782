import React from 'react';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  wrapperSlot: {
    display: 'grid',
    gap: '20px',
    gridTemplateColumns: '1fr 50px',
    alignItems: 'center',
    position: 'relative',
    borderRadius: '3px',
    background: '#eeeeee',
    color: '#000',
  },
  mainList: {
    display: 'flex',
    gap: '1em',
    position: 'relative',
    flexDirection: 'column',
    maxHeight: '500px',
    overflow: 'scroll',
  },
  title: {
    padding: '10px',
  },
}));

const MainList = ({
  navs,
  dragOverHandler,
  dragStartHandler,
  dropHandler,
  handleDragLeave,
  deleteIsNav,
  slotsCount,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.mainList}>
      {navs.map((item) => {
        return (
          <div
            draggable="true"
            onDragStart={() => dragStartHandler(item)}
            onDragLeave={(e) => handleDragLeave(e)}
            onDrop={(e) => dropHandler(e, item)}
            onDragOver={(e) => dragOverHandler(e)}
            key={item.id}
            className={`${classes.wrapperSlot}`}
          >
            <div className={`${classes.title} slot`}>{item.title}</div>
            <IconButton aria-label="delete" onClick={() => deleteIsNav(item)}>
              <DeleteIcon />
            </IconButton>
          </div>
        );
      })}
      {navs.length < slotsCount && (
        <div
          className={`${classes.wrapperSlot} ${classes.title} slot`}
          onDragStart={() => dragStartHandler()}
          onDrop={(e) => dropHandler(e)}
          onDragOver={(e) => dragOverHandler(e)}
          onDragLeave={(e) => handleDragLeave(e)}
        >
          Добавить навигацию
        </div>
      )}
    </div>
  );
};

export default MainList;
