import { PrefixedField } from '../components/CustomField';
import { defaultValue, fetchConfig } from '../constants';
import { AsyncAutocomplete } from '../components/AsyncAutocomplete';
import React from 'react';

export const renderGridByScheme = ({
  items,
  parentPath = '',
  customPath = false,
}) => {
  return items.map((item, itemId) => {
    const isMulti = Array.isArray(item.items);
    const prefixName = customPath
      ? `${parentPath}.items[${itemId}]`
      : `${parentPath}[${itemId}]`;

    if (isMulti) {
      return (
        <div key={prefixName}>
          {renderGridByScheme({
            items: item.items,
            parentPath: prefixName,
            customPath: true,
          })}
        </div>
      );
    }
    return (
      <PrefixedField
        key={prefixName}
        name={prefixName}
        defaultValue={defaultValue}
        render={(renderProps) => {
          return (
            <AsyncAutocomplete
              type={item.dataType}
              viewType={item.viewType}
              defaultValue={renderProps}
              target_id={3}
              label={item.title}
              config={fetchConfig[item.dataType]}
              {...renderProps}
            />
          );
        }}
      />
    );
  });
};
