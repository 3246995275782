import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import { TextField } from 'mui-rff';
import Button from '@material-ui/core/Button';
import { useParams, useHistory } from 'react-router-dom';
import { setUniversalData, getUniversalData } from '../../../request';

const MAX_TEXT = 100;

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTextField-root': {
      margin: '8px 0',
      width: '100%',
    },
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
    },
  },
  submitBtn: {
    marginRight: 5,
  },
}));

const validate = ({ url, digit, text }) => {
  const errors = {};
  if (!url) {
    errors.url = 'Обязательно для заполнения';
  }
  if (!digit) {
    errors.digit = 'Обязательно для заполнения';
  }
  if (!text) {
    errors.text = 'Обязательно для заполнения';
  }
  if (text?.length && text.length > MAX_TEXT) {
    errors.text = 'Превышен лимит символов';
  }
  return errors;
};

const CrudDigit = () => {
  const history = useHistory();
  const { id } = useParams();
  const classes = useStyles();
  const [values, setValues] = useState({});
  useEffect(() => {
    async function fetchData() {
      const res = await getUniversalData({
        path: 'np/triple-block/digit/' + id,
      });
      if (res.id) setValues(res);
      else history.push(`/admin/sp-main-digits`);
    }
    if (id) fetchData();
  }, []);

  const onSubmit = async (data) => {
    data = { ...data, target: 3 };
    try {
      if (id) {
        await setUniversalData({
          path: 'np/triple-block/digit/' + id,
          data,
          method: 'put',
        });
      } else {
        await setUniversalData({ path: 'np/triple-block/digit', data });
      }
      history.push(`/admin/sp-main-digits`);
    } catch (error) {
      console.error('ошибка при сохранении', error);
    }
  };

  return (
    <Form
      initialValues={values}
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, valid, values }) => (
        <form
          onSubmit={handleSubmit}
          className={classes.root}
          noValidate
          autoComplete="off"
        >
          <Paper style={{ padding: 15 }} elevation={0}>
            <TextField
              required
              label="Ссылка на ресурс"
              variant="outlined"
              name="url"
              margin="none"
              size="small"
            />
            <TextField
              required
              label="Цифра на карточке"
              variant="outlined"
              name="digit"
              margin="none"
              size="small"
            />
            <TextField
              required
              label="Текст карточки"
              variant="outlined"
              name="text"
              margin="none"
              size="small"
            />
            {values?.text && (
              <span>
                {values.text.length} из {MAX_TEXT}
              </span>
            )}
            <br />
            <br />
            <Button
              disabled={!valid}
              type="submit"
              className={classes.submitBtn}
              variant="contained"
              color="primary"
            >
              Сохранить
            </Button>
          </Paper>
        </form>
      )}
    />
  );
};

export default CrudDigit;
