import React from 'react';
import cn from 'classnames';

// const calcResponsivePercents = (height, width) => (height / width) * 100

/**
 * Блок для вставки iframe
 * @extends Component
 */
const IFrame = ({
  src,
  allowfullscreen,
  frameborder,
  shareProvider,
  isAmp,
  ...restProps
}) => {
  const height = restProps.height || 315;
  const width = restProps.width || 560;

  const horizontal =
    parseInt(width, 10) > parseInt(height, 10) && !src.match('facebook.com');

  const bodyClasses = cn({
    'frame-container_body': horizontal,
  });

  // const containerStyle = horizontal
  //   ? { paddingBottom: '16px' }
  //   : { textAlign: 'center' }

  const containerStyle = { textAlign: 'center', paddingBottom: '16px' };

  const youtubeWrapper = () => {
    const parseSrc = src.split('/');
    const id = parseSrc[parseSrc.length - 1];
    return isAmp ? (
      <amp-iframe
        title="iframe-responsive"
        width="500"
        height="281"
        layout="responsive"
        sandbox="allow-scripts allow-same-origin"
        src={src}
      />
    ) : (
      <iframe
        style={{ backgroundColor: 'black' }}
        src={src}
        srcDoc={`<style>html, body, a, img {display: block; width: 100%; height: 100%; margin: 0; padding: 0;} a:before, a:after {position: absolute; content: ''; left: 50%; top: 50%;} a:before {margin: -4.9% 0 0 -7%; background: rgba(166, 206, 57, 0.8); padding-top: 9.8%; width: 14%; border-radius: 16% / 27%;} a:after {margin: -1.9vw 0 0 -1vw; border: 2vw solid transparent; border-left: 3.8vw solid #fff;} a:hover:before {background: #AFCA0B}</style><a href='https://www.youtube-nocookie.com/embed/${id}?autoplay=1&mute=1&enablejsapi=1&origin=https://360tv.ru'><img src='//img.youtube.com/vi/${id}/maxresdefault.jpg' srcset='//img.youtube.com/vi/${id}/mqdefault.jpg 320w, //img.youtube.com/vi/${id}/hqdefault.jpg 480w, //img.youtube.com/vi/${id}/maxresdefault.jpg 1307w'></a>`}
        allowFullScreen={allowfullscreen}
        title="iframe-responsive"
        frameBorder={frameborder}
        width="100%"
        height={height}
        {...restProps}
      />
    );
  };

  const defaultIframe = () =>
    isAmp ? (
      <amp-iframe
        title="iframe-responsive"
        width="500"
        height="281"
        layout="responsive"
        sandbox="allow-scripts allow-same-origin"
        src={src}
      />
    ) : (
      <iframe
        src={src}
        title="iframe-responsive"
        allowFullScreen={allowfullscreen}
        frameBorder={frameborder}
        width="100%"
        height={height}
        {...restProps}
      />
    );

  return (
    <div className="box-video">
      <div className={bodyClasses} style={containerStyle}>
        {shareProvider === 'YOUTUBE' && !isAmp
          ? youtubeWrapper()
          : defaultIframe()}
      </div>
    </div>
  );
};

export default IFrame;
