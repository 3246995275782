/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'hook/useQuery';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { deleteScript, getScriptsList } from '../../../request';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import { objectToQuery } from 'assets/js/helper';

let debounce = null;
const BASE_URL = '/admin/classifiers/scripts';
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginTop: 30,
  },
  tableRow: {
    // cursor: 'pointer',
  },
  tableCellAction: {
    cursor: 'pointer',
    transition: 'all 0.3s',
    border: '1px solid rgba(224, 224, 224, 1) !important',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#3f51b5',
      color: 'white',
    },
  },
  tools: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '20px',
  },
  search: {
    paddingBottom: 10,
  },
});

const Actions = ({ id, updater }) => {
  const [isOpenAlertDeleteModal, setOpenAlertDeleteModal] = useState(false);

  const handleDeleteArticle = async () => {
    const { res } = await deleteScript(id);
    if (res.status == 204) {
      updater();
    }
  };

  return (
    <>
      <Modal
        open={isOpenAlertDeleteModal}
        onBackdropClick={() => setOpenAlertDeleteModal(false)}
        style={{
          display: 'grid',
          placeContent: 'center',
        }}
      >
        <Paper
          style={{
            width: 400,
            alignSelf: 'center',
          }}
        >
          <Alert
            variant="outlined"
            severity="error"
            action={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-end',
                }}
              >
                <Button
                  onClick={() => {
                    setOpenAlertDeleteModal(false);
                    handleDeleteArticle();
                  }}
                >
                  Удалить
                </Button>
                <Button
                  onClick={() => {
                    setOpenAlertDeleteModal(false);
                  }}
                >
                  Отмена
                </Button>
              </div>
            }
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              Вы уверены что хотите удалить?
            </div>
          </Alert>
        </Paper>
      </Modal>
      <div>
        <Tooltip title="Удалить" aria-label="add">
          <IconButton
            color="secondary"
            aria-label="remove papper"
            onClick={() => setOpenAlertDeleteModal(true)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </div>
    </>
  );
};

const ListScripts = () => {
  const classes = useStyles();
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const [list, setList] = useState();
  const initPage =
    query.get('page') && typeof Number(query.get('page')) === 'number'
      ? Number(query.get('page'))
      : 1;
  const initPerPage =
    query.get('per_page') && typeof Number(query.get('per_page')) === 'number'
      ? Number(query.get('per_page'))
      : 50;

  // Удаляем параметры не относящиеся к фильтрам
  query.delete('page');
  query.delete('per_page');

  const [page, setPage] = React.useState(initPage);
  const [rowsPerPage, setRowsPerPage] = React.useState(initPerPage);
  const [filters, setFilters] = useState(Object.fromEntries(query.entries()));

  useEffect(() => {
    if (!location.search) {
      history.push(
        `${BASE_URL}?page=${page}&per_page=${rowsPerPage}${objectToQuery(
          filters
        )}`
      );
      return;
    }

    fetchData();
  }, [location.search]);

  const fetchData = async () => {
    const res = await getScriptsList(location.search);
    if (res.results) setList(res);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value, 10);
    history.push(
      `${BASE_URL}?page=1&per_page=${value}${objectToQuery(filters)}`
    );
    setRowsPerPage(value);
  };

  const handleChangePage = (event, nextPage) => {
    history.push(
      `${BASE_URL}?page=${nextPage + 1}&per_page=${rowsPerPage}${objectToQuery(
        filters
      )}`
    );
    setPage(nextPage + 1);
  };

  const handleChangeSearch = ({ target }) => {
    setFilters({ ...filters, search: target.value });
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      history.push(
        `${BASE_URL}?page=1&per_page=${rowsPerPage}${objectToQuery({
          ...filters,
          search: target.value,
        })}`
      );
      setPage(1);
    }, 600);
  };

  return (
    <>
      <Paper style={{ padding: 15, marginBottom: 15 }} elevation={0}>
        <div className={classes.tools}>
          <Button
            onClick={() => history.push(`${BASE_URL}/new`)}
            variant="contained"
            color="primary"
          >
            Добавить скрипт
          </Button>
          <TextField
            label="Поиск"
            className={classes.search}
            value={filters.search}
            onChange={handleChangeSearch}
          />
        </div>
        <Divider />
        {list && (
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Название</TableCell>
                  <TableCell align="center">Активен</TableCell>
                  <TableCell align="center">Действия</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.results.map((row) => (
                  <TableRow
                    className={classes.tableRow}
                    key={row.id}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.tableCellAction}
                      onClick={() => history.push(`${BASE_URL}/edit/${row.id}`)}
                    >
                      {row.code_name}
                    </TableCell>
                    <TableCell align="center">
                      {row.is_active ? (
                        <PlayCircleOutlineIcon color="primary" />
                      ) : (
                        <PauseCircleOutlineIcon color="error" />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <Actions {...row} updater={fetchData} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10]}
                    labelRowsPerPage="Статьи"
                    labelDisplayedRows={({ from, to, count }) =>
                      `${from}-${to} из ${count !== -1 ? count : to}`
                    }
                    count={list.count}
                    rowsPerPage={rowsPerPage}
                    page={page - 1}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
      </Paper>
    </>
  );
};

export default ListScripts;
