import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'hook/useQuery';
import { getContractsList } from '../../../request';
import ContractsList from 'feature/ContractsList';

const Contracts = () => {
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const [contracts, setContracts] = useState();
  const initPage =
    query.get('page') && typeof Number(query.get('page')) === 'number'
      ? Number(query.get('page'))
      : 1;
  const initPerPage =
    query.get('per_page') && typeof Number(query.get('per_page')) === 'number'
      ? Number(query.get('per_page'))
      : 10;
  const [page, setPage] = useState(initPage);
  const [rowsPerPage, setRowsPerPage] = useState(initPerPage);

  useEffect(() => {
    if (!location.search) {
      history.push(
        `/admin/administration/contracts?page=${page}&per_page=${rowsPerPage}`
      );
      return;
    }
    async function fetchData() {
      const contractsResponse = await getContractsList(location.search);
      setContracts(contractsResponse);
    }
    fetchData();
  }, [location.search]);

  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value, 10);
    history.push(`/admin/administration/contracts?page=1&per_page=${value}`);
    setRowsPerPage(value);
  };

  const handleChangePage = (event, page) => {
    history.push(
      `/admin/administration/contracts?page=${page + 1}&per_page=${rowsPerPage}`
    );
    setPage(page + 1);
  };

  return (
    <>
      <Paper style={{ padding: 15, marginBottom: 15 }} elevation={0}>
        <Button
          onClick={() => history.push('/admin/administration/contracts/new')}
          variant="contained"
          color="primary"
        >
          Добавить договор
        </Button>
        {contracts && (
          <ContractsList
            {...contracts}
            onClick={(id) =>
              history.push(`/admin/administration/contracts/edit/${id}`)
            }
            page={page - 1}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onChangePage={handleChangePage}
          />
        )}
      </Paper>
    </>
  );
};

export default Contracts;
