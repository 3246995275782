import React, { useRef, useEffect, useState } from 'react';
import { loadScript } from 'assets/js/helper.js';
import initEditor from './initEditor';
import Preloader from 'components/Preloader';

const ModularEditor = ({
  className,
  initValue = null,
  onInit,
  articleID,
  configType,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const modularEditorTarget = useRef();
  const getInstatceMD = (md) => {
    setIsLoading(false);
    onInit(md);
  };

  useEffect(() => {
    if (window.ModularEditor) {
      initEditor({
        targetNode: modularEditorTarget.current,
        initValue,
        articleID,
        cb: getInstatceMD,
      });
    } else {
      loadScript(`${window.location.origin}/modular_editor.js`, () =>
        initEditor({
          targetNode: modularEditorTarget.current,
          initValue,
          articleID,
          configType,
          cb: getInstatceMD,
        })
      );
    }
  }, []);

  return (
    <>
      <div
        id="modular-editor"
        ref={modularEditorTarget}
        className={className}
      />
      {isLoading && <Preloader />}
    </>
  );
};

export default ModularEditor;
