import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import { TextField, Switches } from 'mui-rff';
import Button from '@material-ui/core/Button';
import { useParams, useHistory } from 'react-router-dom';
import { setUniversalData, getUniversalData } from '../../../request';
import Dropzone from 'react-dropzone';
import { getBase64Image } from 'assets/js/helper';
import Grid from '@material-ui/core/Grid';
import { IconButton, Modal } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import { Alert } from '@material-ui/lab';

const isValidUrl = (urlString) => {
  var urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
};

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTextField-root': {
      margin: '8px 0',
      width: '100%',
    },
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
    },
  },
  submitBtn: {
    marginRight: 5,
  },
  dropZoneContainer: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '19px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    margin: '8px 0',
  },
  avatar: {
    cursor: 'pointer',
    with: '100%',
  },
  calendarWrapper: {
    display: 'flex',
  },
  todayBtn: {
    width: 40,
  },
}));

const validate = ({ url, main_text, second_text, name, post }) => {
  const errors = {};
  if (!url) {
    errors.url = 'Обязательно для заполнения';
  }
  if (!isValidUrl(url)) {
    errors.url = 'Введите правильный URL';
  }
  if (!main_text) {
    errors.main_text = 'Обязательно для заполнения';
  }
  if (!second_text) {
    errors.second_text = 'Обязательно для заполнения';
  }

  if (!name) {
    errors.name = 'Обязательно для заполнения';
  }

  if (!post) {
    errors.post = 'Обязательно для заполнения';
  }
  return errors;
};

const CrudBlockquote = () => {
  const history = useHistory();
  const { id } = useParams();
  const classes = useStyles();
  const [values, setValues] = useState({
    starts_at: new Date(),
  });
  const [image, setImage] = useState();
  const [file, setFile] = useState();
  const [isOpenAlertDeleteModal, setOpenAlertDeleteModal] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const res = await getUniversalData({
        path: 'np/quotes/' + id,
      });
      if (res.id) setValues(res);
      else history.push(`/admin/sp-main-blockquote`);
    }
    if (id) fetchData();
  }, []);

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('target', 3);
    formData.append('main_text', data.main_text);
    formData.append('second_text', data.second_text);
    formData.append('url', data.url);
    formData.append('name', data.name);
    formData.append('post', data.post);
    formData.append('is_active', Boolean(data.is_active));
    if (file) formData.append('image', file);

    try {
      if (id) {
        await setUniversalData({
          path: 'np/quotes/' + id,
          data: formData,
          method: 'put',
        });
      } else {
        await setUniversalData({
          path: 'np/quotes',
          data: formData,
        });
      }
      history.push(`/admin/sp-main-blockquote`);
    } catch (error) {
      console.error('ошибка при сохранении', error);
    }
  };

  const handleOnLoad = async (imgFile) => {
    const baseFile = await getBase64Image(imgFile[0]);
    setImage(baseFile);
    setFile(imgFile[0]);
  };

  const handleDelate = async () => {
    await setUniversalData({
      path: 'np/quotes/' + id,
      method: 'delete',
    });
    history.push('/admin/sp-main-blockquote');
  };

  return (
    <>
      <Modal
        open={isOpenAlertDeleteModal}
        onBackdropClick={() => setOpenAlertDeleteModal(false)}
        style={{
          display: 'grid',
          placeContent: 'center',
        }}
      >
        <Paper
          style={{
            width: 400,
            alignSelf: 'center',
          }}
        >
          <Alert
            variant="outlined"
            severity="error"
            action={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-end',
                }}
              >
                <Button
                  onClick={() => {
                    handleDelate();
                    setOpenAlertDeleteModal(false);
                  }}
                >
                  Удалить
                </Button>
                <Button
                  onClick={() => {
                    setOpenAlertDeleteModal(false);
                  }}
                >
                  Отмена
                </Button>
              </div>
            }
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              Вы уверены что хотите удалить?
            </div>
          </Alert>
        </Paper>
      </Modal>
      <Form
        initialValues={values}
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, valid, form, ...rest }) => (
          <form
            onSubmit={handleSubmit}
            className={classes.root}
            noValidate
            autoComplete="off"
          >
            <Paper style={{ padding: 15 }} elevation={0}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Switches
                  label="Цитата активна"
                  name="is_active"
                  data={{ label: null }}
                />
                <Tooltip title="Удалить" aria-label="add">
                  <IconButton
                    color="secondary"
                    aria-label="remove papper"
                    onClick={() => setOpenAlertDeleteModal(true)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <TextField
                required
                label="Оглавление цитаты"
                variant="outlined"
                name="main_text"
                margin="none"
                size="small"
              />
              <TextField
                required
                label="Текст цитаты"
                variant="outlined"
                name="second_text"
                margin="none"
                size="small"
              />
              <TextField
                required
                label="Имя автора"
                variant="outlined"
                name="name"
                margin="none"
                size="small"
              />
              <TextField
                required
                label="Должность автора"
                variant="outlined"
                name="post"
                margin="none"
                size="small"
              />
              <TextField
                required
                label="Ссылка на материал"
                variant="outlined"
                name="url"
                margin="none"
                size="small"
              />
              <Dropzone accept=".png" onDrop={handleOnLoad}>
                {({ getRootProps, getInputProps }) => (
                  <section className={classes.dropZoneContainer}>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      {image || rest?.values?.image ? (
                        <img
                          alt="pic"
                          src={image || rest?.values?.image}
                          className={classes.avatar}
                        />
                      ) : (
                        <p>Перенесите или выберите фото</p>
                      )}
                    </div>
                  </section>
                )}
              </Dropzone>
              <Button
                disabled={!valid}
                type="submit"
                className={classes.submitBtn}
                variant="contained"
                color="primary"
              >
                Сохранить
              </Button>
            </Paper>
          </form>
        )}
      />
    </>
  );
};

export default CrudBlockquote;
