const parseDraft = (data) => {
  if (!data) {
    return [];
  }
  return data.split(':#');
};

const isSame = (firstVal, secondVal) => firstVal?.length === secondVal?.length;

const isEditorDataEmpty = (nodes = []) => {
  if (nodes.length > 2) return false;
  const flatNodes = nodes.reduce((acc, root) => {
    return acc.concat(root.nodes);
  }, []);
  return flatNodes.every((node) => !node.text);
};

export class EditorDraft {
  constructor(id, initEditorValues, initFormValues) {
    this.id = id;
    this.initEditorValues = initEditorValues;
    this.initFormValues = initFormValues;
    this.isDraft = false;
  }

  getValuesByType(type) {
    const map = {
      editor: this.initEditorValues,
      form: this.initFormValues,
    };
    return map[type];
  }

  getActualData() {
    const draftEditorValues = localStorage.getItem(`${this.id}_editor`);
    const draftFormValues = localStorage.getItem(`${this.id}_form`);

    if (draftEditorValues || draftFormValues) {
      this.isDraft = true;
    }

    const editorValues = draftEditorValues
      ? JSON.parse(draftEditorValues)
      : JSON.parse(this.initEditorValues);
    const formValues = draftFormValues
      ? JSON.parse(draftFormValues)
      : JSON.parse(this.initFormValues);

    return [editorValues, formValues, this.isDraft];
  }

  saveEditorDraft(draft, type) {
    const isValueSame = isSame(
      this.getValuesByType(type),
      JSON.stringify(draft)
    );

    if (!draft || !type || isValueSame) {
      return this.isDraft;
    }

    if (type === 'editor' && isEditorDataEmpty(draft?.document?.nodes)) {
      localStorage.removeItem(`${this.id}_editor`);
      return this.isDraft;
    }

    localStorage.setItem(`${this.id}_${type}`, JSON.stringify(draft));
    this.isDraft = true;
    return this.isDraft;
  }

  removeEditorDraft() {
    localStorage.removeItem(`${this.id}_editor`);
    localStorage.removeItem(`${this.id}_form`);
  }
}
