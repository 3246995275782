/* eslint-disable import/no-cycle */
/* eslint-disable import/extensions */
/* eslint-disable no-console */
import axios, { baseURL, v2Axios, v2BaseURL } from './axios';
import { store } from '../store.js';

const axiosApiInstance = axios.create();
axiosApiInstance.defaults.baseURL =
  process.env.API_URL || 'https://yamal-media.ru/api/v1';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Content-Type'] = 'application/json';

// метод для работы с snakebar нотификацией через redux
const showSnakebar = ({ message, type = 'success' }) => {
  store.dispatch.snakebar.toggle({
    isShow: true,
    type,
    message,
  });
  setTimeout(store.dispatch.snakebar.close, 3000);
};

const sendError = (error) => {
  try {
    let message = 'Что-то пошло не так!';
    if (Array.isArray(error?.response?.data))
      message = error?.response?.data?.join(', ');
    else if (error?.response?.data?.detail)
      message = error?.response?.data?.detail;
    showSnakebar({ type: 'danger', message });
  } catch (e) {
    console.error(e);
  }
};

//Photobank
export const getPhotoBankList = async (query = '') => {
  try {
    const { data } = await v2Axios(`/photobanks/${query}`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const getPhotoBank = async (slug) => {
  try {
    const { data } = await v2Axios(`/photobanks/${slug}?target_id=2`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const createPhotoBank = async (formData) => {
  try {
    const { data } = await v2Axios.post(`/photobanks/`, formData);
    showSnakebar({ message: 'Фотобанк добавлен' });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const deletePhotoBank = async (slug) => {
  try {
    const data = await v2Axios.delete(`/photobanks/${slug}`);
    showSnakebar({ message: 'Фотобанк удален', type: 'warning' });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const savePhotoBank = async (slug, obj) => {
  try {
    const { data } = await v2Axios.patch(`/photobanks/${slug}/`, { ...obj });
    showSnakebar({ message: 'Сохранено' });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const updatePhotoInBank = async (id, obj) => {
  try {
    const { data } = await v2Axios.patch(`/photobanks/photo/${id}/`, {
      ...obj,
    });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const savePhotoToBank = async (formData) => {
  try {
    const { data } = await v2Axios.post(`/photobanks/photo/`, formData);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const deletePhotoFromBank = async (id) => {
  try {
    const data = await v2Axios.delete(`/photobanks/photo/${id}/`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

// Articles
export const getArticleList = async (query) => {
  try {
    const { data } = await v2Axios(`/articles/${query || ''}`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const createArticle = async (obj) => {
  try {
    const { data } = await v2Axios.post(`/articles/`, { ...obj });
    if (data.id) showSnakebar({ message: 'Статья успешно создана!' });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const getArticle = async (id) => {
  try {
    const { data } = await v2Axios(`/articles/${id}/`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const saveArticle = async (id, obj) => {
  try {
    const { data } = await v2Axios.put(`/articles/${id}/`, { ...obj });
    if (data.id) showSnakebar({ message: 'Сохранено успешно!' });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const updateArticle = async (id, obj) => {
  try {
    const { data } = await v2Axios.patch(`/articles/${id}/`, { ...obj });
    if (data.id) showSnakebar({ message: 'Сохранено успешно!' });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const deleteArticle = async (id) => {
  try {
    const { data, ...res } = await v2Axios.delete(`/articles/${id}/`);

    showSnakebar({ message: 'Удаление прошло успешно!' });

    return { data, res };
  } catch (error) {
    sendError(error);
    return { res: error.response };
  }
};

export const publishArticle = async (id) => {
  try {
    const { data, ...res } = await v2Axios.patch(`/articles/${id}/`, {
      is_active: true,
    });
    showSnakebar({ message: 'Статья опубликована!' });

    return { data, res };
  } catch (error) {
    sendError(error);
    return { res: error.response };
  }
};

export const unPublishArticle = async (id) => {
  try {
    const { data, ...res } = await v2Axios.patch(`/articles/${id}/`, {
      is_active: false,
    });

    showSnakebar({ message: 'Статья снята с публикации!' });

    return { data, res };
  } catch (error) {
    sendError(error);
    return { res: error.response };
  }
};

export const getArticleTargets = async () => {
  try {
    const { data } = await v2Axios(`/articles/article-target/`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const patchArticle = async (slug, obj) => {
  try {
    const { data } = await v2Axios.patch(`/articles/${slug}/`, { ...obj });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

// Classifiers
export const getCategoriesList = async (query) => {
  try {
    const { data } = await v2Axios(`/categories/${query || ''}`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const createCategory = async (obj) => {
  try {
    const { data } = await v2Axios.post(`/categories/`, { ...obj });
    if (data.id) showSnakebar({ message: 'Рубрика успешно создана!' });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const saveCategory = async (id, obj) => {
  try {
    const { data } = await v2Axios.put(`/categories/${id}/`, obj);

    if (data.id) showSnakebar({ message: 'Сохранено успешно!' });

    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const getCategory = async (id) => {
  try {
    const { data } = await v2Axios(`/categories/${id}/`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const getCategoryHeader = async (query) => {
  try {
    const { data } = await v2Axios(`/categories/header${query || ''}`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

// Users
export const getUsersList = async (query) => {
  try {
    const { data } = await axios(`/users/${query || ''}`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

// Кэшированный список пользователей без пагинации
export const getUsersAuthorsList = async (query) => {
  try {
    const { data } = await axios(`/users/authors/${query || ''}`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const createUser = async (obj) => {
  try {
    const { data } = await axios.post(`/users/`, obj);
    if (data.id) showSnakebar({ message: 'Пользователь успешно создан!' });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const getUser = async (id) => {
  try {
    const { data } = await axios(`/users/${id}/`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const saveUser = async (id, obj) => {
  try {
    const { data } = await axios.patch(`/users/${id}/`, obj);
    if (data.id) showSnakebar({ message: 'Сохранено успешно!' });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

// Auth
export const getToken = async (obj) => {
  try {
    const { data } = await axiosApiInstance.post(`/token/`, { ...obj });
    if (data.id) showSnakebar({ message: 'Пользователь успешно создан!' });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const refreshToken = async (obj) => {
  try {
    const { data } = await axiosApiInstance.post(`/token/refresh/`, { ...obj });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

// Permissions
export const getPermissions = async (query) => {
  try {
    const { data } = await axios(`/permissions/${query || ''}`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const savePermissions = async (obj) => {
  try {
    const { data } = await axios.post(`/permissions/`, { permissions: obj });
    if (Array.isArray(data)) showSnakebar({ message: 'Сохранено успешно!' });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

// Images
export const createCoverImage = async (obj) => {
  try {
    const { data } = await axios.post(`/article_images/`, { ...obj });
    if (Array.isArray(data)) showSnakebar({ message: 'Загружено успешно!' });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const updateCoverImage = async (id, obj) => {
  try {
    const { data } = await axios.patch(`/article_images/${id}/`, { ...obj });
    if (Array.isArray(data)) showSnakebar({ message: 'Загружено успешно!' });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const deleteCoverImage = async (id) => {
  try {
    const { data } = await axios.delete(`/article_images/${id}/`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

// Tags
export const getTagsList = async (query) => {
  try {
    const { data } = await v2Axios(`/tags/${query || ''}`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const getCachedTagsList = async (query) => {
  try {
    const { data } = await v2Axios(`/tags/cached/${query || ''}`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const createTag = async (obj) => {
  try {
    const { data } = await v2Axios.post(`/tags/`, { ...obj });
    if (data.id) showSnakebar({ message: 'Рубрика успешно создана!' });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const saveTag = async (id, obj) => {
  try {
    const { data } = await v2Axios.put(`/tags/${id}/`, { ...obj });
    if (data.id) showSnakebar({ message: 'Сохранено успешно!' });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const getTag = async (id) => {
  try {
    const { data } = await v2Axios(`/tags/${id}/`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const deleteTag = async (id) => {
  try {
    const { data, ...res } = await v2Axios.delete(`/tags/${id}/`);

    showSnakebar({ message: 'Удаление прошло успешно!' });

    return { data, res };
  } catch (error) {
    sendError(error);
    return { res: error.response };
  }
};

// CustomCode
export const getScriptsList = async (query) => {
  try {
    const { data } = await v2Axios(`/advertising/custom-code/${query || ''}`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const createScript = async (obj) => {
  try {
    const { data } = await v2Axios.post(`/advertising/custom-code/`, {
      ...obj,
    });
    if (data.id) showSnakebar({ message: 'Скрипт успешно создан!' });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const saveScript = async (id, obj) => {
  try {
    const { data } = await v2Axios.put(`/advertising/custom-code/${id}/`, {
      ...obj,
    });
    if (data.id) showSnakebar({ message: 'Сохранено успешно!' });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const getScript = async (id) => {
  try {
    const { data } = await v2Axios(`/advertising/custom-code/${id}/`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const deleteScript = async (id) => {
  try {
    const { data, ...res } = await v2Axios.delete(
      `/advertising/custom-code/${id}/`
    );

    showSnakebar({ message: 'Удаление прошло успешно!' });

    return { data, res };
  } catch (error) {
    sendError(error);
    return { res: error.response };
  }
};

// Documents
export const createDocument = async (obj) => {
  try {
    const { data } = await v2Axios.post(`/company/document/`, obj);
    if (data.id) showSnakebar({ message: 'Документ успешно загружен!' });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const getDocuments = async (query) => {
  try {
    const { data } = await v2Axios(
      `/company/document/${query ? `${query}/` : ''}`
    );
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const saveDocument = async (id, obj) => {
  try {
    const { data } = await v2Axios.patch(`/company/document/${id}/`, obj);
    if (data.id) showSnakebar({ message: 'Документ успешно сохранен!' });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const deleteDocument = async (id) => {
  try {
    const { data } = await v2Axios.delete(`/company/document/${id}/`);
    showSnakebar({ message: 'Удаление прошло успешно!' });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

// custom_navs
export const getCustomNavs = async (query) => {
  try {
    const { data } = await v2Axios(`/company/custom-nav/${query || ''}`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const createCustomNavs = async (obj) => {
  try {
    const { data } = await v2Axios.post(`/company/custom-nav/`, { ...obj });
    if (data.id) showSnakebar({ message: 'Ссылка успешно создана!' });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const saveCustomNavs = async (id, obj) => {
  try {
    const { data } = await v2Axios.put(`/company/custom-nav/${id}/`, {
      ...obj,
    });
    if (data.id) showSnakebar({ message: 'Сохранено успешно!' });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const getCustomNav = async (id) => {
  try {
    const { data } = await v2Axios(`/company/custom-nav/${id}/`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

// Promo

export const getBanners = async (query) => {
  try {
    const { data } = await v2Axios(`/advertising/banner/${query || ''}`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const createBanner = async (obj) => {
  try {
    const { data } = await v2Axios.post(
      `/advertising/banner/`,
      { ...obj },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    if (data.id) showSnakebar({ message: 'Баннер успешно создан!' });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const saveBanner = async (id, obj) => {
  try {
    const { data } = await v2Axios.put(
      `/advertising/banner/${id}/`,
      { ...obj },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    if (data.id) showSnakebar({ message: 'Сохранено успешно!' });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const getBanner = async (id) => {
  try {
    const { data } = await v2Axios(`/advertising/banner/${id}/`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const deleteBanner = async (id) => {
  try {
    const { data } = await v2Axios.delete(`/advertising/banner/${id}/`);
    showSnakebar({ message: 'Удаление прошло успешно!' });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const getTickers = async (query) => {
  try {
    const { data } = await axios(`/bids/${query || ''}`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const getTicker = async (id) => {
  try {
    const { data } = await axios(`/bids/${id}/`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const saveTicker = async (id, obj) => {
  try {
    const { data } = await axios.patch(
      `/bids/${id}/`,
      { ...obj },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    if (data.id) showSnakebar({ message: 'Сохранено успешно!' });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const getFeedsList = async (query) => {
  try {
    const { data } = await axios(`/feeds/${query || ''}`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const getArticleVersions = async (slug, query) => {
  try {
    const { data } = await v2Axios(
      `/articles/${slug}/get_versions_by_slug/${query || ''}`
    );
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const revertArticleVersion = async (slug, version_number) => {
  try {
    await v2Axios.post(
      `/articles/${slug}/revert_article_on_version/${version_number}/`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    showSnakebar({
      message: `Переход на версию ${version_number}, прошел успешно.`,
    });
  } catch (error) {
    sendError(error);
  }
};

export const uploadAirBroadcast = async (obj) => {
  try {
    const { data } = await axios.post(`/tv_days/upload_new_broadcast/`, obj);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const broadcastLastUpdateInfo = async (query) => {
  try {
    const { data } = await axios(`/tv_days/last_update_info/${query || ''}`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

// Programs
export const getPrograms = async (query) => {
  try {
    const { data } = await axios(`/programmes/${query || ''}`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const getProgram = async (id) => {
  try {
    const { data } = await axios(`/programmes/${id}`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const createProgram = async (formData) => {
  try {
    const { data } = await axios.post(`/programmes/`, formData);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const saveProgram = async (id, formData) => {
  try {
    const { data } = await axios.patch(`/programmes/${id}/`, formData);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

// Relese
export const getRelease = async (id) => {
  try {
    const { data } = await axios(`/releases/${id}`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const saveRelease = async (id, formData) => {
  try {
    const { data } = await axios.patch(`/releases/${id}/`, formData);
    showSnakebar({ message: 'Сохранено успешно!' });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const createRelease = async (formData) => {
  try {
    const { data } = await axios.post(`/releases/`, formData);
    showSnakebar({ message: 'Сохранено успешно!' });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

// Reports

export const getArticleReportURL = (query) => {
  return `${v2BaseURL}/articles/report/${query || ''}`;
};

export const getAuthorReportURL = (query) => {
  return `${baseURL}/users/report/${query || ''}`;
};

export const getImageReportURL = (query) => {
  return `${baseURL}/article_images/report/${query || ''}`;
};

export const getRelatedArticles = async (slug) => {
  try {
    const { data } = await v2Axios(`/articles/${slug}/related/`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const getroles = async (query) => {
  try {
    const { data } = await axios(`/roles/${query || ''}`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

// Authors
export const getAuthorsList = async (query) => {
  try {
    const { data } = await axios(`/users/authors/${query || ''}`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

// Contracts
export const getContractsList = async (query) => {
  try {
    const { data } = await axios(`/contracts/${query || ''}`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const createContract = async (obj) => {
  try {
    const { data } = await axios.post(`/contracts/`, obj);
    if (data.id) showSnakebar({ message: 'Договор успешно создан!' });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const getContract = async (id) => {
  try {
    const { data } = await axios(`/contracts/${id}/`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const saveContract = async (id, obj) => {
  try {
    const { data } = await axios.patch(`/contracts/${id}/`, obj);
    if (data.id) showSnakebar({ message: 'Сохранено успешно!' });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

// Config
// target = 1 - Ямал
// target = 2 - Красный север

export const saveUniversalData = async (
  { target_id = 2, type = 'main_page/editors_choice' },
  obj
) => {
  try {
    const { data } = await axios.post(`/conf/${target_id}/${type}/set/`, obj);
    showSnakebar({ message: 'Сохранено успешно!' });
    return data;
  } catch (error) {
    sendError(error);
    return [];
  }
};

export const getUniversalData = async ({ path, query }) => {
  try {
    const queryParams = new URLSearchParams(query).toString();
    const { data } = await v2Axios(`/${path}/?${queryParams}`);
    return data;
  } catch (error) {
    sendError(error);
    return [];
  }
};

export const setUniversalData = async ({
  path,
  query,
  method = 'post',
  data,
}) => {
  try {
    const queryParams = new URLSearchParams(query).toString();
    const response = await v2Axios({
      url: `/${path}/${queryParams}`,
      method,
      data,
    });
    return response.data;
  } catch (error) {
    sendError(error);
    return [];
  }
};

// Newspapers

export const getNewspapersList = async (query) => {
  try {
    const { data } = await v2Axios(`/newspapers${query || ''}`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const getNewspaper = async (slug) => {
  try {
    const { data } = await v2Axios(`/newspapers/${slug}/`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const createNewspaper = async (formData) => {
  try {
    const { data } = await v2Axios.post(`/newspapers/`, formData);
    if (data.id) showSnakebar({ message: 'Печатное издание успешно создано!' });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const saveNewspaper = async (slug, formData) => {
  try {
    const { data } = await v2Axios.patch(`/newspapers/${slug}/`, formData);
    if (data.id)
      showSnakebar({ message: 'Печатное издание успешно обновлено!' });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const deleteNewspaper = async (slug) => {
  try {
    const { data, ...res } = await v2Axios.delete(`/newspapers/${slug}/`);

    showSnakebar({ message: 'Удаление прошло успешно!' });

    return { data, res };
  } catch (error) {
    sendError(error);
    return { res: error.response };
  }
};

// Newspapers Issues

export const getNewspapersIssuesList = async (query) => {
  try {
    const { data } = await v2Axios(`/newspapers_issue/${query || ''}`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const getNewspaperIssue = async (slug) => {
  try {
    const { data } = await v2Axios(`newspapers/newspaper-issue/${slug}/`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const createNewspaperIssue = async (formData) => {
  try {
    const { data } = await v2Axios.post(
      `newspapers/newspaper-issue/`,
      formData
    );
    if (data.id) showSnakebar({ message: 'Выпуск успешно создан!' });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const saveNewspaperIssue = async (slug, formData) => {
  try {
    const { data } = await v2Axios.patch(
      `newspapers/newspaper-issue/${slug}/`,
      formData
    );
    if (data.id) showSnakebar({ message: 'Выпуск успешно обновлён!' });
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const deleteNewspaperIssue = async (slug) => {
  try {
    const { data, ...res } = await v2Axios.delete(
      `newspapers/newspaper-issue/${slug}/`
    );

    showSnakebar({ message: 'Удаление прошло успешно!' });

    return { data, res };
  } catch (error) {
    sendError(error);
    return { res: error.response };
  }
};

// SeverPress

export const getSeverPressGrid = async (target_id = 3) => {
  try {
    const { data } = await v2Axios(`/np/main-grid/?target_id=${target_id}`);
    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const updateSeverPressGrid = async (data, target_id = 3) => {
  try {
    const response = await v2Axios.post(
      `/np/main-grid/?target_id=${target_id}`,
      data
    );
    console.log(response);
    if (response.status === 201)
      showSnakebar({ message: 'Сетка успешно сохранена!' });
    return response;
  } catch (error) {
    sendError(error);
    return {};
  }
};

export const savePromo = async (obj, target_id = 1) => {
  try {
    const { data } = await v2Axios.put(
      `/articles/infinity/promo_articles/?target_id=${target_id}`,
      obj
    );

    if (data.id) showSnakebar({ message: 'Сохранено успешно!' });

    return data;
  } catch (error) {
    sendError(error);
    return {};
  }
};
