import React, { useEffect, useState } from 'react';
import { Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import SeverPressMainGrid from 'components/SeverPressMainGrid';
import { getSeverPressGrid } from '../../request';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

const SeverPressGridFeature = () => {
  const [data, setData] = useState(null);

  const fetchGrid = async () => {
    const gridData = await getSeverPressGrid();
    setData(gridData);
  };

  useEffect(() => {
    fetchGrid();
  }, []);

  if (!data)
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  return (
    <>
      <Paper style={{ padding: 15, marginBottom: 15 }} elevation={0}>
        <SeverPressMainGrid data={data} />
      </Paper>
    </>
  );
};

const mapDispatch = (dispatch) => ({
  showDialog: (message, cb) =>
    dispatch.dialog.toggle({
      message,
      onAgree: cb,
    }),
});

export default connect(null, mapDispatch)(SeverPressGridFeature);
