export const fetchConfig = {
  live_broadcast: {
    query: ``,
    path: 'np/triple-block/live-broadcast',
  },
  digit: {
    query: ``,
    path: 'np/triple-block/digit',
  },
  quote: {
    query: ``,
    path: 'np/quotes',
  },
  article: {
    query: `is_published=true&is_available_by_link=False&type=news,narrative`,
    path: 'articles',
  },
};

const getDefaultItem = ({ viewType, dataType = viewType, title }) => ({
  viewType,
  dataType: dataType,
  title,
  id: null,
  instance: null,
});
const defaultSliderItem = {
  viewType: 'slider',
  title: 'Слайдер',
  isMultiple:true,
  items: [
    getDefaultItem({
      viewType: 'slider',
      dataType: 'article',
      title: 'Слайдер',
    }),
    getDefaultItem({
      viewType: 'slider',
      dataType: 'article',
      title: '',
    }),
    getDefaultItem({
      viewType: 'slider',
      dataType: 'article',
      title: '',
    }),
  ],
};
const defaultDigitItem = getDefaultItem({ viewType: 'digit', title: 'Цифра' });
const defaultBroadcastItem = getDefaultItem({
  viewType: 'live_broadcast',
  title: 'Трансляция',
});
const defaultKickerItem = getDefaultItem({
  viewType: 'kicker',
  dataType: 'article',
  title: 'Кикер',
});
const defaultPhotoItem = getDefaultItem({
  viewType: 'photoBlock',
  dataType: 'article',
  title: 'Блок с фото',
});
const defaultLeadItem = getDefaultItem({
  viewType: 'leadString',
  dataType: 'article',
  title: 'Строчка заголовок',
});
const defaultQuoteItem = getDefaultItem({ viewType: 'quote', title: 'Цитата' });

export const defaultTripleBlock = {
  slug: 'triple_block',
  title: 'Тройной блок',
  items: [
    [defaultSliderItem],
    [defaultDigitItem, defaultBroadcastItem],
    [defaultKickerItem],
  ],
};
export const defaultPictureOfDayBlock = {
  slug: 'picture_od_day',
  title: 'Картина дня',
  items: [
    [defaultPhotoItem, defaultPhotoItem],
    [defaultPhotoItem, defaultPhotoItem],
  ],
};
export const defaultInterestingBlock = {
  slug: 'interesting',
  title: 'Интересное',
  items: [
    [defaultPhotoItem, defaultLeadItem, defaultPhotoItem, defaultLeadItem],
    [defaultPhotoItem, defaultLeadItem],
  ],
};
export const defaultInterestingSubBlock = {
  slug: 'interestingSub',
  title: '',
  items: [[defaultPhotoItem], [defaultPhotoItem], [defaultPhotoItem]],
};
export const defaultCommentsBlock = {
  slug: 'comments',
  title: 'Комментарии',
  items: [[defaultQuoteItem], [defaultQuoteItem], [defaultQuoteItem]],
};
export const valuesScheme = [
  defaultTripleBlock,
  defaultPictureOfDayBlock,
  defaultInterestingBlock,
  defaultInterestingSubBlock,
  defaultCommentsBlock,
];

export const defaultValue = {};
export const initialData = {
  triple_block: [
    {
      items: [
        {
          id: null,
          type: '',
        },
        {
          id: null,
          type: '',
        },
        {
          id: null,
          type: '',
        },
      ],
    },
    {
      id: null,
      type: '',
    },
    {
      id: null,
      type: '',
    },
    {
      id: null,
      type: '',
    },
  ],
  picture_od_day: [
    {
      id: null,
      type: '',
    },
    {
      id: null,
      type: '',
    },
    {
      id: null,
      type: '',
    },
    {
      id: null,
      type: '',
    },
    {
      id: null,
      type: '',
    },
    {
      id: null,
      type: '',
    },
  ],
  interesting: [
    {
      id: null,
      type: '',
    },
    {
      id: null,
      type: '',
    },
    {
      id: null,
      type: '',
    },
    {
      id: null,
      type: '',
    },
    {
      id: null,
      type: '',
    },
    {
      id: null,
      type: '',
    },
    {
      id: null,
      type: '',
    },
    {
      id: null,
      type: '',
    },
    {
      id: null,
      type: '',
    },
  ],
  comments: [
    {
      id: null,
      type: '',
    },
    {
      id: null,
      type: '',
    },
    {
      id: null,
      type: '',
    },
  ],
};

