import React from 'react';
import NavCategories from 'feature/ChoiceNavs/index';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(() => ({
  wrapper: {
    background: '#fff',
    padding: '15px',
    borderRadius: '4px',
  },
}));

const ChoiseNavsSeverPress = () => {
  const classes = useStyles();

  const history = useHistory();

  return (
    <div className={classes.wrapper}>
      <Button
        onClick={() => history.push('/admin/sp-header-categories/new')}
        variant="contained"
        color="primary"
        style={{ marginBottom: '10px' }}
      >
        Добавить ссылку
      </Button>
      <NavCategories slotsCount={6} />
    </div>
  );
};

export default ChoiseNavsSeverPress;
