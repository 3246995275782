import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'hook/useQuery';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import CheckIcon from '@material-ui/icons/Check';
import RemoveIcon from '@material-ui/icons/Remove';
import { getCategoriesList } from '../../../request';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import { objectToQuery } from 'assets/js/helper';

let debounce = null;
const BASE_URL = '/admin/classifiers/categories';
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginTop: 30,
  },
  tableRow: {
    cursor: 'pointer',
  },
  tools: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '20px',
  },
  search: {
    paddingBottom: 10,
  },
});

const Сlassifiers = () => {
  const classes = useStyles();
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const [list, setList] = useState();
  const initPage =
    query.get('page') && typeof Number(query.get('page')) === 'number'
      ? Number(query.get('page'))
      : 1;
  const initPerPage =
    query.get('per_page') && typeof Number(query.get('per_page')) === 'number'
      ? Number(query.get('per_page'))
      : 50;

  // Удаляем параметры не относящиеся к фильтрам
  query.delete('page');
  query.delete('per_page');

  const [page, setPage] = useState(initPage);
  const [rowsPerPage, setRowsPerPage] = useState(initPerPage);
  const [filters, setFilters] = useState(Object.fromEntries(query.entries()));

  useEffect(() => {
    if (!location.search) {
      history.push(`${BASE_URL}?page=${page}&per_page=${rowsPerPage}`);
      return;
    }
    async function fetchData() {
      const res = await getCategoriesList(location.search);
      if (res.results) setList(res);
    }
    fetchData();
  }, [location.search]);

  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value, 10);
    history.push(`${BASE_URL}?page=1&per_page=${value}`);
    setRowsPerPage(value);
  };

  const handleChangePage = (event, nextPage) => {
    history.push(`${BASE_URL}?page=${nextPage + 1}&per_page=${rowsPerPage}`);
    setPage(nextPage + 1);
  };

  const handleChangeSearch = ({ target }) => {
    setFilters({ ...filters, search: target.value });
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      history.push(
        `${BASE_URL}?page=1&per_page=${rowsPerPage}${objectToQuery({
          ...filters,
          search: target.value,
        })}`
      );
      setPage(1);
    }, 600);
  };

  return (
    <>
      <Paper style={{ padding: 15, marginBottom: 15 }} elevation={0}>
        <div className={classes.tools}>
          <Button
            onClick={() => history.push('/admin/classifiers/categories/new')}
            variant="contained"
            color="primary"
          >
            Добавить рубрику
          </Button>
          <TextField
            label="Поиск"
            className={classes.search}
            value={filters.search}
            onChange={handleChangeSearch}
          />
        </div>
        <Divider />
        {list && (
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Название</TableCell>
                  <TableCell align="center">Слаг</TableCell>
                  <TableCell align="center">В шапке</TableCell>
                  <TableCell align="center">Активен</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.results.map((row) => (
                  <TableRow
                    className={classes.tableRow}
                    key={row.title}
                    onClick={() =>
                      history.push(
                        `/admin/classifiers/categories/edit/${row.id}`
                      )
                    }
                  >
                    <TableCell component="th" scope="row">
                      {row.title}
                    </TableCell>
                    <TableCell align="center">{row.slug}</TableCell>
                    <TableCell align="center">
                      {row.is_main ? (
                        <CheckIcon color="primary" />
                      ) : (
                        <RemoveIcon color="error" />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {row.is_active ? (
                        <PlayCircleOutlineIcon color="primary" />
                      ) : (
                        <PauseCircleOutlineIcon color="error" />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10]}
                    labelRowsPerPage="Статьи"
                    labelDisplayedRows={({ from, to, count }) =>
                      `${from}-${to} из ${count !== -1 ? count : to}`
                    }
                    count={list.count}
                    rowsPerPage={rowsPerPage}
                    page={page - 1}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
      </Paper>
    </>
  );
};

export default Сlassifiers;
