import React, { useState, useRef } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Dropzone from 'react-dropzone';
import TextField from '@material-ui/core/TextField';
import { getBase64Image } from 'assets/js/helper';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  createCoverImage,
  updateCoverImage,
  deleteCoverImage,
} from '../../../request';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { getCroppedImg } from 'assets/js/getCroppedImg';
import CropImgPreview from 'components/CropImgPreview';

let debounceId = null;

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  dropZoneContainer: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
  image: {
    position: 'relative',
    width: '100%',
    maxWidth: '480px',
    minWidth: '270px',
  },
  deleteBtn: {
    position: 'absolute',
    top: 5,
    right: 5,
    zIndex: 1,
  },
  wrapperImagePoint: {
    margin: '0 auto',
    width: '60%',
  },
  imagePointHead: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  imgCropTools: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '20px 0',
    gap: 10,
    border: '1px grey solid',
    padding: '10px 0',
    borderRadius: 4,
  },
  reactCrop: {
    maxWidth: 800,
  },
}));

const ArticleCover = ({ article }) => {
  const classes = useStyles();
  const cover = article?.image_cover || {};
  const initState = {
    image_id: cover.id,
    article: article.id,
    file: null,
    src: cover.file,
    description: cover.description,
    is_cover: true,
    param_x: cover.param_x || 0,
    param_y: cover.param_y || 0,
  };
  const initCropConfig = {
    aspect: 16 / 9,
  };
  const [state, setState] = useState(initState);
  const [crop, setCrop] = useState(initCropConfig);
  const [cropImage, setCropImage] = useState(null);
  const imgRef = useRef(null);

  const handleOnLoad = async (file) => {
    const baseFile = await getBase64Image(file[0]);
    let data = {};
    if (state.image_id) {
      data = await updateCoverImage(state.image_id, {
        ...state,
        file: baseFile,
      });
    } else {
      data = await createCoverImage({ ...state, file: baseFile });
    }
    if (data.id) {
      setState({
        ...state,
        description: data.description,
        src: data.file,
        image_id: data.id,
        file: null,
      });
    } else {
      setState(initState);
    }
  };

  const deleteCover = async () => {
    await deleteCoverImage(state.image_id);
    setState({
      ...state,
      file: null,
      src: null,
      image_id: null,
    });
  };

  const handleDescription = ({ target }) => {
    setState({ ...state, description: target.value });
    if (!state.image_id) return;
    clearTimeout(debounceId);
    debounceId = setTimeout(async () => {
      await updateCoverImage(state.image_id, {
        description: target.value,
      });
    }, 1000);
  };

  const handleCroppedImg = async () => {
    if (!imgRef.current) return;
    const cropFile = await getCroppedImg(imgRef.current, crop);
    setCropImage(cropFile);
  };

  const handleSaveCrop = async () => {
    if (!cropImage) return;
    const data = await updateCoverImage(state.image_id, {
      ...state,
      file: cropImage,
    });

    setState({
      ...state,
      description: data.description,
      src: data.file,
      image_id: data.id,
      file: null,
    });
    setCrop(initCropConfig);
    setCropImage(null);
  };

  return (
    <>
      {cropImage && (
        <CropImgPreview
          img={cropImage}
          handleSaveCrop={handleSaveCrop}
          close={() => setCropImage(null)}
        />
      )}
      <Paper style={{ padding: 15, marginBottom: 15 }} elevation={0}>
        <b>Редактирование обложки</b>
      </Paper>

      <Paper style={{ padding: 15, marginBottom: 15 }} elevation={0}>
        <TextField
          value={state.description}
          onChange={handleDescription}
          label="Описание"
          variant="outlined"
          size="small"
          margin="normal"
          style={{ width: '100%' }}
        />
        <Dropzone accept=".img,.jpg,.jpeg,.png,.gif" onDrop={handleOnLoad}>
          {({ getRootProps, getInputProps }) => (
            <section className={classes.dropZoneContainer}>
              {state.src && (
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  size="medium"
                  className={classes.deleteBtn}
                  onClick={deleteCover}
                >
                  Удалить
                </Button>
              )}
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                {state.src ? (
                  <img
                    crossOrigin="anonymous"
                    className={classes.image}
                    alt="pic"
                    src={state.src}
                  />
                ) : (
                  <p>Drag and drop some image here, or click to select image</p>
                )}
              </div>
            </section>
          )}
        </Dropzone>
        {state.src && (
          <div className={classes.imgCropTools}>
            <div>Кроп картинки</div>
            <ReactCrop
              aspect={16 / 9}
              crop={crop}
              onChange={(newCrop) => setCrop(newCrop)}
              className={classes.reactCrop}
            >
              <img
                ref={imgRef}
                crossOrigin="anonymous"
                alt="pic"
                src={state.src}
              />
            </ReactCrop>
            <Button
              disabled={!crop.width || !crop.height}
              onClick={handleCroppedImg}
              variant="contained"
              size="medium"
            >
              обрезать
            </Button>
          </div>
        )}
      </Paper>
    </>
  );
};

export default ArticleCover;
