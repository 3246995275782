import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { useHistory } from 'react-router-dom';
import { deleteBanner } from './../../request/index';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginTop: 30,
  },
  tableRow: {
    cursor: 'pointer',
  },
});

const BannersList = (props) => {
  const {
    list,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    route,
    fetchData,
  } = props;

  const classes = useStyles();
  const history = useHistory();

  const deleteHandler = async (id) => {
    await deleteBanner(id);
    fetchData();
  };
  return (
    <>
      <Paper style={{ padding: 15, marginBottom: 15 }} elevation={0}>
        <Button
          onClick={() => history.push(`${route}/new`)}
          variant="contained"
          color="primary"
        >
          Добавить баннер
        </Button>
        {list && (
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Название</TableCell>
                  <TableCell align="center">Расположение</TableCell>
                  <TableCell align="center">Приоритет</TableCell>
                  <TableCell align="center">Активен</TableCell>
                  <TableCell align="center">Удалить</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.results.map((row) => (
                  <TableRow
                    className={classes.tableRow}
                    key={row.id}
                    onClick={() => history.push(`${route}/edit/${row.id}`)}
                  >
                    <TableCell component="th" scope="row">
                      {row.code_name}
                    </TableCell>
                    <TableCell align="center">{row.position}</TableCell>
                    <TableCell align="center">{row.priority}</TableCell>
                    <TableCell align="center">
                      {row.is_active ? (
                        <PlayCircleOutlineIcon color="primary" />
                      ) : (
                        <PauseCircleOutlineIcon color="error" />
                      )}
                    </TableCell>

                    <TableCell align="center">
                      <IconButton
                        color="secondary"
                        aria-label="delete picture"
                        component="span"
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteHandler(row.id);
                        }}
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10]}
                    labelRowsPerPage="Статьи"
                    labelDisplayedRows={({ from, to, count }) =>
                      `${from}-${to} из ${count !== -1 ? count : to}`
                    }
                    count={list.count}
                    rowsPerPage={rowsPerPage}
                    page={page - 1}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
      </Paper>
    </>
  );
};

export default BannersList;
