export const tabList = [
  {
    name: 'Документы',
    value: 'documents',
  },
  {
    name: 'Информация',
    value: 'info',
  },
];
