import React from 'react';
// @material-ui/icons
import AppsIcon from '@material-ui/icons/Apps';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import CategoryIcon from '@material-ui/icons/Category';
import HistoryIcon from '@material-ui/icons/History';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import DescriptionIcon from '@material-ui/icons/Description';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import ListIcon from '@material-ui/icons/List';
import PostAddIcon from '@material-ui/icons/PostAdd';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import TvIcon from '@material-ui/icons/Tv';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ExploreIcon from '@material-ui/icons/Explore';
import NewspaperIcon from '@material-ui/icons/Note';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import LandscapeIcon from '@material-ui/icons/Landscape';
// core components/views for Admin layout
import ArticlesPage from 'views/Articles/Articles';
import NewArticle from 'views/Articles/New/NewArticle';
import EditArticle from 'views/Articles/Edit';
import MainGrid from 'views/MainGrid/MainGrid';
import ListUsers from 'views/Administration/Users/ListUsers';
import CrudUser from 'views/Administration/Users/CrudUser';
import LastActivities from 'views/Administration/LastActivities';
import Permissions from 'views/Administration/Permissions';
import ListContracts from 'views/Administration/Contracts/ListContracts';
import CrudContract from 'views/Administration/Contracts/CrudContract';
import ListCategories from 'views/Classifiers/Categories/ListCategories';
import CrudCategories from 'views/Classifiers/Categories/CrudCategories';
import MainPage from 'views/MainPage';
import Documents from 'views/Classifiers/Documents';
import CrudDocuments from 'views/Classifiers/Documents/CrudDocuments';
import Extranav from 'views/Classifiers/Extranav';
import CrudExtraNav from 'views/Classifiers/Extranav/CrudExtraNav';
import Air from 'views/Classifiers/Air';
import Programs from 'views/Classifiers/Programs';
import CrudPrograms from 'views/Classifiers/Programs/CrudPrograms';
import Release from 'views/Classifiers/Programs/Release';
import Promo from 'views/Promo/banners';
import CrudPromo from 'components/Promo/CrudPromo';
import RedNorthPromo from 'views/RedNorth/banners';
import TickerList from 'views/Promo/Advertisement';
import Ticker from 'views/Promo/Advertisement/Ticker';
import Reports from 'views/Reports';
import RedNorthMainGrid from 'views/RedNorth/MainGrid';
import RedNorthEditorChoice from 'views/RedNorth/EditorChoice';
import NavCategories from 'views/RedNorth/NavCategories';
import ListNewspapers from 'views/RedNorth/Newspapers/ListNewspapers';
import CrudNewspapers from 'views/RedNorth/Newspapers/CrudNewspapers';
import CrudNewspaperIssues from 'views/RedNorth/Newspapers/NewspaperIssues';
import Photobank from 'views/RedNorth/Photobank';
import NewPhotobank from 'views/RedNorth/Photobank/New';
import EditPhotoBank from 'views/RedNorth/Photobank/Edit';
import ListScripts from './views/Classifiers/Scripts/ListScripts';
import CrudScripts from './views/Classifiers/Scripts/CrudScripts';
import CrudTags from './views/Classifiers/Tags/CrudTags';
import ListTags from './views/Classifiers/Tags/ListTags';
import SeverPressMainGrid from 'views/SeverPress/MainGrid';
// import SeverPressNavCategories from 'views/SeverPress/NavCategories';
import SeverPressNavCategoriesCrud from 'views/SeverPress/NavCategories/CrudExtraNav';
import SeverPressNavTags from 'views/SeverPress/NavTags';
import SeverPressEditorChoice from 'views/SeverPress/EditorChoice';
import SeverPressBanners from 'views/SeverPress/Banners';
import SeverPressDigits from 'views/SeverPress/Digits';
import SeverPressCrudDigit from 'views/SeverPress/Digits/CrudDigit';
import SeverPressBroadcast from 'views/SeverPress/Broadcast';
import SeverPressCrudBroadcast from 'views/SeverPress/Broadcast/CrudBroadcast';
import SeverPressBlockquote from 'views/SeverPress/Blockquote';
import SeverPressCrudBlockquote from 'views/SeverPress/Blockquote/CrudBlockquote';
import PromoArticles from 'components/PromoArticles';

const administrationRoutes = [
  {
    path: '/administration/users',
    name: 'Пользователи',
    icon: PeopleOutlineIcon,
    component: ListUsers,
    layout: '/admin',
    show: false,
    subMenuShow: true,
  },
  {
    path: '/administration/users/new',
    name: 'Добавить пользователя',
    icon: GroupAddIcon,
    component: CrudUser,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
  {
    path: '/administration/users/edit/:id',
    name: 'Редактировать пользователя',
    icon: GroupAddIcon,
    component: CrudUser,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
  {
    path: '/administration/last_activities',
    name: 'Последние действия',
    icon: HistoryIcon,
    component: LastActivities,
    layout: '/admin',
    show: false,
    subMenuShow: true,
  },
  {
    path: '/administration/permissions',
    name: 'Доступы',
    icon: PermIdentityIcon,
    component: Permissions,
    layout: '/admin',
    show: false,
    subMenuShow: true,
  },
  {
    path: '/administration/contracts',
    name: 'Договоры',
    icon: AssignmentIcon,
    component: ListContracts,
    layout: '/admin',
    show: false,
    subMenuShow: true,
  },
  {
    path: '/administration/contracts/new',
    name: 'Добавить договор',
    icon: AssignmentIcon,
    component: CrudContract,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
  {
    path: '/administration/contracts/edit/:id',
    name: 'Редактировать договор',
    icon: AssignmentIcon,
    component: CrudContract,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
];

const classifiersRoutes = [
  {
    path: '/classifiers/categories',
    name: 'Рубрики',
    icon: CategoryIcon,
    component: ListCategories,
    layout: '/admin',
    show: false,
    subMenuShow: true,
  },
  {
    path: '/classifiers/categories/new',
    name: 'Добавить рубрику',
    icon: CategoryIcon,
    component: CrudCategories,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
  {
    path: '/classifiers/categories/edit/:id',
    name: 'Редактировать рубрику',
    icon: CategoryIcon,
    component: CrudCategories,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
  {
    path: '/classifiers/tags',
    name: 'Теги',
    icon: LocalOfferIcon,
    component: ListTags,
    layout: '/admin',
    show: false,
    subMenuShow: true,
  },

  {
    path: '/classifiers/tags/new',
    name: 'Добавить тег',
    icon: LocalOfferIcon,
    component: CrudTags,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
  {
    path: '/classifiers/tags/edit/:id',
    name: 'Редактировать тег',
    icon: LocalOfferIcon,
    component: CrudTags,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },

  //'/classifiers/scripts'
  {
    path: '/classifiers/scripts',
    name: 'Скрипты',
    icon: DescriptionIcon,
    component: ListScripts,
    layout: '/admin',
    show: false,
    subMenuShow: true,
  },
  {
    path: '/classifiers/scripts/new',
    name: 'Добавить скрипт',
    icon: LocalOfferIcon,
    component: CrudScripts,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
  {
    path: '/classifiers/scripts/edit/:id',
    name: 'Редактировать скрипт',
    icon: LocalOfferIcon,
    component: CrudScripts,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
  {
    path: '/classifiers/documents',
    name: 'Документы',
    icon: NoteAddIcon,
    component: Documents,
    layout: '/admin',
    show: false,
    subMenuShow: true,
  },
  {
    path: '/classifiers/documents/new',
    name: 'Добавить документ',
    icon: NoteAddIcon,
    component: CrudDocuments,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
  {
    path: '/classifiers/documents/edit/:id',
    name: 'Редактировать документ',
    icon: NoteAddIcon,
    component: CrudDocuments,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
  {
    path: '/classifiers/extranav',
    name: 'Дополнительная навигация',
    icon: SettingsApplicationsIcon,
    component: Extranav,
    layout: '/admin',
    show: false,
    subMenuShow: true,
  },
  {
    path: '/classifiers/extranav/new',
    name: 'Новая кастомная кнопка',
    icon: SettingsApplicationsIcon,
    component: CrudExtraNav,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
  {
    path: '/classifiers/extranav/edit/:id',
    name: 'Редактирование кастомной кнопки',
    icon: SettingsApplicationsIcon,
    component: CrudExtraNav,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
  {
    path: '/classifiers/air',
    name: 'Редактирование эфира',
    icon: LiveTvIcon,
    component: Air,
    layout: '/admin',
    show: false,
    subMenuShow: true,
  },
  {
    path: '/classifiers/programs',
    name: 'Список програм',
    icon: TvIcon,
    component: Programs,
    layout: '/admin',
    show: false,
    subMenuShow: true,
  },
  {
    path: '/classifiers/programs/edit/:id',
    name: 'Редактирование програм',
    icon: TvIcon,
    component: CrudPrograms,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
  {
    path: '/classifiers/programs/new',
    name: 'Создание программы',
    icon: TvIcon,
    component: CrudPrograms,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
  {
    path: '/classifiers/programs/release/:id',
    name: 'Редактирование выпуска',
    icon: TvIcon,
    component: Release,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
  {
    path: '/classifiers/programs/release/new/:programId',
    name: 'Создание выпуска',
    icon: TvIcon,
    component: Release,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
];

const promoRoutes = [
  {
    path: '/promo',
    name: 'Баннеры и обменки',
    icon: ListIcon,
    component: Promo,
    layout: '/admin',
    show: false,
    subMenuShow: true,
  },
  {
    path: '/promo/new',
    name: 'Добавить промо',
    icon: PostAddIcon,
    component: CrudPromo,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
  {
    path: '/promo/edit/:id',
    name: 'Добавить промо',
    icon: PostAddIcon,
    component: CrudPromo,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
  {
    path: '/promo/advertisment',
    name: 'Бегущая строка',
    icon: ListIcon,
    component: TickerList,
    layout: '/admin',
    show: false,
    subMenuShow: true,
  },
  {
    path: '/promo/advertisment/:id',
    name: 'Заявка на рекламу',
    icon: ListIcon,
    component: Ticker,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
];

const yamal = [
  {
    path: '/yamal/promo-in-infinity',
    name: 'Промо в инфинитиве',
    icon: BeenhereIcon,
    component: () => <PromoArticles />,
    layout: '/admin',
    show: false,
    subMenuShow: true,
  },
];

const redNorth = [
  {
    path: '/red-north',
    name: 'Новости на главной',
    icon: AppsIcon,
    component: RedNorthMainGrid,
    layout: '/admin',
    show: false,
    subMenuShow: true,
  },
  {
    path: '/header-categories',
    name: 'Навигация',
    icon: AppsIcon,
    component: NavCategories,
    layout: '/admin',
    show: false,
    subMenuShow: true,
  },
  {
    path: '/editor-сhoice',
    name: 'Выбор редакции',
    icon: AppsIcon,
    component: RedNorthEditorChoice,
    layout: '/admin',
    show: false,
    subMenuShow: true,
  },
  {
    path: '/newspapers',
    name: 'Печатные издания',
    icon: NewspaperIcon,
    component: ListNewspapers,
    layout: '/admin',
    show: false,
    subMenuShow: true,
  },
  {
    path: '/newspapers/new',
    name: 'Создать печатное издание',
    icon: NewspaperIcon,
    component: CrudNewspapers,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
  {
    path: '/newspapers/edit/:id',
    name: 'Редактировать печатное издание',
    icon: NewspaperIcon,
    component: CrudNewspapers,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
  {
    path: '/newspapers/issue/new/:newspaperId',
    name: 'Создать номер печатного издания',
    icon: NewspaperIcon,
    component: CrudNewspaperIssues,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
  {
    path: '/newspapers/issue/:id',
    name: 'Редактировать номер печатного издания',
    icon: NewspaperIcon,
    component: CrudNewspaperIssues,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
  {
    path: '/photobanks',
    name: 'Фотобанк',
    icon: InsertPhotoIcon,
    component: Photobank,
    layout: '/admin',
    show: false,
    subMenuShow: true,
  },
  {
    path: '/photobanks/new',
    name: 'Добавить фотобанк',
    icon: DescriptionIcon,
    component: NewPhotobank,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
  {
    path: '/photobanks/edit/:slug',
    name: 'Редактировать фотобанк',
    icon: DescriptionIcon,
    component: EditPhotoBank,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
  {
    path: '/red-north/promo',
    name: 'Баннеры и обменки',
    icon: ListIcon,
    component: RedNorthPromo,
    layout: '/admin',
    show: false,
    subMenuShow: true,
  },
  {
    path: '/red-north/promo/new',
    name: 'Добавить промо',
    icon: PostAddIcon,
    component: () => <CrudPromo defaultTarget={2} />,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
  {
    path: '/red-north/promo/edit/:id',
    name: 'Добавить промо',
    icon: PostAddIcon,
    component: CrudPromo,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
  {
    path: '/red-north/promo-in-infinity',
    name: 'Промо в инфинитиве КС',
    icon: BeenhereIcon,
    component: () => <PromoArticles targetId="2" />,
    layout: '/admin',
    show: false,
    subMenuShow: true,
  },
];

const severPress = [
  {
    path: '/sp-main-page',
    name: 'Сетка главной',
    icon: AppsIcon,
    component: SeverPressMainGrid,
    layout: '/admin',
    show: false,
    subMenuShow: true,
  },
  {
    path: '/sp-header-categories',
    name: 'Навигация в шапке',
    icon: AppsIcon,
    component: SeverPressNavTags,
    layout: '/admin',
    show: false,
    subMenuShow: true,
  },
  {
    path: '/sp-header-categories/new',
    name: 'Создание навигации',
    icon: AppsIcon,
    component: SeverPressNavCategoriesCrud,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
  {
    path: '/sp-header-categories/edit/:id',
    name: 'Редактирование навигации',
    icon: AppsIcon,
    component: SeverPressNavCategoriesCrud,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
  {
    path: '/sp-header-editor-choice',
    name: 'Новости "Популярное"',
    icon: AppsIcon,
    component: SeverPressEditorChoice,
    layout: '/admin',
    show: false,
    subMenuShow: true,
  },
  {
    path: '/sp-banners',
    name: 'Баннеры и обменки',
    icon: ListIcon,
    component: SeverPressBanners,
    layout: '/admin',
    show: false,
    subMenuShow: true,
  },
  {
    path: '/sp-banners/new',
    name: 'Добавить баннер СП',
    icon: PostAddIcon,
    component: () => <CrudPromo defaultTarget={3} />,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
  {
    path: '/sp-banners/edit/:id',
    name: 'Редактировать баннер СП',
    icon: PostAddIcon,
    component: CrudPromo,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
  {
    path: '/sp-main-digits',
    name: 'Карточки "цифра"',
    icon: AppsIcon,
    component: SeverPressDigits,
    layout: '/admin',
    show: false,
    subMenuShow: true,
  },
  {
    path: '/sp-main-digits/new',
    name: 'Создание карточки "цифра"',
    icon: AppsIcon,
    component: SeverPressCrudDigit,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
  {
    path: '/sp-main-digits/edit/:id',
    name: 'Редактирование карточки "цифра"',
    icon: AppsIcon,
    component: SeverPressCrudDigit,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
  {
    path: '/sp-main-broadcast',
    name: 'Карточки "трансляция"',
    icon: AppsIcon,
    component: SeverPressBroadcast,
    layout: '/admin',
    show: false,
    subMenuShow: true,
  },
  {
    path: '/sp-main-broadcast/new',
    name: 'Создание карточки "трансляция"',
    icon: AppsIcon,
    component: SeverPressCrudBroadcast,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
  {
    path: '/sp-main-broadcast/edit/:id',
    name: 'Редактирование карточки "трансляция"',
    icon: AppsIcon,
    component: SeverPressCrudBroadcast,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
  {
    path: '/sp-main-blockquote',
    name: 'Карточки "комментарии"',
    icon: AppsIcon,
    component: SeverPressBlockquote,
    layout: '/admin',
    show: false,
    subMenuShow: true,
  },
  {
    path: '/sp-main-blockquote/new',
    name: 'Создание карточки "комментарии"',
    icon: AppsIcon,
    component: SeverPressCrudBlockquote,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
  {
    path: '/sp-main-blockquote/edit/:id',
    name: 'Редактирование карточки "комментарии"',
    icon: AppsIcon,
    component: SeverPressCrudBlockquote,
    layout: '/admin',
    show: false,
    subMenuShow: false,
  },
  {
    path: '/sp-promo-in-infinity',
    name: 'Промо в инфинитиве СП',
    icon: BeenhereIcon,
    component: () => <PromoArticles targetId="3" />,
    layout: '/admin',
    show: false,
    subMenuShow: true,
  },
];

const dashboardRoutes = [
  {
    path: '/main',
    name: 'Главная',
    icon: AppsIcon,
    component: MainPage,
    layout: '/admin',
    show: false,
  },
  {
    path: '/articles/new',
    name: 'Новая статья',
    icon: DescriptionIcon,
    component: NewArticle,
    layout: '/admin',
    show: false,
  },
  {
    path: '/articles/edit/:slug',
    name: 'Редактировать статью',
    icon: DescriptionIcon,
    component: EditArticle,
    layout: '/admin',
    show: false,
  },
  {
    path: '/articles',
    name: 'Статьи',
    icon: DescriptionIcon,
    component: ArticlesPage,
    layout: '/admin',
    show: true,
  },
  {
    path: '/dashboardgrid',
    name: 'Сетка главной',
    icon: AppsIcon,
    component: MainGrid,
    layout: '/admin',
    show: true,
  },
  {
    name: 'Аккаунты',
    icon: SupervisorAccountIcon,
    layout: '/admin',
    show: true,
    subLinks: administrationRoutes,
  },
  {
    name: 'Справочник',
    icon: LibraryBooksIcon,
    layout: '/admin',
    show: true,
    subLinks: classifiersRoutes,
  },
  {
    name: 'Реклама',
    icon: BusinessCenterIcon,
    layout: '/admin',
    show: true,
    subLinks: promoRoutes,
  },
  {
    path: '/reports',
    name: 'Отчеты',
    icon: AssessmentIcon,
    component: Reports,
    layout: '/admin',
    show: true,
  },
  {
    name: 'Ямал',
    icon: AcUnitIcon,
    layout: '/admin',
    show: true,
    subLinks: yamal,
  },
  {
    name: 'Красный север',
    icon: ExploreIcon,
    layout: '/admin',
    show: true,
    subLinks: redNorth,
  },
  {
    name: 'Север-пресс',
    icon: LandscapeIcon,
    layout: '/admin',
    show: true,
    subLinks: severPress,
  },
  ...administrationRoutes,
  ...classifiersRoutes,
  ...promoRoutes,
  ...redNorth,
  ...yamal,
  ...severPress,
];

export default dashboardRoutes;
