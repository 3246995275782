import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import { TextField, Switches } from 'mui-rff';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { useParams, useHistory } from 'react-router-dom';
import {
  getNewspaperIssue,
  saveNewspaperIssue,
  createNewspaperIssue,
} from '../../../../request';
import Dropzone from 'react-dropzone';
import { getBase64Image } from 'assets/js/helper';
import DeleteIcon from '@material-ui/icons/Delete';

const BASE_URL = '/admin/newspapers';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTextField-root': {
      margin: '8px 0',
      width: '100%',
    },
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
    },
  },
  submitBtn: {
    marginRight: 5,
    marginTop: 15,
  },
  linkBlock: {
    display: 'flex',
  },
  dropZoneContainer: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '19px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    margin: '8px 0',
  },
  deleteBtn: {
    marginLeft: '15px',
    marginRight: '15px',
    padding: '6px',
  },
  cover: {
    position: 'relative',
    width: '100%',
    maxWidth: '460px',
    maxHeight: '480px',
  },
  pdf: {
    position: 'relative',
    alignItems: 'center',
    minWidth: '460px',
    minHeight: '480px',
  },
  error: {
    color: '#f44336',
    fontSize: '0.75rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    marginLeft: '14px',
  },
}));

const validate = ({ year, number }) => {
  const errors = {};
  if (!year) {
    errors.year = 'Обязательно для заполнения';
  }
  if (!number) {
    errors.number = 'Обязательно для заполнения';
  }
  return errors;
};

const NewspaperIssue = () => {
  const history = useHistory();
  const { id, newspaperId } = useParams();
  const classes = useStyles();
  const [values, setValues] = useState({});
  const [state, setState] = useState({});

  useEffect(() => {
    async function fetchData() {
      const res = await getNewspaperIssue(id);
      if (res.id) {
        setValues({ ...res, coverFile: null, pdfFile: null });
        setState({
          cover: res.cover,
          pdf: res.pdf,
          coverFile: null,
          pdfFile: null,
        });
      } else {
        history.push(`${BASE_URL}`);
      }
    }
    if (id) fetchData();
  }, []);

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('is_active', Boolean(data.is_active));
    formData.append('year', data.year);
    formData.append('number', data.number);

    if (state.coverFile) formData.append('cover', state.coverFile);
    if (state.pdfFile) formData.append('pdf', state.pdfFile);

    try {
      if (id) {
        await saveNewspaperIssue(id, formData);
      } else {
        formData.append('newspaper', newspaperId);
        await createNewspaperIssue(formData);
      }
      history.push(`${BASE_URL}/edit/${newspaperId || state.newspaper_slug}`);
    } catch (error) {
      console.error('Ошибка при сохранении', error);
    }
  };

  const handleOnLoadCover = async (file) => {
    try {
      const baseFile = await getBase64Image(file[0]);
      setState({
        ...state,
        coverFile: file[0],
        cover: baseFile,
      });
    } catch (error) {
      console.error('Ошибка при сохранении обложки', error);
    }
  };

  const handleOnLoadPDF = async (file) => {
    try {
      setState({
        ...state,
        pdf: file,
        pdfFile: file[0],
      });
    } catch (error) {
      console.error('Ошибка при сохранении PDF файла', error);
    }
  };

  const deleteCover = async () => {
    setState({
      ...state,
      coverFile: null,
      cover: null,
    });
  };

  const deletePDF = async () => {
    setState({
      ...state,
      pdfFile: null,
      pdf: null,
    });
  };

  const isValidFiles = state.cover && state.pdf;
  return (
    <Form
      initialValues={values}
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, valid }) => (
        <form
          onSubmit={handleSubmit}
          className={classes.root}
          noValidate
          autoComplete="off"
        >
          <Paper style={{ padding: 15, marginBottom: 15 }} elevation={0}>
            {id && (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  size="medium"
                  className={classes.deleteBtn}
                  onClick={deleteCover}
                  disabled={!state.cover}
                >
                  {state.cover ? `Удалить обложку` : `Обложка не загружена`}
                </Button>

                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  size="medium"
                  className={classes.deleteBtn}
                  onClick={deletePDF}
                  disabled={!state.pdf}
                >
                  {state.pdf ? `Удалить PDF файл` : `PDF файл не загружен`}
                </Button>

                <Divider />

                <br />
              </>
            )}
            <Switches
              label="Номер опубликован"
              name="is_active"
              data={{ label: null }}
            />
            <TextField
              required
              label="Год"
              variant="outlined"
              name="year"
              margin="none"
              size="small"
            />
            <TextField
              required
              label="Номер"
              variant="outlined"
              name="number"
              margin="none"
              size="small"
            />

            <div className={classes.linkBlock}></div>
            <div className={classes.mediaBlock}>
              <Dropzone
                accept=".img,.jpg,.jpeg,.png,.gif"
                onDrop={handleOnLoadCover}
              >
                {({ getRootProps, getInputProps }) => (
                  <section className={classes.dropZoneContainer}>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      {state.cover ? (
                        <img
                          alt="pic"
                          src={state.cover}
                          className={classes.cover}
                        />
                      ) : (
                        <p>Перенесите или выберите обложку</p>
                      )}
                    </div>
                  </section>
                )}
              </Dropzone>
              {!state.cover && (
                <span className={classes.error}>Загрузите обложку</span>
              )}
              <div className={classes.linkBlock}>
                {state.pdf ? (
                  typeof state.pdf === 'string' ? (
                    <a
                      href={state.pdf}
                      target="_blank"
                      rel="noreferrer"
                      download
                    >
                      Файл загружен! Нажмите для просмотра
                    </a>
                  ) : (
                    <span>Файл загружен</span>
                  )
                ) : (
                  <Dropzone accept=".pdf" onDrop={handleOnLoadPDF}>
                    {({ getRootProps, getInputProps }) => (
                      <section className={classes.dropZoneContainer}>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <p>Перенесите или выберите номер в формате PDF</p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                )}
              </div>
              {!state.pdf && (
                <span className={classes.error}>Загрузите выпуск</span>
              )}
              <div className={classes.linkBlock}></div>
            </div>

            <Button
              disabled={!valid || !isValidFiles}
              type="submit"
              className={classes.submitBtn}
              variant="contained"
              color="primary"
            >
              Сохранить
            </Button>
          </Paper>
        </form>
      )}
    />
  );
};

export default NewspaperIssue;
