import React from 'react';

const InstagramSharing = ({ source }) => (
  // const prepareSharingContent = sharingContent.replace(/<script\b[^>]*>([\s\S]*?)<\/script>/gm, '')
  <div
    className="article-instagram-sharing social-embed"
    data-instagram-source={source}
  />
);
export default InstagramSharing;
